/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Loader } from '@savant-components/basic';
import React from 'react';
import { useProtectedLink } from '../../hooks/deeplink';

const ProtectedLinkView = (): React.ReactElement => {
  const { isLoading } = useProtectedLink();
  const style = css`
    padding: 16px;
    padding-right: 4.5rem;
    height: 100%;
  `;
  return <div css={style}>{isLoading ? <Loader size={200} /> : <React.Fragment />}</div>;
};

export default ProtectedLinkView;
