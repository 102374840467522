/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { UsageLog, UsageLogContext } from '@savant-components/catalog';

import AdminLayout from './AdminLayout';
import { useBotRunEvents } from '../../hooks/usage';
import { getUsageLogContext } from '../../contexts';

const UsageLogView = (): React.ReactElement => {
  const intl = useIntl();
  const ctx = React.useMemo(() => getUsageLogContext(intl), []);
  const { ontimeRangeUsageEvents, ontimeRangeUsageCsvData, ontimeRangeUsageMetrics } = useBotRunEvents();
  const style = css`
    padding: 16px;
    padding-right: 4.5rem;
    height: 100%;
  `;
  return (
    <AdminLayout module="usage">
      <div css={style}>
        <UsageLogContext.Provider value={ctx}>
          <UsageLog
            ontimeRangeUsageEvents={ontimeRangeUsageEvents}
            ontimeRangeUsageCsvData={ontimeRangeUsageCsvData}
            ontimeRangeUsageMetrics={ontimeRangeUsageMetrics}
          />
        </UsageLogContext.Provider>
      </div>
    </AdminLayout>
  );
};

export default UsageLogView;
