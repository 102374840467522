/* eslint-disable @typescript-eslint/no-explicit-any */
import { Execution, Recipe, BotAutomationSummary, Schedule } from '@savant-components/catalog';
import { AlertContainer } from 'react-alert';
import { Flow } from '../types';
import client, { ApiPromise, followPromise, handleError } from './client';

export const createRecipe = async (flow: Flow & { folderId?: string }): Promise<Flow> => {
  const payload = {
    recipe: flow,
  };
  return client.put(`/recipes`, payload).then(resp => {
    const flow: Flow = resp.data;
    return flow;
  });
};

export const copyRecipe = async (id: string): Promise<Flow> => {
  const payload = {
    id,
  };
  return client.post(`/recipes/copy`, payload).then(resp => {
    const flow: Flow = resp.data;
    return flow;
  });
};

export const exportRecipe = async (id: string): Promise<void> => {
  const { data: exportData } = await client.post<ApiPromise<undefined>>(`/recipes/export`, { id });
  exportData.createdTime = new Date();
  const downloadUrl = (await followPromise(exportData)) ?? '';
  window.location.href = downloadUrl;
};

export const importRecipe = async (body: FormData): Promise<Recipe> => {
  const { data } = await client.post<ApiPromise<Recipe>>(`/recipes/import`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  data.createdTime = new Date();
  return await followPromise(data);
};

export const draftRecipe = async (id: string, version: string): Promise<Flow> => {
  const payload = {
    id,
    version,
  };
  return client.post(`/recipes/draft`, payload).then(resp => {
    const flow: Flow = resp.data;
    return flow;
  });
};

export const sealRecipeVersion = async (id: string, version: string): Promise<Flow> => {
  const payload = {
    id,
    version,
  };
  return client.post(`/recipes/version`, payload).then(resp => {
    const flow: Flow = resp.data;
    return flow;
  });
};

export const updateRecipe = async (id: string, flow: Flow): Promise<Flow> => {
  return client
    .put(`/recipes`, {
      recipe: {
        ...flow,
        id,
      },
    })
    .then(resp => {
      const flow: Flow = resp.data;
      return flow;
    });
};

export const getFlow = async (id: string): Promise<Flow> => {
  return client.get(`/recipes/${id}`).then(resp => {
    const flow: Flow = resp.data;
    return flow;
  });
};

export const getRecipe = async (id?: string): Promise<Recipe> => {
  return client.get<Recipe>(`/recipes/${id}`).then(({ data }) => data);
};

export const getRecipeFromExecution =
  (alert: AlertContainer) =>
  (exec: Execution): Promise<Recipe> => {
    return getRecipe(exec.recipeId)
      .then((data: Recipe) => {
        return data;
      })
      .catch(err => {
        handleError(err, alert);
        return { id: exec.recipeId, name: '', description: '' } as Recipe;
      });
  };

export async function listRecipes(): Promise<Recipe[]> {
  return client.get(`/recipes`).then(resp => {
    return resp.data.slice.map((summary: { createdTime: any }) => {
      return {
        ...summary,
        createdTime: new Date(summary.createdTime),
      };
    });
  });
}

export async function getDependingRecipesBySourceId(sourceId: string): Promise<Recipe[]> {
  return client.get(`/recipes/source/${sourceId}`).then(res => {
    return res.data.slice;
  });
}

export async function getDependingRecipesByConnectionId(connectionId: string): Promise<Recipe[]> {
  return client.get(`/recipes/connection/${connectionId}`).then(res => {
    return res.data.slice;
  });
}

export async function getDependingSchedules(recipeId: string): Promise<Schedule[]> {
  const TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return client.get(`/recipes/${recipeId}/automation/summary?tz=${TZ}`).then(res => {
    return res.data.automationSummaries;
  });
}

export async function getBotAutomation(recipeId: string): Promise<BotAutomationSummary> {
  const TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return client.get(`/recipes/${recipeId}/botautomation?tz=${TZ}`).then(res => res.data);
}

export async function deleteRecipe(id: string): Promise<void> {
  await client.delete(`/recipes/${id}`);
}

export async function runRecipeNow(id: string, noticeOption: string): Promise<Recipe> {
  const reqPayload = {
    noticeOption,
    executionType: 'run_now',
  };
  return client.post(`/recipes/${id}/executions`, reqPayload).then(resp => resp.data?.execution);
}

export async function moveRecipe(recipeId: string, destFolderId: string): Promise<void> {
  const payload = {
    destFolderId,
  };
  await client.put(`/recipes/${recipeId}/folder`, payload).then(resp => {
    return resp;
  });
}

export async function updateRecipeName(id: string, newName: string): Promise<void> {
  const payload = {
    recipe: {
      name: newName,
    },
  };
  await client.put(`/recipes/${id}/name`, payload);
}

export async function updateRecipeMetadata(id: string, metadata: Recipe, skipParameters: boolean): Promise<Recipe> {
  return await client.put(`/recipes/${id}/metadata`, { ...metadata, skipParameters }).then(resp => resp.data);
}
