import React from 'react';
import { useTheme } from '@savant-components/theme';
import { useIntl } from 'react-intl';

const FooterCore = ({
  showConsent = false,
  showCopyRight = false,
}: {
  showConsent?: boolean;
  showCopyRight?: boolean;
}): JSX.Element => {
  const theme = useTheme();
  const thisYear: number = new Date().getFullYear();

  const intl = useIntl();
  const consentLabel = intl.formatMessage({ id: 'login.consent' });
  const serviceTermsLabel = intl.formatMessage({ id: 'login.serviceTerms' });
  const privacyPolicy = intl.formatMessage({ id: 'login.privacyPolicy' });
  const consent1 = consentLabel.split('{0}')[0];
  const consent23 = consentLabel.split('{0}')[1];
  const consent2 = consent23.split('{1}')[0];
  const consent3 = consent23.split('{1}')[1];

  return (
    <footer
      style={{
        marginTop: `2rem`,
        fontSize: 'small',
        color: theme.colors.text,
        textAlign: 'center',
      }}
    >
      {showConsent && (
        <div
          className="consent text-t10"
          style={{
            marginBottom: '0.5rem',
          }}
        >
          {consent1}
          <a href="https://www.savantlabs.io/eula" target="_blank" rel="noreferrer">
            {serviceTermsLabel}
          </a>
          {consent2}
          <a href="https://www.savantlabs.io/dpa" target="_blank" rel="noreferrer">
            {privacyPolicy}
          </a>
          {consent3}
        </div>
      )}
      {showCopyRight && (
        <div>
          &copy; {thisYear} Savant Labs, Inc. All rights reserved.
          {/* {` build-${process.env.GREACT_APP_APP_VERSION}`} */}
        </div>
      )}
    </footer>
  );
};

const Footer = React.memo(FooterCore);

export default Footer;
