import { TimeRange } from '@savant-components/basic';
import { UsageLogItem, UsageCsvData, UsageLogMetrics } from '@savant-components/catalog';
import client, { ApiPromise, followPromise } from './client';

export function listBotRunEvents(timeRange: TimeRange): Promise<UsageLogItem[]> {
  const payload = {
    timeRange,
    eventType: 'bot_run',
  };
  return client.post('/usage-events/events/async', payload).then(resp => {
    const promise: ApiPromise<UsageLogItem[]> = resp.data;
    promise.createdTime = new Date();
    return followPromise<UsageLogItem[]>(promise, undefined).then(result => {
      return result as UsageLogItem[];
    });
  });
}

export function listBotRunCsvData(timeRange: TimeRange): Promise<UsageCsvData[]> {
  const payload = {
    timeRange,
    eventType: 'bot_run',
  };
  return client.post('/usage-events/csv/async', payload).then(resp => {
    const promise: ApiPromise<UsageCsvData[]> = resp.data;
    promise.createdTime = new Date();
    return followPromise<UsageCsvData[]>(promise, undefined).then(result => {
      return result as UsageCsvData[];
    });
  });
}

export function listBotRunMetrics(timeRange: TimeRange): Promise<UsageLogMetrics> {
  const payload = {
    timeRange,
    eventType: 'bot_run',
  };
  return client.post('/usage-events/metrics/async', payload).then(resp => {
    const promise: ApiPromise<UsageLogMetrics> = resp.data;
    promise.createdTime = new Date();
    return followPromise<UsageLogMetrics>(promise, undefined).then(result => {
      return result as UsageLogMetrics;
    });
  });
}
