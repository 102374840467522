import { Schedule } from '@savant-components/catalog';
import { Automation } from '@savant-components/builder';

import client from './client';

export const saveAutomation = async ({
  cronExpression,
  id,
  recipeId,
  schedule,
  state,
  version,
}: {
  id: string;
  recipeId: string;
  version: string;
  cronExpression: string;
  state: string;
  schedule: Schedule;
}): Promise<Automation> => {
  console.log(schedule);
  const payload = {
    automation: {
      id,
      recipeId,
      version,
      cronExpression,
      state,
      schedule,
    },
  };
  return client.post(`/automations`, payload).then(resp => {
    return resp.data;
  });
};

export const updateAutomation = async (
  id: string,
  cronExpression: string,
  state: string,
  schedule: Schedule,
): Promise<Automation> => {
  if ('endTime' in schedule) {
    delete schedule.endTime;
  }
  const payload = {
    id,
    cronExpression,
    state,
    schedule,
  };
  return client.put(`/automations`, payload).then(resp => {
    return resp.data;
  });
};

export const getAutomationByRecipeId = async (recipeId: string): Promise<Automation> => {
  // const TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return client.get(`/recipes/${recipeId}/automation`).then(resp => {
    return resp.data;
  });
};
