/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { MouseEventHandler, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import ReCAPTCHA from 'react-google-recaptcha';

import { MInput, Loader, Button, Spacer } from '@savant-components/basic';
import { useTheme } from '@savant-components/theme';
import { Divider, Button as MButton } from '@mui/material';

import { bootstrapTab, getLoginRedirect, loginByPassword, verifyRecapthaToken } from '../services/login';
import {
  saveLandingUrl,
  getLandingUrl,
  removeLandingUrl,
  getAuthentication,
  saveSession,
  saveTabSession,
} from '../services/storage';

import { LANDING_URI } from '../constants';
import { handleError } from '../services/client';
import { SignInContext } from '../contexts';
import { forgotPassword } from '../services/login';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

export const SSO_PROVIDERS = ['google', 'microsoft', 'linkedin'] as const;

export function getProviderName(provider: (typeof SSO_PROVIDERS)[number]): string {
  if (provider === 'linkedin') {
    return 'LinkedIn';
  } else {
    return provider.replace(provider[0], provider[0].toUpperCase());
  }
}

const LoginForm = (): React.ReactElement => {
  const alert = useAlert();
  const intl = useIntl();
  const locale = intl.locale;
  const newToSavantLabel = intl.formatMessage({ id: 'login.newToSavant' }).replace('{0}', 'Savant');
  const signupHref = `/${locale}/signup`;
  const signinHref = `/${locale}/login`;
  const signInLabel = intl.formatMessage({ id: 'login.signIn' });
  const signUpLabel = intl.formatMessage({ id: 'login.signUp' });
  const invalidLoginLabel = intl.formatMessage({ id: 'login.invalidLogin' });
  const invalidEmailLabel = intl.formatMessage({ id: 'signup.invalidEmail' });
  const loginWithLabel = intl.formatMessage({ id: 'login.loginWith', defaultMessage: 'Log in with {0}' });
  const workingEmailLabel = intl.formatMessage({ id: 'login.workingEmail', defaultMessage: 'Working Email' });
  const forgotPasswordLabel = intl.formatMessage({ id: 'login.forgotPassword', defaultMessage: 'Forgot password?' });
  const passwordLabel = intl.formatMessage({ id: 'login.password', defaultMessage: 'Password' });
  const returnLoginLabel = intl.formatMessage({ id: 'login.returnLogin', defaultMessage: 'Back to login' });
  const sendLabel = intl.formatMessage({ id: 'login.send', defaultMessage: 'Send' });
  const forgotPasswordPromptLabel = intl.formatMessage({
    id: 'login.resetPasswordPrompt',
  });
  const forgotPasswordConfirmLabel = intl.formatMessage({
    id: 'login.resetPasswordConfirm',
  });

  const ctx = React.useContext(SignInContext);

  const [email, setEmail] = useState<string>('');
  const [isReturningEmail, setIsReturningEmail] = useState<boolean>();
  const [password, setPassword] = useState<string>('');
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [signinChannel, setSigninChannel] = useState<string>('');
  const [isInvalidLogIn, setIsInvalidLogIn] = useState<boolean>(false);
  const [isSendingResettingPassword, setIsSendingResettingPassword] = useState<boolean>(false);
  const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false);
  const [resettingPasswordEmailSend, setResettingPasswordEmailSend] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

  const reCAPTCHASiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
  const showReCAPTCHA =
    Boolean(reCAPTCHASiteKey) &&
    (window.location.hostname === 'localhost' ||
      window.location.hostname.endsWith('savant-dev.net') ||
      window.location.hostname.endsWith('savant-prod.net') ||
      window.location.hostname === 'app.savantlabs.io' ||
      window.location.hostname === 'app-eu.savantlabs.io') &&
    Boolean(email);
  console.log({
    hostname: window.location.hostname,
    showReCAPTCHA,
  });
  const _reCaptchaRef = React.useRef<ReCAPTCHA>(null);
  const disableSignInEmail = !email || !password || isSigningIn;

  const onClickSSO = (provider: string) => () => {
    if (!isSigningIn && typeof window !== 'undefined') {
      setSigninChannel(provider);
      setIsSigningIn(true);
      getLoginRedirect(provider)
        .then(redirectUrl => {
          const landing = getLandingUrl() || `/${locale}${LANDING_URI}`;
          saveLandingUrl(landing);
          window.location.href = redirectUrl;
        })
        .catch(err => {
          handleError(err, alert);
          setIsSigningIn(false);
          setSigninChannel('');
        });
    }
  };
  const onClickContinueEmail = () => {
    setSigninChannel('email');
    setIsSigningIn(true);
    const recaptchaValue = _reCaptchaRef.current.getValue();
    // _reCaptchaRef.current.reset();
    verifyRecapthaToken(recaptchaValue, email).then(({ success }) => {
      if (success) {
        loginByPassword(email, password)
          .then(session => {
            saveSession(session);
            const auth = getAuthentication();
            return bootstrapTab(auth.access_token || '').then(tab => {
              const landingUri = getLandingUrl() || LANDING_URI;
              removeLandingUrl();
              setIsReturningEmail(false);
              saveTabSession(tab);
              window.location.replace(landingUri);
            });
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              // new user
              setIsInvalidLogIn(true);
              setIsReturningEmail(true);
            } else {
              handleError(err, alert);
            }
            setIsSigningIn(false);
            setSigninChannel('');
          });
      } else {
        alert.error(intl.formatMessage({ id: 'signup.recaptchaFailed' }));
        setIsSigningIn(false);
        setSigninChannel('');
        return;
      }
    });
  };

  const onClickForgotPassword: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    setIsResettingPassword(true);
    e.stopPropagation();
  };

  const onClickResetPassword: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setIsSendingResettingPassword(true);
    forgotPassword(email, locale)
      .then(() => {
        setResettingPasswordEmailSend(true);
      })
      .catch(err => handleError(err, alert))
      .finally(() => {
        setIsSendingResettingPassword(false);
      });
    e.stopPropagation();
  };

  const onBlurWorkEmailInput = () => {
    setIsValidEmail(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email));
  };

  const theme = useTheme();
  const { breakpoint } = useWindowDimensions();

  const smStyle = css`
    width: 100%;
    height: 100%;
  `;
  const rootStyle = css`
    z-index: 10;
    margin: auto;
    width: min(100%, 400px);
    max-height: 100%;
    overflow-y: auto;
    box-shadow: 0 0 16px #d4d0ff;
    border-radius: 16px;
    border-radius: ${ctx.breakpoint === 'sm' ? 0 : '16px'};
    ${breakpoint === 'sm' ? smStyle : ''}
    padding: 50px 35px 50px 35px;
    text-align: center;
    background: ${theme.colors.b6}e6;
    .subtitle {
      margin-top: 16px;
      text-align: left;
    }

    .top-form {
      margin: 20px 0;
    }
    .MuiDivider-root {
      margin: 25px 0;
    }
    .bottom-form {
      margin: auto;
      .prompt {
        margin: 30px 0px 15px 4px;
        text-align: left;
      }
      & > .login-error {
        color: ${theme.colors.s4};
        text-align: left;
        margin: 8px 0;
      }
      & > .input {
        margin: 20px 0;
        text-align: left;
        .MuiFormControl-root {
          .input-label-with-icon {
            margin: -4px 4px;
            .MuiFormLabel-root {
              font-size: 16px;
              color: ${theme.colors.f1};
            }
          }
          .MuiInputBase-root {
            .MuiInputBase-input {
              border-radius: 6px;
              font-size: 0.75rem;
            }
          }
          &:error {
            .MuiInputBase-input {
              border-color: '#FF0F4B';
              .MuiFormLabel-root {
                color: ${theme.colors.error};
              }
            }
          }

          &:hover {
            .MuiInputBase-input {
              border-color: ${theme.colors.o6};
            }
          }
          width: 100%;
          .MuiInputBase-input {
            width: 100%;
          }
        }
      }
      & > .consent {
      }
      & > .forgot-password {
        text-align: left;
      }
    }

    .recaptcha-row {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-row {
      margin: 10px 0;
      border-radius: 6px;
      .btn-content {
        width: calc(100% - 60px);
        padding: 4px 0;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-label {
          flex: 4;
          margin-left: 16px;
          text-align: left;
          color: ${theme.colors.b1};
          text-transform: none;
        }
        .btn-label-disabled {
          color: inherit;
        }
      }
    }
    .login {
      margin: 15px 0;
      text-align: left !important;
      .MuiButtonBase-root {
        border-radius: 6px;
      }
      .btn-content {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .btn-row-sso {
      border: 1px solid ${theme.colors.o2};
      &:hover {
        border-color: ${theme.colors.p2};
      }
    }
    .forgot-password {
      margin-left: 2px;
      text-align: left;
      .MuiButtonBase-root {
        width: fit-content;
        min-width: 8rem;
        font-size: 0.75rem;
        margin: 24px 0;
        border-radius: 6px;
      }
    }
  `;

  const mBtnStyle = {
    boxShadow: 'unset',
    background: 'white',
  };

  const mLoginBtnStyle = {
    boxShadow: 'unset',
    background: theme.colors.p1,
    color: theme.colors.b6,
    borderradius: '6px',
  };

  const view = Boolean(isResettingPassword) ? 'reset-password' : 'login';

  return (
    <div css={rootStyle}>
      <div id="logo">
        <img src="https://assets.savantlabs.io/brand/logo.svg" width="113px" />
      </div>
      <Spacer direction="vertical" size="10px" />
      {view === 'login' && (
        <React.Fragment>
          <div className="subtitle text-t10">
            {newToSavantLabel + ' '} <a href={signupHref}>{signUpLabel}</a>
          </div>
          <div className="top-form">
            {SSO_PROVIDERS.map(provider => (
              <div className="btn-row btn-row-sso" key={provider}>
                <MButton
                  variant="contained"
                  onClick={onClickSSO(provider)}
                  fullWidth
                  style={mBtnStyle}
                  disabled={isSigningIn}
                >
                  <div className="btn-content">
                    <div>
                      {signinChannel === provider ? (
                        <Loader size={20} />
                      ) : (
                        <img src={`https://assets.savantlabs.io/img/signin_${provider}.png`} height="20px" />
                      )}
                    </div>
                    <div className={`btn-label text-t9` + (isSigningIn ? ' btn-label-disabled' : '')}>
                      {loginWithLabel.replace('{0}', getProviderName(provider))}
                    </div>
                  </div>
                </MButton>
              </div>
            ))}
          </div>
          <Divider className="text-t12"> OR </Divider>
          <div className="bottom-form">
            <div className="input">
              <MInput
                value={email}
                name="email"
                onChange={e => {
                  setEmail(e.target.value);
                  if (isReturningEmail) {
                    setIsReturningEmail(false);
                  }
                }}
                label={workingEmailLabel}
                placeholder=""
                dataTestId="input-email-test-id"
                disabled={isSigningIn}
                error={Boolean(isInvalidLogIn)}
              />
            </div>
            <div className="input">
              <MInput
                value={password}
                onChange={e => setPassword(e.target.value)}
                name="password"
                type="password"
                label={passwordLabel}
                placeholder=""
                dataTestId="input-password-test-id"
                disabled={isSigningIn}
                error={Boolean(isInvalidLogIn)}
                errorMsg={invalidLoginLabel}
              />
            </div>
            {showReCAPTCHA && (
              <div className="recaptcha-row">
                <ReCAPTCHA sitekey={reCAPTCHASiteKey} ref={_reCaptchaRef} />
              </div>
            )}
            <div className="login">
              <MButton
                variant="contained"
                onClick={onClickContinueEmail}
                style={mLoginBtnStyle}
                disabled={disableSignInEmail}
              >
                <div className="btn-content">
                  {signinChannel === 'email' ? (
                    <Loader size={24} />
                  ) : (
                    <div className={'btn-label text-t9' + (disableSignInEmail ? ' btn-label-disabled' : '')}>
                      {signInLabel}
                    </div>
                  )}
                </div>
              </MButton>
            </div>
          </div>
          <div className="forgot-password text-t10">
            <a href="" target="_blank" rel="noreferrer" onClick={onClickForgotPassword}>
              {forgotPasswordLabel}
            </a>
          </div>
        </React.Fragment>
      )}
      {view === 'reset-password' && (
        <React.Fragment>
          {Boolean(isSendingResettingPassword) ? (
            <Loader size={100} />
          ) : (
            <div className="bottom-form">
              {resettingPasswordEmailSend ? (
                <div className="prompt text-t10">{forgotPasswordConfirmLabel}</div>
              ) : (
                <div className="prompt text-t10">{forgotPasswordPromptLabel}</div>
              )}
              <div className="input">
                <MInput
                  value={email}
                  name="email"
                  onChange={e => {
                    setEmail(e.target.value);
                    if (isReturningEmail) {
                      setIsReturningEmail(false);
                    }
                  }}
                  onBlur={onBlurWorkEmailInput}
                  label={workingEmailLabel}
                  placeholder=""
                  dataTestId="input-email-test-id"
                  error={!isValidEmail}
                  errorMsg={invalidEmailLabel}
                />
              </div>
              <div className="forgot-password">
                {!resettingPasswordEmailSend && (
                  <React.Fragment>
                    <Button variant="contained" fullWidth onClick={onClickResetPassword} disabled={!isValidEmail}>
                      {sendLabel}
                    </Button>
                  </React.Fragment>
                )}
                <a className="text-t10" href={signinHref}>
                  {returnLoginLabel}
                </a>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default LoginForm;
