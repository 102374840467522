import React, { useMemo } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useIntl, IntlProvider } from 'react-intl';

import TagManager from 'react-gtm-module-nonce';
import { LicenseInfo } from '@mui/x-license-pro';

import SEO from './seo';

import './webfonts.css';
import { BasicContext, BasicContextProps } from '@savant-components/basic';

import { useIdleTracker } from '../hooks/auth';
import { getBasicContext } from '../contexts';
import { getSession, getTabSession } from '../services/storage';
import { useTrackingEvent } from '../hooks/useTrackingEvent';

import de_de from '../translate/de_de.json';
import en_us from '../translate/en_us.json';
import es_es from '../translate/es_es.json';
import fr_fr from '../translate/fr_fr.json';
import pt_pt from '../translate/pt_pt.json';
import zh_cn from '../translate/zh_cn.json';
import zh_tw from '../translate/zh_tw.json';

import NotFound from './notfound';
import LoginPage from '../pages/login';

import AppPage from '../pages/app';
import ResetPasswordPage from '../pages/reset_password';
import SignUpPage from '../pages/signup';

const MemorizedSEO = React.memo(SEO);
const gtmContainerId = process.env.REACT_APP_GTM_CONTAINER_ID;
const region = process.env.REACT_APP_REGION || 'us';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LIC || process.env.GATSBY_MUI_X_PRO_LIC || '');

const IntlMain = ({ title, children }: { title: string; children: JSX.Element[] | JSX.Element }) => {
  const intl = useIntl();
  const ctx: BasicContextProps = getBasicContext(intl);

  const session = getSession();
  const tab = getTabSession();
  useIdleTracker(() => {
    console.debug('Should show count down.');
  });

  const updateDataLayer = () => {
    const subscription = tab?.subscription;
    const dataLayer = {
      region: region,
      session_id: tab?.tabId,
      root_session_id: session?.sessionId,
      session_started_at: session?.createdAt ? session.createdAt / 1000 : undefined,
      user_id: session?.user?.id,
      user_name: session?.user?.name,
      user_email: session?.user?.email,
      namespace: tab?.namespace,
      org_id: tab?.organization?.id,
      org_name: tab?.organization?.name,
      workspace_id: tab?.workspace?.id,
      workspace_name: tab?.workspace?.name,
      org_subscription_tier: subscription?.tier,
      org_subscription_status: subscription?.status,
      org_commercial_stage: subscription?.commercialStage,
      org_subscription_start_date: subscription?.startDateStr,
      org_subscription_end_date: subscription?.endDateStr,
    };
    const tagManagerArgs = {
      dataLayer: dataLayer,
      dataLayerName: 'PageDataLayer',
      nonce: 'DhcnhD3khTMePgXw',
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  useTrackingEvent();

  React.useEffect(() => {
    if (typeof window !== 'undefined' && gtmContainerId) {
      const tagManagerArgs = {
        gtmId: gtmContainerId,
        dataLayerName: 'PageDataLayer',
        nonce: 'DhcnhD3khTMePgXw',
      };
      TagManager.initialize(tagManagerArgs);
      updateDataLayer();
    }
  }, [session]);

  return (
    <BasicContext.Provider value={ctx}>
      <MemorizedSEO title={title} />
      <main>{children}</main>
    </BasicContext.Provider>
  );
};

const WithIntl = ({ locale, language }: { locale: string; language: string }): React.ReactElement => {
  const messages = useMemo(() => {
    switch (locale) {
      case 'de':
        return de_de;
      case 'es':
        return es_es;
      case 'fr':
        return fr_fr;
      case 'pt':
        return pt_pt;
      case 'zh':
        return language == 'zh_tw' ? zh_tw : zh_cn;
      default:
        return en_us;
    }
  }, [locale, language]);
  return (
    <IntlProvider locale={locale} messages={messages}>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route
            path="login"
            element={
              <IntlMain title="Login">
                <LoginPage />
              </IntlMain>
            }
          />
          <Route
            path="signup"
            element={
              <IntlMain title="Sign Up">
                <SignUpPage />
              </IntlMain>
            }
          />
          <Route
            path="reset_password"
            element={
              <IntlMain title="Reset Password">
                <ResetPasswordPage />
              </IntlMain>
            }
          />
          <Route
            path="app/*"
            element={
              <IntlMain title="Application">
                <AppPage />
              </IntlMain>
            }
          />
          <Route path="" element={<Navigate to="login" />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </IntlProvider>
  );
};

export default WithIntl;
