import { useState, useEffect } from 'react';

function getWindowDimensions() {
  let width = 0;
  let height = 0;
  if (typeof window !== 'undefined') {
    width = window.innerWidth;
    height = window.innerHeight;
  }
  if (width === 0) {
    return {
      width,
      height,
      orientation: undefined,
      breakpoint: undefined,
    };
  } else {
    const orientation = width >= height ? 'landscape' : 'portrait';
    let breakpoint = 'lg';
    if (orientation === 'portrait') {
      if (width <= 768) {
        breakpoint = 'md';
      }
      if (width <= 440) {
        breakpoint = 'sm';
      }
    } else {
      if (width <= 1024) {
        breakpoint = 'md';
      }
      if (width <= 768) {
        breakpoint = 'sm';
      }
    }
    return {
      width,
      height,
      orientation: orientation as 'landscape' | 'portrait' | undefined,
      breakpoint: breakpoint as 'sm' | 'md' | 'lg' | undefined,
    };
  }
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
