/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Users, UsersContext } from '@savant-components/catalog';

import AdminLayout from './AdminLayout';
import { getUsersContext } from '../../contexts';
import { useUsers } from '../../hooks/users';
import { Loader } from '@savant-components/basic';

const UsersView = (): React.ReactElement => {
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');
  const ctx = React.useMemo(() => getUsersContext(intl), []);
  const { users, isLoading, isOrgAdmin, updateMemberRole } = useUsers();
  const style = css`
    padding: 16px;
    padding-right: 4.5rem;
    height: 100%;
  `;
  return (
    <AdminLayout module="users">
      <div css={style}>
        {isLoading ? (
          <Loader size={200} />
        ) : (
          <UsersContext.Provider value={ctx}>
            <Users
              users={users}
              updateMemberRole={updateMemberRole}
              isWorkspaceAdmin={isOrgAdmin}
              enableBusinessUser={true}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </UsersContext.Provider>
        )}
      </div>
    </AdminLayout>
  );
};

export default UsersView;
