import { Client, IFrame, IMessage } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { getAuthentication, getTabSession } from './storage';
import { nanoid } from 'nanoid';

let stompClient: Client | undefined;

function initWs(onClose: () => void, callback: (e: IMessage) => void): Client {
  let sock: WebSocket;
  if (window.WebSocket && window.location.origin.indexOf('localhost') < 0) {
    let wsUrl = '/api/websocket';
    if (typeof window !== 'undefined') {
      if (window.location.host.indexOf(':3000') >= 0) {
        wsUrl = 'ws://localhost:8080/api/websocket';
      } else {
        wsUrl = window.location.origin.replace('https://', 'wss://') + '/api/websocket';
      }
    }
    sock = new WebSocket(wsUrl);
  } else if (Boolean(process.env.REACT_APP_API_URL)) {
    const wsUrl = process.env.REACT_APP_API_URL?.replace('https://', 'wss://') + '/api/websocket';
    console.log('Using websocket: ' + wsUrl);
    sock = new WebSocket(wsUrl);
  } else {
    const sockJsUrl = '/api/sockjs';
    console.log('Use sockJs: ' + sockJsUrl);
    sock = new SockJS(sockJsUrl);
  }
  // // always use SockJs
  // const sock = new SockJS('/api/sockjs');
  const tabSession = getTabSession();
  const client = new Client({
    webSocketFactory: () => sock,
    connectHeaders: { simpUser: tabSession?.sessionId, sessionId: tabSession?.sessionId, tabId: tabSession?.tabId },
    // debug: str => console.log(str),
    reconnectDelay: 1000,
    heartbeatIncoming: 15000,
    heartbeatOutgoing: 5000,
    onConnect: (frame: IFrame) => {
      console.debug('WebSocket connected: ', frame);
      // const userId = frame.headers['user-name'];
      // stompClient?.subscribe(`/user/${userId}/queue/heartbeat`, callback);
      stompClient?.subscribe(`/topic/global`, callback);
      stompClient?.subscribe(`/topic/session/${tabSession?.sessionId}`, callback);
      stompClient?.subscribe(`/topic/tab/${tabSession?.tabId}`, callback);
    },
    onDisconnect: (frame: IFrame) => {
      console.debug('WebSocket disconnected', frame);
    },
    onStompError: (frame: IFrame) => {
      console.log('Stomp error: ' + frame.headers['message']);
      console.log('Additional details: ' + frame.body);
    },
    onWebSocketClose: (frame: IFrame) => {
      console.debug('WebSocket closed', frame);
      onClose();
    },
    onWebSocketError: (frame: IFrame) => {
      console.log('Websocket error: ' + frame.headers['message']);
      console.log('Additional details: ' + frame.body);
    },
  });
  client.activate();
  return client;
}

interface WebsocketInitProps {
  onClose: () => void;
  callback: (e: IMessage) => void;
}

export const ws = {
  init: ({ onClose, callback }: WebsocketInitProps): void => {
    if (!stompClient) {
      stompClient = initWs(() => {
        stompClient = undefined;
        onClose();
      }, callback);
    }
  },
  send: (msg: Record<string, unknown>): string => {
    if (stompClient) {
      const requestId = nanoid(8);
      const authToken = getAuthentication()?.access_token;
      const tabId = getTabSession()?.tabId;
      stompClient.publish({
        destination: '/app',
        body: JSON.stringify({
          ...msg,
          authToken,
          tabId,
          requestId,
        }),
      });
      return requestId;
    } else {
      return '';
    }
  },
};
