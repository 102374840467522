/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
// import { useIntl } from 'react-intl';

import { useAddSource, useSourceDetail } from '../../hooks/source';
import { ClickableIcon, Loader, InfoPanelPopover, EditableText } from '@savant-components/basic';
import { Logo, SourceDetailsWrapper, FileSystemDetailsWrapper, getConnDef } from '@savant-components/catalog';
import { useTheme } from '@savant-components/theme';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const SourceDetailView = (): React.ReactElement => {
  const { sourceId } = useParams();
  const theme = useTheme();
  const { isLoading, source, isFileSystem, sample, getConnectionInfo, onUpdateName } = useSourceDetail(
    sourceId as string,
  );
  const { configService } = useAddSource();
  // const intl = useIntl();
  const sourceName = source ? source.name : '';

  const intl = useIntl();
  const navigate = useNavigate();
  const onClickBack = () => {
    navigate(`/${intl.locale}/app/source`);
  };

  const style = css`
    height: calc(100vh - 4rem);
  `;
  const wrapperStyle = css`
    height: 100%;
    & > .source-header {
      height: 52px;
      margin: 0 24px;
      display: flex;
      align-items: center;
      & > .source-name {
        margin-left: 24px;
        margin-right: 12px;
      }
      & > .connector {
        padding: 4px 8px;
        background: ${theme.colors.b5};
        display: flex;
        align-items: center;
        & > .connector-name {
          margin-left: 8px;
          text-transform: uppercase;
        }
      }
    }
    & > .source-detail {
      border-top: 1px solid ${theme.colors.o2};
      height: calc(100% - 4rem);
    }
  `;
  return (
    <div css={style}>
      {isLoading && <Loader size={200} />}
      {!isLoading && source && (
        <div css={wrapperStyle}>
          <div className="source-header">
            <ClickableIcon color={theme.colors.i2} size={24} icon={['las', 'angle-left']} onClick={onClickBack} />
            <EditableText text={sourceName} onUpdateText={onUpdateName} size="t3" />
            <div className="connector">
              <Logo name={source?.config?.connector || ''} size={12} />
              <div className="text-t10 connector-name"> {getConnDef(source?.config?.connector)?.name}</div>
            </div>
            {source.connectionId && <InfoPanelPopover id={source.connectionId} getInfo={getConnectionInfo} />}
          </div>
          <div className="source-detail">
            {isFileSystem ? (
              <FileSystemDetailsWrapper service={configService} source={source} initialSample={sample} readOnly />
            ) : (
              <QueryClientProvider client={new QueryClient()}>
                <SourceDetailsWrapper service={configService} source={source} initialSample={sample} readOnly />
              </QueryClientProvider>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SourceDetailView;
