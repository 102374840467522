/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { useTheme } from '@savant-components/theme';
import { Tabs as MTabs, Tab } from '@mui/material';
import { nanoid } from 'nanoid';

const Tabs = ({
  activeTab,
  setActiveTab,
  tabData,
  hiddenTabs = [],
}: {
  activeTab: string;
  setActiveTab: (newTab: string) => void;
  tabData: { label: string; value: string }[];
  hiddenTabs?: string[];
}): React.ReactElement => {
  const theme = useTheme();
  const onSwitchTab = (newTab: string) => {
    if (newTab !== activeTab) {
      setActiveTab(newTab);
    }
  };
  const style = css`
    & .MuiTabs-root {
      .MuiTab-root {
        color: ${theme.colors.f1};
      }
      .Mui-selected {
        color: ${theme.colors.p1};
      }
      .MuiTab-root:hover {
        color: ${theme.colors.p2};
      }
    }
  `;
  return (
    <div css={style}>
      <MTabs
        sx={{ height: '40px', minHeight: '40px' }}
        value={activeTab}
        onChange={(_, newVal) => onSwitchTab(newVal)}
        aria-label={`tabs-${nanoid(10)}`}
      >
        {tabData.map(({ label, value }) => (
          <Tab
            data-testid="analyses-button-test-id"
            key={`tab-${value}`}
            className={activeTab === value ? 'text-t9' : 'text-t10'}
            value={value}
            label={label}
            hidden={hiddenTabs.includes(value)}
          />
        ))}
      </MTabs>
    </div>
  );
};

export default Tabs;
