import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Recipe, Recipes } from '@savant-components/catalog';

import { Filter, Loader, LoaderModal, useLocalStorage } from '@savant-components/basic';
import { getDestinationSystemArray, getSourceSystemArray, getTagArray, useRecipes } from '../../hooks/recipes';
import { createRecipe } from '../../services/recipes';
import { handleError } from '../../services/client';

import CatalogLayout from './CatalogLayout';
import { getTabSession } from '../../services/storage';
import { getOwnerArray } from '../../utils/utils';

const DEFAULT_FOLDER = 'home';

type ProperReturnValue = (item: Recipe, folderId: string) => Promise<Recipe>;
const RecipesView = (): JSX.Element => {
  const alert = useAlert();
  const intl = useIntl();
  const tab = getTabSession();
  const navigate = useNavigate();
  const locale = intl.locale;
  const submittedMsg = useMemo(() => intl.formatMessage({ id: 'builder.submitted' }), [intl]);

  const setFilterValues = (recipes: Recipe[]) => {
    const typeFilter = filters.find(f => f.label === 'Source System(s)');
    if (typeFilter) {
      typeFilter.options = getSourceSystemArray(recipes || []);
    }
    const phaseFilter = filters.find(f => f.label === 'Destination System(s)');
    if (phaseFilter) {
      phaseFilter.options = getDestinationSystemArray(recipes || []);
    }
    const ownerFilter = filters.find(f => f.label === 'Owner');
    if (ownerFilter) {
      ownerFilter.options = getOwnerArray(recipes || []);
    }
    const tagFilter = filters.find(f => f.label === 'Tag');
    if (tagFilter) {
      tagFilter.options = getTagArray(recipes || []);
    }
    setFilters([...filters]);
  };

  const {
    isLoading,
    recipes,
    onRunNow,
    onDelete,
    onCopy,
    onEdit,
    onExport,
    onImport,
    getRecipeSummary,
    onGoToSchedule,
    onMoveToFolder,
    folders,
    addFolder,
    updateFolder,
    deleteFolder,
  } = useRecipes({ messages: { submitted: submittedMsg }, setFilterValues });

  function filtersInitial(recipes: Recipe[]): Filter[] {
    return [
      {
        label: 'Last Modified At',
        type: 'date',
        placeHolder: 'Select date',
        value: [null, null],
      },
      {
        label: 'Last Run Date',
        type: 'date',
        placeHolder: 'Select date',
        value: [null, null],
      },
      {
        label: 'Source System(s)',
        type: 'select',
        placeHolder: 'All systems',
        value: [],
        options: getSourceSystemArray([...recipes]),
      },
      {
        label: 'Destination System(s)',
        type: 'select',
        placeHolder: 'All systems',
        value: [],
        options: getDestinationSystemArray([...recipes]),
      },
      {
        label: 'Owner',
        type: 'select',
        placeHolder: 'All systems',
        value: [],
        options: getOwnerArray([...recipes]),
      },
      {
        label: 'Tag',
        type: 'select',
        placeHolder: 'All systems',
        value: [],
        options: getTagArray([...recipes]),
      },
    ];
  }

  const host = window.location.host;
  const [filters, setFilters] = useLocalStorage<Filter[]>(`${host}filterOptions`, filtersInitial(recipes));

  useEffect(() => {
    return () => setFilters(filtersInitial(recipes));
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState('');
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const loaderMessage = intl.formatMessage({ id: 'app.recipe.creatingRecipe' });
  const getRecipeLink = (recipe: Recipe) => `/${locale}/app/flow/${recipe.id}?rns=${tab.namespace}`;

  const folderId = searchParams.get('folderId') || DEFAULT_FOLDER;
  const setFolderId = (folderId: string) => {
    if (folderId === DEFAULT_FOLDER) {
      setSearchParams(
        {},
        {
          replace: true,
        },
      );
    } else {
      setSearchParams(
        {
          folderId,
        },
        {
          replace: true,
        },
      );
    }
  };

  const onClickAdd = () => {
    setIsCreating(true);
    createRecipe({
      folderId,
      nodes: [],
    })
      .then(data => {
        navigate(`/${intl.locale}/app/flow/${data.id}?rns=${tab.namespace}`);
        setIsCreating(false);
      })
      .catch(err => handleError(err, alert));
  };

  return (
    <CatalogLayout module="analysis">
      <Box display="contents">{isLoading ? <Loader size={200} /> : null}</Box>
      <Box sx={{ display: isLoading ? 'none' : 'contents' }}>
        <Recipes
          filterOptions={filters}
          setFilterOptions={setFilters}
          recipes={recipes}
          getRecipeLink={getRecipeLink}
          onRunNow={onRunNow}
          onClickAdd={onClickAdd}
          onDelete={onDelete as unknown as (d: Recipe) => Promise<Recipe>}
          onCopy={onCopy as unknown as (d: Recipe) => Promise<Recipe>}
          onEdit={onEdit}
          onExport={onExport}
          onImport={onImport}
          getRecipeSummary={getRecipeSummary}
          onGoToSchedule={onGoToSchedule}
          onMoveToFolder={onMoveToFolder as unknown as ProperReturnValue}
          folders={folders}
          addFolder={addFolder}
          updateFolder={updateFolder}
          deleteFolder={deleteFolder}
          initialFolderId={DEFAULT_FOLDER}
          folderId={folderId}
          setFolderId={setFolderId}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </Box>
      <LoaderModal isOpen={isCreating} message={loaderMessage} />
    </CatalogLayout>
  );
};

export default RecipesView;
