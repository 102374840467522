import { BizApp, RecipeParameter } from '@savant-components/catalog';
import client from './client';

export const getBizApp = async (appId: string): Promise<BizApp> => {
  return client.get(`/bizapps/${appId}`).then(res => res.data);
};

export const getBizAppByRecipeId = async (recepId: string): Promise<BizApp> => {
  return client.get(`/recipes/${recepId}/bizapp`).then(res => {
    const bizApp = res.data;
    return {
      ...bizApp,
      appUrl: getAppUrl(bizApp),
    };
  });
};

export const saveBizApp = async (recipeId: string, version: string, bizApp: BizApp): Promise<BizApp> => {
  return client
    .post(`/bizapps`, {
      recipeId,
      version,
      bizApp,
    })
    .then(res => {
      const bizApp = res.data;
      return {
        ...bizApp,
        appUrl: getAppUrl(bizApp),
      };
    });
};

export const submitBizAppExec = async (bizappId: string, parameters: RecipeParameter[]): Promise<void> => {
  return client.post(`/bizapps/${bizappId}/executions`, { parameters }).then(res => res.data);
};

function getAppUrl(bizApp: BizApp): string {
  const href = window.location.href;
  const matches = href.match(/^https?\:\/\/[^/]+\/[a-z]{2}\/app/gm) || [''];
  const appUrl = matches[0];
  return `${appUrl}/bizapp/${bizApp.id}/submit?rns=${bizApp.namespace}`;
}
