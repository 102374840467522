import client from './client';
import { NLPFeedback, NLPRequest, NLPResponse } from '@savant-components/builder';

export async function askExpression(req: NLPRequest): Promise<NLPResponse> {
  return client.post(`/openai/prompt/expression`, req).then(resp => {
    return resp.data as NLPResponse;
  });
}

export async function giveFeedback(req: NLPFeedback): Promise<void> {
  return client.post(`/openai/feedbacks`, req);
}
