/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { AggregatedReAuthConnectionState, AuthenticationContext, ReAuthConnection } from '@savant-components/catalog';

import { Loader, LoaderModal } from '@savant-components/basic';
import { useAddConnection, useConnectionDetail } from '../../hooks/connection';
import {
  removeRedirectState,
  getAddConnectionWizardContext,
  getAddSourceWizardContext,
  removeAddConnectionWizardContext,
  removeAddSourceWizardContext,
  saveNewDestNode,
} from '../../services/storage';
import { getAuthenticationContext } from '../../contexts';
import { useParams } from 'react-router-dom';

const EditConnectionView = (): JSX.Element => {
  const { connectionId } = useParams();
  const intl = useIntl();
  const locale = intl.locale;
  const { isLoading: isLoadingConnection, connection } = useConnectionDetail(connectionId as string);
  const {
    isLoading: isProcessingRequest,
    isRedirecting,
    restoreSteps,
    saveAndAuthenticate,
    confirmReAuthConnection,
    authenticateBasicAuth,
    authenticateSshAuth,
    authenticateApiKeyAuth,
    authenticateDbAuth,
    authenticateAwsAuth,
    authenticateGoogleSAAuth,
    authenticateCustomOAuth,
    authenticateGenericApiAuth,
  } = useAddConnection();
  const authCtx = getAuthenticationContext(intl);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const onCancelAdd = () => {
    let cancelUrl = `/${locale}/app/connection`;
    const ctx = getAddConnectionWizardContext();
    if (ctx === 'connections') {
      cancelUrl = `/${locale}/app/connection`;
    } else if (ctx === 'add-source') {
      const srcCtx = getAddSourceWizardContext();
      if (srcCtx !== undefined && srcCtx.startsWith('edit-recipe')) {
        cancelUrl = srcCtx.replace('edit-recipe', `/${locale}/app/flow`);
      } else {
        cancelUrl = `/${locale}/app/source`;
      }
      removeAddSourceWizardContext();
    } else if (ctx !== undefined && ctx.startsWith('edit-recipe')) {
      cancelUrl = ctx.replace('edit-recipe', `/${locale}/app/flow`);
    }
    removeAddConnectionWizardContext();
    window.location.href = cancelUrl;
  };
  const onConfirmAdd = (state: AggregatedReAuthConnectionState) => {
    setIsCreating(true);
    confirmReAuthConnection(connectionId as string, state).then(connection => {
      removeRedirectState();
      const ctx = getAddConnectionWizardContext();
      if (ctx === 'add-source') {
        removeAddConnectionWizardContext();
        window.location.replace(`/${locale}/app/wizard/newsrc?connectionId=${connection.id}`);
      } else if (ctx !== undefined) {
        let returnUrl = `/${locale}/app/connection`;
        if (ctx.startsWith('edit-recipe')) {
          saveNewDestNode({
            ...connection,
            createdTime: undefined,
          });
          returnUrl = ctx.replace('edit-recipe', `/${locale}/app/flow`);
        }
        removeAddConnectionWizardContext();
        window.location.href = returnUrl;
      }
      setIsCreating(false);
    });
  };
  const loaderMessage = intl.formatMessage({ id: 'app.connection.updatingConnection' });
  const isRedirectingMessage = intl.formatMessage({ id: 'app.connection.isRedirecting' });
  const style = css`
    height: calc(100vh - 4rem);
  `;
  return (
    <React.Fragment>
      <div css={style}>
        {isLoadingConnection || isProcessingRequest ? (
          <Loader size={200} />
        ) : (
          connection && (
            <AuthenticationContext.Provider value={authCtx}>
              <ReAuthConnection
                onCancel={onCancelAdd}
                onConfirm={onConfirmAdd}
                connection={connection}
                restoreSteps={
                  restoreSteps || [
                    {
                      id: 'authenticate',
                      name: 'Authenticate',
                      isReachable: true,
                      isActive: true,
                      state: {
                        connector: connection.type,
                        name: connection.name,
                        description: connection.description,
                        auth: {
                          ...connection,
                        },
                      },
                    },
                  ]
                }
                onSaveAndAuthenticate={saveAndAuthenticate}
                authenticateBasicAuth={authenticateBasicAuth}
                authenticateSshAuth={authenticateSshAuth}
                authenticateDbAuth={authenticateDbAuth}
                authenticateApiKeyAuth={authenticateApiKeyAuth}
                authenticateAwsAuth={authenticateAwsAuth}
                authenticateGoogleSA={authenticateGoogleSAAuth}
                authenticateCustomOAuth={authenticateCustomOAuth}
                authenticateGenericApi={authenticateGenericApiAuth}
                authenticateCustomDbAuth={authenticateDbAuth}
              />
            </AuthenticationContext.Provider>
          )
        )}
      </div>
      <LoaderModal isOpen={isCreating} message={loaderMessage} />
      <LoaderModal isOpen={isRedirecting} message={isRedirectingMessage} />
    </React.Fragment>
  );
};

export default EditConnectionView;
