import { useAlert } from 'react-alert';

import { TimeRange } from '@savant-components/basic';
import { UsageLogItem, UsageCsvData, UsageLogMetrics } from '@savant-components/catalog';

import { listBotRunEvents, listBotRunCsvData, listBotRunMetrics } from '../services/usage';
import { handleError } from '../services/client';

export function useBotRunEvents(): {
  ontimeRangeUsageEvents: (timeRange: TimeRange) => Promise<UsageLogItem[]>;
  ontimeRangeUsageCsvData: (timeRange: TimeRange) => Promise<UsageCsvData[]>;
  ontimeRangeUsageMetrics: (timeRange: TimeRange) => Promise<UsageLogMetrics>;
} {
  const alert = useAlert();
  const ontimeRangeUsageEvents = async (timeRange: TimeRange): Promise<UsageLogItem[]> => {
    return listBotRunEvents(timeRange)
      .then(data => {
        data = data.sort((e1, e2) => {
          return e2.startedAt - e1.startedAt;
        });
        return data;
      })
      .catch(err => {
        handleError(err, alert);
        return [];
      });
  };

  const ontimeRangeUsageCsvData = async (timeRange: TimeRange): Promise<UsageCsvData[]> => {
    return listBotRunCsvData(timeRange)
      .then(data => {
        return data;
      })
      .catch(err => {
        handleError(err, alert);
        return [];
      });
  };

  const ontimeRangeUsageMetrics = async (timeRange: TimeRange): Promise<UsageLogMetrics> => {
    return listBotRunMetrics(timeRange)
      .then(data => {
        return data;
      })
      .catch(err => {
        handleError(err, alert);
        return {
          botCount: 0,
          runCount: 0,
          rowCount: 0,
        } as UsageLogMetrics;
      });
  };
  return {
    ontimeRangeUsageEvents,
    ontimeRangeUsageCsvData,
    ontimeRangeUsageMetrics,
  };
}
