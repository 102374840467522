import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import queryString from 'query-string';

// import { useCustomEventListener } from 'react-custom-events';
import {
  clearLocalStorage,
  clearSessionStorage,
  getAuthentication,
  getSession,
  // saveSession as saveSessionInStorage,
  getTabSession,
  removeAuthentication,
  saveTabSession,
} from '../services/storage';
// import { Session, TabSession } from '../types';
import { TabSession } from '../types';
// import events from '../events';
import { LANDING_URI } from '../constants';
import { switchNamespace } from '../services/session';
import { handleError } from '../services/client';
import { bootstrapTab } from '../services/login';

export function useIdleTracker(onIdle: () => void): void {
  const handleOnIdle = () => {
    onIdle();
  };
  useIdleTimer({
    timeout: 1000 * 60 * 60 * 4, // 4 * 60 min
    onIdle: handleOnIdle,
    debounce: 500,
  });
}

export function useAuth(): {
  isAuthenticated: boolean;
  isStaledSession: boolean;
  isBootstrappingTab: boolean;
} {
  const tabSession = getTabSession();
  const auth = getAuthentication();
  const alert = useAlert();
  const sesion = getSession();
  const isAuthenticated = auth?.access_token !== undefined && sesion !== undefined;
  const [isBootstrappingTab, setIsBootstrappingTab] = useState<boolean>(isAuthenticated && !Boolean(tabSession));
  useEffect(() => {
    if (isAuthenticated && !Boolean(tabSession)) {
      setIsBootstrappingTab(true);
      bootstrapTab(auth.access_token || '')
        .then(() => {
          window.location.reload();
        })
        .catch(err => {
          if (err.response?.status === 401) {
            removeAuthentication();
            clearLocalStorage();
            clearSessionStorage();
            // noinspection SillyAssignmentJS
            window.location.href = window.location.href;
          } else {
            handleError(err, alert);
          }
        });
    }
  }, [isAuthenticated, Boolean(tabSession)]);
  const isStaledSession = !isBootstrappingTab && Boolean(tabSession && tabSession?.sessionId !== sesion?.sessionId);
  return { isAuthenticated, isStaledSession, isBootstrappingTab };
}

export function useRedirectNamespace(): void {
  const intl = useIntl();
  const alert = useAlert();
  let qString = '';
  if (typeof window !== 'undefined') {
    qString = window.location.search;
  }
  const parsed = queryString.parse(qString);
  if (Boolean(parsed.rns)) {
    const tabSession = getTabSession();
    if (tabSession) {
      const targetNs = parsed.rns as string;
      const currentNs = tabSession.namespace;
      if (currentNs !== targetNs) {
        switchNamespace(targetNs)
          .then(tab => {
            void reloadTabSession(intl.locale, tab, true);
          })
          .catch(err => {
            handleError(err, alert);
          });
      }
    }
  }
}

export async function reloadTabSession(locale: string, tab: TabSession, redirectNamespace: boolean): Promise<void> {
  if (getTabSession()?.namespace !== tab.namespace) {
    saveTabSession(tab);
    let qString = '';
    if (typeof window !== 'undefined') {
      qString = window.location.search;
    }
    const parsed = queryString.parse(qString);
    if (Boolean(parsed.rns) && redirectNamespace) {
      // when rns (recipe namespace) is present, we want to keep the page after switching namespace
      // window.location.href = window.location.href.split('?')[0];
      window.location.reload();
    } else {
      window.location.href = `/${locale}${LANDING_URI}`;
    }
  }
}
