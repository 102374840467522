import axios from 'axios';
import client, { ApiPromise, followPromise } from './client';

type BaseDeepLink = {
  id: string;
  type: string;
};

export type InstallTemplateLink = {
  templateId: string;
} & BaseDeepLink;

export type ResetPasswordLink = {
  email: string;
} & BaseDeepLink;

export type DeepLink = InstallTemplateLink | ResetPasswordLink;

export type EvaluatedLink = {
  objectType: string;
  objectId: string;
};

export async function resolveDeepLink(linkId: string): Promise<DeepLink> {
  const resp = await axios.get(`/api/deeplink/${linkId}`);
  return resp.data as DeepLink;
}

export async function evaluatLink(deepLink: DeepLink): Promise<EvaluatedLink> {
  const resp = await client.post(`/protected-link/async`, deepLink);
  const promise: ApiPromise<EvaluatedLink> = resp.data;
  promise.createdTime = new Date();
  const result = await followPromise(promise, undefined);
  return result as EvaluatedLink;
}

export function toDeepLink(linkId: string): string {
  if (typeof window !== 'undefined' && linkId) {
    return `${window.location.protocol}//${window.location.host}/deeplink/${linkId}`;
  } else {
    return '';
  }
}
