import React from 'react';
import { useIntl } from 'react-intl';
import { getTabSession } from '../../services/storage';
import { LANDING_URI } from '../../constants';
import { Brand } from '../logo';
import { Breadcrumbs, css, Link as MLink, styled } from '@mui/material';
import { FlexFiller } from '@savant-components/basic';
import SessionControl from './SessionControl';
import { ThemeExtra } from '@savant-components/theme';
import { Execution, ExecutionType } from '@savant-components/catalog';

const TopControlBarStyle = styled('div')<{ theme?: ThemeExtra }>(
  ({ theme }) => css`
    height: 3rem;
    padding: 0 2.25rem;
    border-bottom: 2px solid ${theme.palette.grey[300]};
    display: flex;
    align-items: center;

    .brand {
      margin-right: 1em;
      display: flex;
      align-items: center;
      padding: 0.5em;
      border-radius: 0.5em;
      cursor: pointer;
    }

    .flow-name {
      height: 100%;
      align-items: center;
      max-width: 16vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
    }

    .flow-name-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a.disabled:hover {
      color: inherit;
      cursor: not-allowed;
    }

    .recipe-name {
      max-width: 20rem;
      font-size: 1rem;
    }
  `,
);

export interface TopControlBarProps extends Pick<Execution, 'recipeId' | 'recipeName' | 'type'> {
  executionName: string;
}

const getExecutionTypeRouteParams = ({ type }: { type?: ExecutionType }) => {
  switch (type) {
    case 'test_run':
      return '?showTestRuns=true';
    case 'run_now':
    case 'scheduled':
      return '/bot/runs';
    case undefined:
      return '';
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled type ${exhaustiveCheck}`);
    }
  }
};

export const TopControlBar = ({
  recipeId,
  recipeName,
  executionName,
  type,
}: TopControlBarProps): React.ReactElement => {
  const intl = useIntl();
  const tabSession = getTabSession();
  const applicationLabel = intl.formatMessage({ id: 'app.applications' });
  const runsLabel = intl.formatMessage({ id: 'app.runs' });
  const onClickBrand = () => {
    window.location.href = `/${intl.locale}${LANDING_URI}`;
  };

  return (
    <React.Fragment>
      <TopControlBarStyle>
        <div className="brand" onClick={onClickBrand}>
          <Brand orgId={tabSession.orgId} />
        </div>
        <Breadcrumbs aria-label="breadcrumb">
          <MLink underline="hover" color="inherit" href={`/${intl.locale}/app/analysis`}>
            {applicationLabel}
          </MLink>
          <MLink
            underline={recipeId ? 'hover' : 'none'}
            className={`flow-name-link ${recipeId ? '' : 'disabled'}`}
            color="inherit"
            href={recipeId ? `/${intl.locale}/app/flow/${recipeId}?rns=${tabSession.namespace}` : undefined}
          >
            <div className="flow-name text-ellipsis" title={recipeName}>
              {recipeName}
            </div>
          </MLink>
          <MLink
            underline={recipeId ? 'hover' : 'none'}
            className={recipeId ? '' : 'disabled'}
            color="inherit"
            href={recipeId ? `/${intl.locale}/app/flow/${recipeId}${getExecutionTypeRouteParams({ type })}` : undefined}
          >
            {runsLabel}
          </MLink>
          <div></div>
        </Breadcrumbs>
        <div className="recipe-name text-ellipsis" title={executionName}>
          {executionName}
        </div>
        <FlexFiller />
        <SessionControl />
      </TopControlBarStyle>
    </React.Fragment>
  );
};
