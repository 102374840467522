import React from 'react';
import Tabs from './Tabs';
import { useIntl } from 'react-intl';

const WorkspaceDetailLayout = ({
  module,
  setModule,
  notificationEnabled,
  children,
}: {
  module: string;
  setModule: (tgt: string) => void;
  notificationEnabled: boolean;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const intl = useIntl();
  const membersLabel = intl.formatMessage({ id: 'app.workspace.members' });
  const settingsLabel = intl.formatMessage({ id: 'app.settings' });
  const onClickNav = (tgt: string) => {
    setModule(tgt);
  };
  const tabData = [
    { label: membersLabel, value: 'members' },
    { label: settingsLabel, value: 'settings' },
  ];
  const hiddenTabs = notificationEnabled ? [] : ['settings'];

  return (
    <>
      <div className="tab-row" style={{ paddingBottom: 16 }}>
        <Tabs activeTab={module} setActiveTab={onClickNav} tabData={tabData || []} hiddenTabs={hiddenTabs} />
      </div>
      <div className="tab-content" style={{ flexGrow: 1 }}>
        {children}
      </div>
    </>
  );
};

export default WorkspaceDetailLayout;
