import { useAlert } from 'react-alert';
import { FieldMetadata, Option } from '@savant-components/basic';
import { Connector, DynamicOptionProvider, Source } from '@savant-components/catalog';
import { listSources, getSchema as getSourceSchemaViaApi, getFormOptions } from '../services/source';
import { handleError } from '../services/client';

function getMockOptions<T>(provider: DynamicOptionProvider): Promise<Option<T>[]> {
  return new Promise(resolve => {
    setTimeout(() => {
      if (provider.type === 'dataset') {
        if (provider.labelField) {
          resolve([
            { value: 'opt1' as T, label: 'Label 1' },
            { value: 'opt2' as T, label: 'Label 2' },
          ]);
        } else {
          resolve([
            { value: 'opt1' as T, label: 'Option 1' },
            { value: 'opt2' as T, label: 'Option 2' },
          ]);
        }
      }
    }, 1000);
  });
}

export function useDynamicOptionProvider(): {
  getAvailableSources: () => Promise<Source[]>;
  getSourceSchema: (sourceId: string) => Promise<FieldMetadata[]>;
  getDynamicOptions: <T>(provider: DynamicOptionProvider) => Promise<Option<T>[]>;
} {
  const alert = useAlert();

  const getAvailableSources = (): Promise<Source[]> => {
    return listSources()
      .then(sources => {
        return sources
          .map(source => {
            return {
              id: source.id,
              name: source.name,
              connector: source.mockConnector || source.connector || (source.type as Connector),
            };
          })
          .sort((s1, s2) => {
            const type1: string = (s1.connector || '').toLowerCase();
            const type2: string = (s2.connector || '').toLowerCase();
            if (type1 === type2) {
              return s1.name.localeCompare(s2.name);
            } else {
              return type1.localeCompare(type2);
            }
          });
      })
      .catch(err => {
        handleError(err, alert);
        return [];
      });
  };

  const getSourceSchema = (sourceId: string): Promise<FieldMetadata[]> => {
    return getSourceSchemaViaApi(sourceId).catch(err => {
      handleError(err, alert);
      return [];
    });
  };

  function getDynamicOptions<T>(provider: DynamicOptionProvider): Promise<Option<T>[]> {
    if (provider.type === 'dataset') {
      return getFormOptions(provider.sourceId, {
        valueField: provider.valueField,
        labelField: provider.labelField,
        dependencies: provider.dependencies,
      })
        .then(res => {
          return res.options as unknown as Option<T>[];
        })
        .catch(err => {
          handleError(err, alert);
          return [];
        });
    } else {
      return getMockOptions(provider);
    }
  }

  return {
    getAvailableSources,
    getSourceSchema,
    getDynamicOptions,
  };
}
