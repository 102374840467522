import { AutomationContext, Builder, BuilderContext } from '@savant-components/builder';
import React from 'react';
import 'reactflow/dist/style.css';
import { getAutomationCtx, getBuilderContext } from '../../contexts';
import APIUploader from '../../services/uploader';
import { useParams } from 'react-router-dom';
import { useFlowBuilder } from '../../hooks/flowBuilder';
import { SavantLoader, FlowBuilderContainer } from './FlowBuilderContainer';
import { ErrorBoundaryContext } from '@savant-components/basic';

const FlowBuilder = (): React.ReactElement | null => {
  const { recipeId } = useParams();
  const hook = useFlowBuilder(recipeId || '');
  return hook.state.isLoading || !hook.state.flow ? (
    <SavantLoader />
  ) : (
    <ErrorBoundaryContext.Provider
      value={{
        onError: (error: Error, info: React.ErrorInfo) => {
          console.error('ErrorBoundary caught an error:', error, info);
        },
      }}
    >
      <FlowBuilderContainer showAutomatePane={false}>
        <BuilderContext.Provider value={getBuilderContext(hook.intl, hook.engine, APIUploader, [])}>
          <AutomationContext.Provider value={getAutomationCtx(hook.intl)}>
            <Builder
              flow={hook.state.flow}
              engine={hook.engine}
              featureFlags={hook.featureFlags}
              handlers={hook.builderHandlers}
              isSaving={hook.state.isSavingRecipe}
            />
          </AutomationContext.Provider>
        </BuilderContext.Provider>
      </FlowBuilderContainer>
    </ErrorBoundaryContext.Provider>
  );
};

export default FlowBuilder;
