import React from 'react';
import { useIntl } from 'react-intl';

import { ThemeExtra } from '@savant-components/theme';
import { FlexFiller } from '@savant-components/basic';
import { Brand } from '../logo';
import { LANDING_URI } from '../../constants';
import {
  getTabSession,
  removeAddConnectionWizardContext,
  removeAddSourceWizardContext,
  removeFlowBuilderContext,
} from '../../services/storage';

import SessionControl from './SessionControl';

import { useNavigate } from 'react-router-dom';
import { Feature } from '../../services/feature';
import { useFeatureService } from '../../hooks/feature';
import RestrictedPage from '../RestricedPage';
import { css, styled } from '@mui/material';
import { Global } from '@emotion/react';

export const TOP_NAV_HEIGHT = '4rem';

interface NavLinkProps {
  title: string;
  module: string;
  active: boolean;
  onClick?: () => void;
  testId?: string;
}

const NavLink = ({ title, active, module, onClick, testId }: NavLinkProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  if (onClick === undefined) {
    onClick = () => {
      removeAddSourceWizardContext();
      removeAddConnectionWizardContext();
      removeFlowBuilderContext();
      navigate(`/${intl.locale}/app/${module}`);
    };
  }
  let className = 'text-t7 link';
  if (active) {
    className = 'text-t5 link-active';
  }
  return (
    <Container onClick={onClick} className={className} data-testid={testId}>
      {title}
    </Container>
  );
};

const Container = styled('div')<{ theme?: ThemeExtra }>(
  ({ theme }) => css`
    margin: 0 0.5em;
    padding: 0.5em;
    //border-radius: 0.5em;
    // font-size: 1rem;
    cursor: pointer;

    :hover {
      background: ${theme.colors.b5};
    }
  `,
);
const Style = styled('div')<{ theme?: ThemeExtra }>(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 600px;
    .top-nav {
      background: ${theme.colors.background};
      border-bottom: 1px solid ${theme.colors.o1};
      height: ${TOP_NAV_HEIGHT};
      padding: 0 2.25rem;
      display: flex;
      align-items: center;

      .brand {
        margin-right: 1em;
        display: flex;
        align-items: center;
        padding: 0;
        border-radius: 0.5em;
        cursor: pointer;
      }
    }

    .app {
      position: absolute;
      left: 0;
      right: 0;
      insert: 4rem 0 0;
      top: ${TOP_NAV_HEIGHT};
      bottom: 0;
      height: calc(100% - ${TOP_NAV_HEIGHT});
      overflow-x: hidden;
    }

    .link {
      font-weight: 400;
    }

    .link-active {
      font-weight: 700;
      color: ${theme.colors.f1};
    }
  `,
);

const AppLayout = ({
  children,
  module,
  hideNavigation,
  pagePermission,
}: {
  children: JSX.Element | JSX.Element[];
  module: string;
  hideNavigation?: boolean;
  pagePermission: Feature;
}): React.ReactElement => {
  const intl = useIntl();
  const tabSession = getTabSession();

  const applicationLabel = intl.formatMessage({ id: 'app.applications' });
  const runsLabel = intl.formatMessage({ id: 'app.runs' });
  const dataMgmtLabel = intl.formatMessage({ id: 'app.data' });
  const adminLabel = intl.formatMessage({ id: 'app.admin' });
  const communityLabel = intl.formatMessage({ id: 'app.community' });

  const { isFeatureEnabled } = useFeatureService();
  const isPageAccessible = isFeatureEnabled(pagePermission);

  const onClickBrand = () => {
    window.location.href = `/${intl.locale}${LANDING_URI}`;
  };

  return (
    <Style>
      <Global
        styles={css`
          html {
            overflow-x: hidden;
          }
        `}
      />
      <div className="top-nav">
        <div className="brand" onClick={onClickBrand}>
          <Brand orgId={tabSession?.orgId} />
        </div>
        {!hideNavigation && (
          <React.Fragment>
            <NavLink
              title={applicationLabel}
              active={module === 'analysis' || module === 'template'}
              module="analysis"
              testId={'analyses-div-test-id'}
            />
            <NavLink title={runsLabel} active={module === 'run'} module="run" testId={'run-test-id'} />
            <NavLink
              title={dataMgmtLabel}
              active={module === 'source' || module === 'connection'}
              module="source"
              testId={'data-test-id'}
            />
            <NavLink
              title={communityLabel}
              active={module === 'community'}
              module="community"
              testId={'community-tab-test-id'}
            />
            <NavLink title={adminLabel} active={module === 'admin'} module="admin" testId={'admin-test-id'} />
          </React.Fragment>
        )}
        <FlexFiller />
        {typeof window !== 'undefined' && <SessionControl />}
      </div>
      <div className="app">{isPageAccessible ? children : <RestrictedPage />}</div>
    </Style>
  );
};

export default AppLayout;
