import { ConnectionInfo, DataFrame, FieldMetadata, Option, PhysicalDataType } from '@savant-components/basic';
import { Source, Connector, ObjectSchema, ParameterDependency } from '@savant-components/catalog';

import client, { ApiPromise, followPromise } from './client';

export interface CreateSourceRequest {
  name: string;
  description: string;
  connectionId?: string;
  id?: string;
  sourceId?: string;
  config: {
    type: 'connection' | 'filesystem' | 'file' | 'query';
    connector: Connector;
  } & {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    [key: string]: any;
    /* eslint-enable  @typescript-eslint/no-explicit-any */
  };
  profile: {
    schema: FieldMetadata[];
    sample?: PhysicalDataType[][];
    rows: number;
    columns: number;
  };
}

interface GetFormOptionsRequest {
  valueField: string;
  labelField?: string;
  dependencies?: ParameterDependency[];
}

export async function getSource(id: string): Promise<Source> {
  return client.get(`/sources/${id}`).then(res => {
    return res.data;
  });
}

export async function getSourceInfo(id: string): Promise<ConnectionInfo> {
  return client.get(`/sources/${id}/info`).then(resp => {
    return resp.data;
  });
}

export async function createSource(req: CreateSourceRequest): Promise<Source> {
  return client.post(`/sources/create-async`, req).then(async resp => {
    // String id;
    // String name;
    // String namespace;
    // Long createdTime;
    // String createdBy;
    // SourceConfig config;
    // List<FieldMetadata> schema;
    // Long createdTime;
    // String createdBy;
    // DataProfile profile; // exclude schema and sample
    // @Nullable Long rows;
    // @Nullable Integer columns;
    const promise: ApiPromise<Source> = resp.data;
    promise.createdTime = new Date();
    const result = await followPromise(promise, undefined);
    return {
      ...result,
      type: result.config?.connector,
      connector: result.config?.connector,
      // createdTime: new Date(resp.data.createdTime),
      //connectionId?: string;
      //connectionName?: string;
      profile: undefined,
    } as unknown as Source;
  });
}

export async function listSources(connector?: string): Promise<Source[]> {
  let url = '/sources';
  if (connector) {
    url += `?connector=${connector}`;
  }
  return client.get(url).then(resp => {
    // String id;
    // String name;
    // Connector connector;
    // Long createdTime;
    // String createdBy;
    // Long rows;
    // Integer columns;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return resp.data.slice.map((summary: { connector: any }) => {
      return {
        ...summary,
        type: summary.connector,
        // createdTime: new Date(summary.createdTime),
      };
    });
  });
}

export async function deleteSource(id: string): Promise<void> {
  return client.delete(`/sources/${id}`).then(() => {
    return;
  });
}

export async function updateSourceName(id: string, newName: string): Promise<void> {
  const payload = {
    newName,
  };
  await client.put(`/sources/${id}/name`, payload);
}

export async function getSample(id: string): Promise<DataFrame> {
  return client.get(`/sources/${id}/sample`).then(res => {
    return res.data;
  });
}

export async function refreshSample(id: string): Promise<void> {
  return client.post(`/sources/${id}/sample`).then(() => {
    return;
  });
}

export async function getSchema(id: string): Promise<FieldMetadata[]> {
  return client.get(`/sources/${id}/schema`).then(res => {
    return res.data;
  });
}

export async function getSourceObjectSchema(id: string): Promise<ObjectSchema> {
  return client.get(`/sources/${id}/schema/object`).then(res => {
    return res.data;
  });
}

export async function evictSourceCache(id: string): Promise<void> {
  return client.delete(`/sources/${id}/cache`).then(() => {
    return;
  });
}

export async function getFormOptions<T>(id: string, req: GetFormOptionsRequest): Promise<{ options: Option<T>[] }> {
  return client.post(`/sources/${id}/form-options`, req).then(res => {
    return { ...res.data };
  });
}
