/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  AddConnection,
  AggregatedAddConnectionState as AggregatedState,
  AuthenticationContext,
} from '@savant-components/catalog';

import { Loader, LoaderModal } from '@savant-components/basic';
import { useAddConnection } from '../../hooks/connection';
import {
  removeRedirectState,
  getAddConnectionWizardContext,
  getAddSourceWizardContext,
  removeAddConnectionWizardContext,
  removeAddSourceWizardContext,
  saveNewDestNode,
} from '../../services/storage';
import { getAuthenticationContext } from '../../contexts';
import { useFeatureService } from '../../hooks/feature';

const AddConnectionView = (): JSX.Element => {
  const intl = useIntl();
  const locale = intl.locale;
  const {
    isLoading,
    isRedirecting,
    initialType,
    restoreSteps,
    saveAndAuthenticate,
    confirmAddConnection,
    authenticateBasicAuth,
    authenticateSshAuth,
    authenticateApiKeyAuth,
    authenticateDbAuth,
    authenticateAwsAuth,
    authenticateGoogleSAAuth,
    authenticateCustomOAuth,
    authenticateGenericApiAuth,
  } = useAddConnection();
  const authCtx = getAuthenticationContext(intl);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const onCancelAdd = () => {
    let cancelUrl = `/${locale}/app/connection`;
    const ctx = getAddConnectionWizardContext();
    if (ctx === 'connections') {
      cancelUrl = `/${locale}/app/connection`;
    } else if (ctx === 'add-source') {
      const srcCtx = getAddSourceWizardContext();
      if (srcCtx !== undefined && srcCtx.startsWith('edit-recipe')) {
        cancelUrl = srcCtx.replace('edit-recipe', `/${locale}/app/flow`);
      } else {
        cancelUrl = `/${locale}/app/source`;
      }
      removeAddSourceWizardContext();
    } else if (ctx !== undefined && ctx.startsWith('edit-recipe')) {
      cancelUrl = ctx.replace('edit-recipe', `/${locale}/app/flow`);
    }
    removeAddConnectionWizardContext();
    window.location.href = cancelUrl;
  };
  const onConfirmAdd = (state: AggregatedState) => {
    setIsCreating(true);
    confirmAddConnection(state).then(connection => {
      removeRedirectState();
      const ctx = getAddConnectionWizardContext();
      if (ctx === 'add-source') {
        removeAddConnectionWizardContext();
        window.location.replace(`/${locale}/app/source/add?connectionId=${connection.id}`);
      } else if (ctx !== undefined) {
        let returnUrl = `/${locale}/app/connection`;
        if (ctx.startsWith('edit-recipe')) {
          saveNewDestNode({
            ...connection,
            createdTime: undefined,
          });
          returnUrl = ctx.replace('edit-recipe', `/${locale}/app/flow`);
        }
        removeAddConnectionWizardContext();
        window.location.href = returnUrl;
      }
      setIsCreating(false);
    });
  };
  const loaderMessage = intl.formatMessage({ id: 'app.connection.creatingConnection' });
  const isRedirectingMessage = intl.formatMessage({ id: 'app.connection.isRedirecting' });

  const experimentalConnectors: string[] = [];
  const { isFeatureEnabled } = useFeatureService();

  if (isFeatureEnabled('CONNECTOR_GOOGLECAMPAIGNMANAGER')) {
    experimentalConnectors.push('googlecampaignmanager');
  }

  if (isFeatureEnabled('CONNECTOR_AZURE_SYNAPSE')) {
    experimentalConnectors.push('azuresynapse');
  }

  if (isFeatureEnabled('CONNECTOR_TWITTER')) {
    experimentalConnectors.push('twitter');
  }

  const style = css`
    height: calc(100vh - 4rem);
  `;
  return (
    <React.Fragment>
      <div css={style}>
        {isLoading ? (
          <Loader size={200} />
        ) : (
          <AuthenticationContext.Provider value={authCtx}>
            <AddConnection
              onCancel={onCancelAdd}
              onConfirm={onConfirmAdd}
              initialType={initialType}
              restoreSteps={restoreSteps}
              experimentalConnectors={experimentalConnectors}
              onSaveAndAuthenticate={saveAndAuthenticate}
              authenticateBasicAuth={authenticateBasicAuth}
              authenticateSshAuth={authenticateSshAuth}
              authenticateDbAuth={authenticateDbAuth}
              authenticateApiKeyAuth={authenticateApiKeyAuth}
              authenticateAwsAuth={authenticateAwsAuth}
              authenticateCustomOAuth={authenticateCustomOAuth}
              authenticateGenericApi={authenticateGenericApiAuth}
              authenticateGoogleSA={authenticateGoogleSAAuth}
              authenticateCustomDbAuth={authenticateDbAuth}
            />
          </AuthenticationContext.Provider>
        )}
      </div>
      <LoaderModal isOpen={isCreating} message={loaderMessage} />
      <LoaderModal isOpen={isRedirecting} message={isRedirectingMessage} />
    </React.Fragment>
  );
};

export default AddConnectionView;
