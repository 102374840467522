import { Feature, isFeatureEnabled as isFeatureEnabledBySvc } from '../services/feature';
import { getTabSession } from '../services/storage';

export function useFeatureService(): {
  isFeatureEnabled: (feature: Feature) => boolean;
} {
  const isFeatureEnabled = (feature: Feature) => {
    const session = getTabSession();
    if (session) {
      return isFeatureEnabledBySvc(session, feature);
    } else {
      return false;
    }
  };
  return {
    isFeatureEnabled,
  };
}
