import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import { Loader } from '@savant-components/basic';
import { Builder, BuilderContext, BuilderContextProps } from '@savant-components/builder';
import { useTheme } from '@savant-components/theme';
import ApiEngine from '../../services/engine';
import APIUploader from '../../services/uploader';
import uploader from '../../services/uploader';
import { RootStyle, ViewerStyle } from './FlowViewerStyles';
import { TopControlBar } from './TopControlBar';
import { useFeatureFlags } from './useFeatureFlags';
import { useExecutionGraphQuery, useExecutionQuery } from '../../hooks/execution';
import { useNavigate, useParams } from 'react-router-dom';
import { getBuilderContext } from '../../contexts';
import { listAIProviderConnections } from '../../services/connection';

const FlowViewer = (): React.ReactElement | null => {
  const { executionId } = useParams();
  if (!executionId) return null;

  const intl = useIntl();
  const alert = useAlert();
  const theme = useTheme();
  const featureFlags = useFeatureFlags();
  const executionGraphQuery = useExecutionGraphQuery(executionId);

  const executionQuery = useExecutionQuery(executionId);

  const navigate = useNavigate();
  const engine = useMemo(
    () => new ApiEngine(uploader, intl, alert, executionQuery?.data?.recipeId || '', false, navigate),
    [alert, executionQuery?.data?.recipeId],
  );
  const ctx: BuilderContextProps = getBuilderContext(intl, engine, APIUploader, executionQuery?.data?.tasks || []);
  const isLoaded =
    executionQuery?.data && executionGraphQuery.data && executionQuery.isFetched && executionGraphQuery.isFetched;

  return (
    <RootStyle>
      {executionQuery.isLoading ||
        (executionGraphQuery.isLoading && <Loader size={200} color={theme.colors.secondary} />)}

      {isLoaded && (
        <TopControlBar
          executionName={executionQuery?.data?.name}
          recipeId={executionQuery?.data?.recipeId}
          recipeName={executionGraphQuery?.data?.name}
          type={executionQuery?.data?.type}
        />
      )}

      {isLoaded && (
        <ViewerStyle>
          <BuilderContext.Provider value={ctx}>
            <Builder
              executionId={executionId}
              engine={engine}
              handlers={{ listAIProviderConnections }}
              flow={{
                ...executionQuery?.data,
                nodes: executionGraphQuery?.data?.nodes,
              }}
              featureFlags={featureFlags}
              isSaving={false}
            />
          </BuilderContext.Provider>
        </ViewerStyle>
      )}
    </RootStyle>
  );
};

export default FlowViewer;
