import { Organization } from '@savant-components/catalog';
import client from './client';
import { UpdateUserRequest } from './user';

export async function updateUserRole(orgId: string, updateRequest: UpdateUserRequest): Promise<void> {
  await client.post(`/organizations/${orgId}/updateuser`, updateRequest);
}

export async function updateName(newName: string): Promise<string> {
  return client.put(`/organizations`, { newName }).then(resp => resp.data.name);
}

export async function updateLogo(fileId: string): Promise<Organization> {
  return client.put(`/organizations`, { newLogoFileId: fileId }).then(resp => resp.data);
}
