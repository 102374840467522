import { getSession, saveSession } from '../../services/storage';
import { useAlert } from 'react-alert';
import { handleError } from '../../services/client';
import { useState } from 'react';
import { User } from '../../types';
import { updateUser } from '../../services/user';

export function useUserProfile(): {
  user: User;
  updateFirstName: (firstName: string) => Promise<string>;
  updateLastName: (lastName: string) => Promise<string>;
  updateUserLogo: (fileId: string) => Promise<string>;
} {
  const session = getSession();
  const alert = useAlert();
  const [user, setUser] = useState<User>(session?.user as User);
  const updateFirstName = async (firstName: string): Promise<string> => {
    return updateUser({ newFirstName: firstName })
      .then(user => {
        setUser(user);
        saveSession({
          ...session,
          user: user,
        });
        return user.firstName as string;
      })
      .catch(err => {
        handleError(err, alert);
        return session.user?.firstName as unknown as string;
      });
  };

  const updateLastName = async (lastName: string): Promise<string> => {
    return updateUser({ newLastName: lastName })
      .then(user => {
        setUser(user);
        saveSession({
          ...session,
          user: user,
        });
        return user.lastName as string;
      })
      .catch(err => {
        handleError(err, alert);
        return session.user?.lastName as unknown as string;
      });
  };

  const updateUserLogo = async (fileId: string): Promise<string> => {
    return updateUser({ newAvatarFileId: fileId })
      .then(user => {
        setUser(user);
        saveSession({
          ...session,
          user: user,
        });
        return user.avatarUrl as string;
      })
      .catch(err => {
        handleError(err, alert);
        return session.user?.avatarUrl as unknown as string;
      });
  };

  return {
    user,
    updateFirstName,
    updateLastName,
    updateUserLogo,
  };
}
