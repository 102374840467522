/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuditReportPayload, Execution, UpdateExecutionMetadataRequest } from '@savant-components/catalog';
import { SubmitExecutionReq } from '@savant-components/builder';

import { Flow } from '../types';

import client, { followPromise, handleError } from './client';
import { DataFrame } from '@savant-components/basic';
import { AlertContainer } from 'react-alert';
import { emitCustomEvent } from 'react-custom-events';

export const getExecution = async (executionId: string): Promise<Execution> => {
  return client.get(`/executions/${executionId}`).then(resp => {
    return resp.data.execution;
  });
};

export const getExecutionGraph = async (executionId: string): Promise<Flow> => {
  return client.get(`/executions/${executionId}/graph`).then(resp => {
    return resp.data;
  });
};

export const getLatestSample = async (recipeId: string, nodeId: string, outlet: string): Promise<DataFrame> => {
  return client.get(`/recipes/${recipeId}/nodes/${nodeId}/latest-sample?outlet=${outlet}`).then(res => {
    const frame = {
      schema: res.data.schema.map((field: { dataType: any }) => {
        if (field.dataType) {
          return field;
        } else {
          return {
            ...field,
            dataType: 'string',
          };
        }
      }),
      data: res.data.data,
    };
    return frame;
  });
};

export const getExecutionsInRecipe = async (
  recipeId: string,
  types?: ('test_run' | 'run_now' | 'scheduled')[],
): Promise<Execution[]> => {
  let url = `/recipes/${recipeId}/executions`;
  if ((types || []).length > 0) {
    url += `?types=${types?.join(',')}`;
  }
  return client.get(url).then(resp => {
    return resp.data.executions.map((exec: { recipeId: any }) => {
      return {
        ...exec,
        flowId: exec.recipeId,
      };
    });
  });
};

export const getExecutions = async (): Promise<Execution[]> => {
  return client.get(`/executions`).then(resp => {
    return resp.data.executions.map((exec: { recipeId: any }) => {
      return {
        ...exec,
        flowId: exec.recipeId,
        recipeId: exec.recipeId,
      };
    });
  });
};

export async function submitExecution(req: SubmitExecutionReq, type: 'test_run' | 'run_now'): Promise<Execution> {
  const nodes = req.nodes.map(node => {
    return {
      ...node,
      config: {
        ...node.config,
        data: undefined,
      },
    };
  });
  const req2 = {
    ...req,
    nodes: nodes,
    executionType: type,
  };
  return client.post(`/executions`, req2).then(res => {
    return res.data.execution;
  });
}

export async function cancelExecution(executionId: string): Promise<void> {
  return client.put(`/executions/${executionId}/cancel`, undefined);
}

export async function downloadReport(executions: AuditReportPayload): Promise<string> {
  return client.post(`/executions/audit-report`, executions).then(resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as string;
    });
  });
}

export enum RefreshEvent {
  'refreshAllEvent' = 'app/runs/refreshAll',
  'refreshSingleEvent' = 'app/runs/refreshSingle',
}

export const downloadReportExecution =
  (alert: AlertContainer, event: string) =>
  (executions: AuditReportPayload): Promise<void> => {
    let dataEvent = {};
    return downloadReport(executions)
      .then((signedUrl: string) => {
        window.location.href = signedUrl;
        if (event == RefreshEvent.refreshSingleEvent) {
          dataEvent = { executionId: executions.execution1 };
        }
        return emitCustomEvent(event, dataEvent);
      })
      .catch(err => handleError(err, alert));
  };

export const getExecutionsFromExecution =
  (alert: AlertContainer) =>
  (execution: Execution): Promise<Execution[]> => {
    const url = `/recipes/${execution.recipeId}/executions?types=run_now`;
    const executionsFilter = (e: Execution) => {
      return (
        e.id !== execution.id &&
        (e.phase.toLowerCase() === 'succeeded' || e.phase.toLowerCase() === 'failed') &&
        e.startedAt !== undefined &&
        execution.startedAt !== undefined &&
        e.startedAt <= execution.startedAt
      );
    };
    return client
      .get(url)
      .then(resp => {
        return resp.data.executions.filter((exec: Execution) => {
          return executionsFilter(exec);
        });
      })
      .catch(err => {
        handleError(err, alert);
      });
  };

export async function updateExecutionMetadata(req: UpdateExecutionMetadataRequest): Promise<Execution> {
  return client.put(`/executions/${req.id}/metadata`, {
    name: req.name,
  });
}
