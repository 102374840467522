import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Connection, Connections } from '@savant-components/catalog';

import { Loader } from '@savant-components/basic';
import { useConnections } from '../../hooks/connection';
import { saveAddConnectionWizardContext } from '../../services/storage';

import CatalogLayout from './CatalogLayout';
import { getConnectorsArray, getOwnerArray } from '../../utils/utils';

const ConnectionsView = ({ onSelect }: { onSelect?: (item: Connection) => void }): React.ReactElement => {
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');

  const setFilterValues = (connections: Connection[]) => {
    const typeFilter = filterOptions.find(f => f.label === 'Connector(s)');
    if (typeFilter) {
      typeFilter.options = getConnectorsArray(connections || []);
    }
    const phaseFilter = filterOptions.find(f => f.label === 'Owner');
    if (phaseFilter) {
      phaseFilter.options = getOwnerArray(connections || []);
    }
    setFilterOptions([...filterOptions]);
  };

  const { isLoading, connections, onDelete, getConnectionInfo, getDependingRecipes } = useConnections({
    connecionsUrl: `/${intl.locale}/app/connection`,
    setFilterValues,
  });

  const [filterOptions, setFilterOptions] = useState([
    {
      label: 'Last Modified',
      type: 'date',
      placeHolder: 'Select date',
      value: [null, null],
    },
    {
      label: 'Connector(s)',
      type: 'select',
      placeHolder: 'All systems',
      value: [],
      options: getConnectorsArray([...connections]),
    },
    {
      label: 'Owner',
      type: 'select',
      placeHolder: 'All systems',
      value: [],
      options: getOwnerArray([...connections]),
    },
  ]);

  const onClickAdd = () => {
    // setShowAddConnection(true);
    saveAddConnectionWizardContext('connections');
    const addConnUrl = `/${intl.locale}/app/connection/add`;
    window.location.href = addConnUrl;
    // window.location.href = addConnUrl;
  };
  const onEdit = (conn: Connection) => {
    saveAddConnectionWizardContext('connections');
    window.location.href = `/${intl.locale}/app/connection/${conn.id}/edit`;
  };
  return (
    <CatalogLayout module="connection">
      {isLoading ? (
        <Loader size={200} />
      ) : (
        <Connections
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          connections={connections}
          getConnectionLink={undefined as unknown as (conn: Connection) => string}
          onClickAdd={onClickAdd}
          onSelect={onSelect as (item: Connection) => void}
          onDelete={onDelete}
          onEdit={onEdit}
          selected={undefined}
          getConnectionInfo={getConnectionInfo}
          getDependingRecipes={getDependingRecipes}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      )}
    </CatalogLayout>
  );
};

export default ConnectionsView;
