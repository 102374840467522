/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import queryString from 'query-string';

import SignUpForm from '../components/SignUpForm';

import { handleError } from '../services/client';
import { getUserRegistration, registerUser } from '../services/user';
import { SignInContext } from '../contexts';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { Loader, Spacer } from '@savant-components/basic';
import { saveTabSession } from '../services/storage';
import { bootstrapTab } from '../services/login';
import { LANDING_URI } from '../constants';

const SignUpPage = (): React.ReactElement => {
  const alert = useAlert();
  const intl = useIntl();
  const locale = intl.locale;
  const { orientation, breakpoint } = useWindowDimensions();
  const [toLogin, setToLogin] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (toLogin) {
      setToLogin(false);
      if (typeof window !== 'undefined') {
        window.location.href = `/${locale}/login`;
      }
    }
  }, [toLogin]);

  useEffect(() => {
    if (!toLogin && typeof window !== 'undefined') {
      const parsed = queryString.parse(window.location.search);
      const state = parsed.state as string;
      if (state) {
        setIsLoading(true);
        getUserRegistration(state)
          .then(userReg => {
            if (userReg) {
              return registerUser({ ...userReg, state, username: userReg.email }).then(({ auth, sessionId }) => {
                bootstrapTab(auth?.access_token ?? '', sessionId).then(tab => {
                  saveTabSession(tab);
                  window.location.replace(LANDING_URI);
                });
              });
            } else {
              window.location.replace(`/${locale}/404`);
            }
          })
          .catch(err => {
            handleError(err, alert);
            setIsLoading(false);
          });
      }
    }
  }, []);

  const smPortraitStyle = css`
    overflow: auto;
    #login-form {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
    background-image: none;
  `;

  const landscape = css`
    #two-panels {
      position: absolute;
      top: 50%;
      left: 50%;
      gap: ${breakpoint === 'md' ? '50px' : '120px'};
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      row-gap: 100px;
    }
    #marketing {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > img {
        width: 50vw;
        min-width: 225px;
        max-width: 450px;
      }
      .marketing-title {
        margin-bottom: 2rem;
        min-width: max-content;
        font-size: 1.6rem;
        font-weight: normal;
      }
      .marketing-text {
        margin-bottom: 2rem;
        font-family: Inter, Helvetica, Arial, sans-serif;
        font-size: 0.875rem;
        line-height: 1.375rem;
        font-weight: normal;
        & > ul {
          margin-left: 15px;
          & > li {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    #login-form {
      z-index: 10;
      box-shadow: 0 0 16px #d4d0ff;
      border-radius: 16px;
    }

    #marketing-compliants {
      margin-top: -0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  `;

  const portrait = css`
    #login-form {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  `;

  const style = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #f9f9ff;
    background-image: url('https://assets.savantlabs.io/brand/icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    ${breakpoint !== 'sm' ? (orientation === 'landscape' ? landscape : portrait) : smPortraitStyle}
  `;

  const isEmbedded = useMemo(() => {
    const url = new URLSearchParams(window.location.search);
    return url.get('embed') === 'true';
  }, [window.location.search]);

  if (isEmbedded) {
    return <MainContent />;
  }
  return (
    <div css={style}>
      {isLoading ? (
        <Loader size={100} />
      ) : (
        <React.Fragment>
          <div id="two-panels">
            {orientation === 'landscape' && breakpoint !== 'sm' && (
              <div id="marketing">
                <h1 className="marketing-title">Start Your 14-Day Free Trial</h1>
                <div className="marketing-text">
                  <ul>
                    <li>One-click access to 200+ sources, apps and Excel</li>
                    <li>Drag-and-drop analytics workflows in minutes</li>
                    <li>Pre-built solution templates to get started</li>
                    <li>Generative AI to speed data prep & analytics</li>
                    <li>Insights to 100+ apps and dashboards</li>
                  </ul>
                </div>
                <div className="marketing-text">
                  <div id="marketing-compliants">
                    <img src="https://assets.savantlabs.io/img/soc2.png" width="60" />
                    <Spacer direction="horizontal" size="16px" />
                    <img src="https://assets.savantlabs.io/img/hippa.png" width="60" />
                  </div>
                </div>
              </div>
            )}
            <MainContent />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const MainContent = () => {
  const { breakpoint, orientation } = useWindowDimensions();
  return (
    <div id="login-form">
      <SignInContext.Provider value={{ orientation, breakpoint }}>
        <SignUpForm />
      </SignInContext.Provider>
    </div>
  );
};

export default SignUpPage;
