/* eslint-disable @typescript-eslint/no-explicit-any */
import { NotificationSetting, WorkspaceSetting } from '@savant-components/basic';
import client from './client';
import { Connection } from '@savant-components/catalog';
import { SlackChannel } from '@savant-components/builder';

export const saveNotificationSetting = async (
  recipients: string[],
  type: string,
  option: string,
  orgId?: string,
  workspaceId?: string,
  connectionId?: string,
  channelId?: string,
): Promise<NotificationSetting> => {
  const payload =
    type === 'organization'
      ? {
          organizationSetting: {
            orgId,
            option,
            emailInfo: {
              recipients,
            },
            slackInfo: {
              connectionId,
              channelId,
            },
          },
        }
      : {
          workspaceSetting: {
            workspaceId,
            option,
            emailInfo: {
              recipients,
            },
            slackInfo: {
              connectionId,
              channelId,
            },
          },
        };
  return client.post(`/notifications/${type}`, payload).then(resp => {
    return resp.data;
  });
};

export const updateWorkspaceSetting = async (
  executionExpirationTime: string | undefined,
  workspaceId: string,
): Promise<WorkspaceSetting> => {
  const payload = {
    executionExpirationTime,
  };

  return client.put(`/settings/workspace/${workspaceId}`, payload).then(resp => resp.data);
};

export const updateNotificationSetting = async (
  recipients: string[],
  option: string,
  type: string,
  orgId?: string,
  workspaceId?: string,
  slackConnectionId?: string,
  slackChannelId?: string,
  teamsConnectionId?: string,
  teamsChannelId?: string,
): Promise<NotificationSetting> => {
  const slackInfo = {
    connectionId: slackConnectionId,
    channelId: slackChannelId,
  };
  const teamsInfo = {
    connectionId: teamsConnectionId,
    channelId: teamsChannelId,
  };
  const payload = {
    recipients,
    option,
    slackInfo,
    teamsInfo,
  };
  const id = type === 'organization' ? orgId : workspaceId;
  return client.put(`/notifications/${type}/${id}`, payload).then(resp => {
    return resp.data;
  });
};

export const getNotificationSetting = async (
  type: string,
  orgId?: string,
  workspaceId?: string,
): Promise<NotificationSetting> => {
  const id = type === 'organization' ? orgId : workspaceId;
  return client.get(`/notifications/${type}/${id}`).then(resp => {
    return resp.data;
  });
};

export const getWorkspaceSetting = async (workspaceId: string): Promise<WorkspaceSetting> =>
  client.get(`/settings/workspace/${workspaceId}`).then(resp => resp.data);

export const listOrgSlackConnections = async (): Promise<Connection[]> => {
  return client.get(`/connections/org?connector=slack`).then(resp => {
    return resp.data.slice.map((summary: { connector: any }) => {
      return {
        ...summary,
        type: summary.connector,
      };
    });
  });
};

export const listOrgTeamsConnections = async (): Promise<Connection[]> => {
  return client.get(`/connections/org?connector=teams`).then(resp => {
    return resp.data.slice.map((summary: { connector: any }) => {
      return {
        ...summary,
        type: summary.connector,
      };
    });
  });
};

export async function listOrgSlackChannels(connectionId: string): Promise<SlackChannel[]> {
  return client.get(`/connections/${connectionId}/org/slack/channels`).then(resp => {
    return resp.data;
  });
}

export async function describeOrgSlackChannel(connectionId: string, channelId: string): Promise<SlackChannel> {
  return client.get(`/connections/${connectionId}/org/slack/channels/${channelId}`).then(resp => {
    return resp.data;
  });
}

export const listWorkspaceSlackConnections = async (workspaceId: string): Promise<Connection[]> => {
  return client.get(`/connections/workspace/${workspaceId}?connector=slack`).then(resp => {
    return resp.data.slice.map((summary: { connector: any }) => {
      return {
        ...summary,
        type: summary.connector,
      };
    });
  });
};

export const listWorkspaceTeamsConnections = async (workspaceId: string): Promise<Connection[]> => {
  return client.get(`/connections/workspace/${workspaceId}?connector=teams`).then(resp => {
    return resp.data.slice.map((summary: { connector: any }) => {
      return {
        ...summary,
        type: summary.connector,
      };
    });
  });
};

export async function listWorkspaceSlackChannels(connectionId: string, workspaceId: string): Promise<SlackChannel[]> {
  return client.get(`/connections/${connectionId}/workspace/${workspaceId}/slack/channels`).then(resp => {
    return resp.data;
  });
}

export async function describeWorkspaceSlackChannel(
  connectionId: string,
  channelId: string,
  workspaceId: string,
): Promise<SlackChannel> {
  return client.get(`/connections/${connectionId}/workspace/${workspaceId}/slack/channels/${channelId}`).then(resp => {
    return resp.data;
  });
}
