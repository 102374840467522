/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useTheme } from '@savant-components/theme';
import React from 'react';
import { Avatar } from '@savant-components/basic';
import { useIntl } from 'react-intl';

import BackgroundIcon from '../images/home_icon_bg.svg';

const RestrictedPage = (): React.ReactElement => {
  const theme = useTheme();
  const intl = useIntl();

  const restrictedAreaLabel = intl.formatMessage({ id: 'restrictedPage.restrictedAreaLabel' });
  const subTitle = intl.formatMessage({ id: 'restrictedPage.subtitle' });
  const infoLine1 = intl.formatMessage({ id: 'restrictedPage.infoLine1' });
  const infoLine2 = intl.formatMessage({ id: 'restrictedPage.infoLine2' });
  const infoLine3 = intl.formatMessage({ id: 'restrictedPage.infoLine3' });

  const adminUsers = [
    { name: 'Barry Burns', email: 'barry@savantlabs.io' },
    { name: 'Matt Masher', email: 'matt@savantlabs.io' },
  ];

  const style = css`
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(254.57deg, rgba(246, 245, 255, 0.7) 13.59%, rgba(212, 208, 255, 0.7) 97.83%);
    #bg-icon {
      position: absolute;
      z-index: 1;
      height: 95%;
      & > img {
        height: 100%;
      }
    }
    .content {
      background-color: #ffffffc7;
      border: 1px solid ${theme.colors.o1};
      width: 520px;
      min-width: 30em;
      min-height: 500px;
      position: relative;
      z-index: 2;
      border-radius: 12px;
      padding: 30px 30px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .sub-title {
        margin-bottom: 1rem;
      }
    }
    .admin-card {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .card {
        padding: 1rem 1rem;
        background-color: #fff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 1rem;
        border: 1px solid ${theme.colors.o1};

        .details {
          display: flex;
          flex-direction: column;
          flex-basis: 80%;
          gap: 0.3rem;
        }
      }
    }
  `;
  return (
    <div css={style}>
      <div id="bg-icon">
        <img src={BackgroundIcon} />
      </div>
      <div className="content">
        <div className="text-t6 restricted-area-label">{restrictedAreaLabel}</div>
        <h3 className="text-t1 sub-title">{subTitle}</h3>
        <div className="text-t10 info">
          <p>{infoLine1}</p>
          <p>{infoLine2}</p>
          <p>{infoLine3}</p>
        </div>
        {adminUsers.map(user => {
          return (
            <div className="admin-card" key={user.name}>
              <div className="card">
                <div className="profile">
                  <Avatar name={user.name} size={32} />
                </div>
                <div className="details">
                  <label className="name text-t10">{user.name}</label>
                  <a className="text-t10" href={`mailto: ${user.email}`}>
                    {user.email}
                  </a>
                </div>
                {/* <div className="role text-t8">Admin</div> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RestrictedPage;
