/* eslint-disable @typescript-eslint/no-explicit-any */
import { Folder } from '@savant-components/catalog';
import client from './client';

export async function listFolders(): Promise<Folder[]> {
  return client.get(`/folders/list`).then(resp => {
    return resp.data.slice.map((summary: { createdTime: any }) => {
      return {
        ...summary,
        createdTime: new Date(summary.createdTime),
      };
    });
  });
}

export async function saveFolder(folder: Folder): Promise<Folder> {
  return client.post(`/folders`, folder).then(resp => {
    return resp.data;
  });
}

export async function deleteFolder(id: string): Promise<void> {
  return client.delete(`/folders/${id || 'no-id'}`);
}
