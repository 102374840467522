/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { useIntl } from 'react-intl';
import Tabs from './Tabs';
import { useFeatureService } from '../../hooks/feature';
import { useNavigate } from 'react-router-dom';

const CatalogLayout = ({
  module,
  children,
}: {
  module: string;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();
  const sourcesMsg = intl.formatMessage({ id: 'app.sources' });
  const connectionsMsg = intl.formatMessage({ id: 'app.connections' });
  const analysesMessage = intl.formatMessage({ id: 'app.analyses' });
  const templatesMessage = intl.formatMessage({ id: 'app.templates' });

  const { isFeatureEnabled } = useFeatureService();
  const canInstallTemplate = isFeatureEnabled('INSTALL_TEMPLATE');

  const onClickNav = (tgt: string) => {
    if (module !== tgt) {
      navigate(`/${intl.locale}/app/${tgt}/`);
    }
  };
  const tabData = React.useMemo(() => {
    if (module === 'source' || module === 'connection') {
      return [
        { label: sourcesMsg, value: 'source' },
        { label: connectionsMsg, value: 'connection' },
      ];
    } else if (module === 'analysis' || module === 'template') {
      if (canInstallTemplate) {
        return [
          { label: analysesMessage, value: 'analysis' },
          { label: templatesMessage, value: 'template' },
        ];
      } else {
        return [{ label: analysesMessage, value: 'analysis' }];
      }
    }
  }, [module, canInstallTemplate]);
  const style = css`
    height: calc(100vh - 4rem);
    & > .tab-row {
      margin: 0 2.25rem;
      height: 40px;
    }
    & > .tab-content {
      margin: 0 2.25rem;
      height: calc(100% - 40px);
      padding: 1rem 0;
    }
  `;
  return (
    <div css={style}>
      <div className="tab-row">
        <Tabs activeTab={module} setActiveTab={onClickNav} tabData={tabData || []} />
      </div>
      <div className="tab-content">{children}</div>
    </div>
  );
};

export default CatalogLayout;
