import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash.throttle';
import { useAlert } from 'react-alert';

import { DataFrame, useMounted } from '@savant-components/basic';
import {
  Automation,
  FlowState,
  getGraph,
  NLPFeedback,
  NLPRequest,
  NLPResponse,
  NodeState,
  SubmitExecutionReq,
} from '@savant-components/builder';

import { getFlow, updateRecipe } from '../services/recipes';
import { getLatestSample, submitExecution } from '../services/execution';
import { handleError } from '../services/client';
import { getBuilderState, saveBuilderState } from '../services/storage';
import { Recipe } from '@savant-components/catalog';
import { getAutomationByRecipeId } from '../services/automation';
import { emitEvent, Event } from '../services/event';
import { askExpression, giveFeedback } from '../services/openai';
import { AxiosError } from 'axios';

export function useBuilderStateCache(graphId: string): {
  openTabs: string[];
  onTabsChange: (data: { tabs: string[]; focusedTab: string }) => void;
} {
  const [openTabs, setOpenTabs] = useState<string[]>([]);
  const [currentGraphId, setCurrentGraphId] = useState<string>(graphId);
  useEffect(() => {
    if (graphId !== currentGraphId) {
      setCurrentGraphId(currentGraphId);
      setOpenTabs([]);
      // removeBuilderState();
    } else {
      const state = getBuilderState() || {};
      if (state[graphId]) {
        setOpenTabs(state[graphId].openTabs);
      } else {
        setOpenTabs([]);
        // removeBuilderState();
      }
    }
  }, [graphId, currentGraphId]);

  const onTabsChange = useCallback(
    ({ tabs, focusedTab }: { tabs: string[]; focusedTab: string }) => {
      const state = getBuilderState() || {};
      state[graphId] = {
        openTabs: tabs,
        focusedTab,
      };
      saveBuilderState(state);
    },
    [graphId],
  );

  return { openTabs, onTabsChange };
}

export function useLatestSample(recipeId: string): {
  fetchLatestSample: (nodeId: string, outlet: string) => Promise<{ sample?: DataFrame; execId?: string }>;
} {
  const alert = useAlert();
  return {
    fetchLatestSample: (nodeId: string, outlet: string) => {
      return getLatestSample(recipeId, nodeId, outlet)
        .then(resp => {
          return {
            sample: resp,
          };
        })
        .catch(err => {
          handleError(err, alert);
          return {};
        });
    },
  };
}

export function useFlow(
  recipe: Recipe | undefined,
  messages: {
    submitted: string;
  },
): {
  isLoading: boolean;
  flow: FlowState;
  recipeVersion: string;
  recipeName: string;
  automation: Automation;
  setFlow: (flow: FlowState) => void;
  onNodeChange: (nodes: NodeState[], callback: () => void) => void;
  onSaveGraph: (nodes: NodeState[]) => void;
  startTestRun: (noticeOption: string) => Promise<void>;
  startRunNow: (noticeOption: string) => Promise<void>;
  askNLP: (req: NLPRequest) => Promise<NLPResponse>;
  giveNLPFeedback: (req: NLPFeedback) => void;
} {
  const flowId = recipe?.id || '';
  const alert = useAlert();
  const [flow, setFlow] = useState<FlowState>();
  const [recipeVersion, setRecipeVersion] = useState<string>();
  const [recipeName, setRecipeName] = useState<string>();
  const [flowName, setFlowName] = useState<string>();
  const [automation, setAutomation] = useState<Automation>();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isMounted } = useMounted({});

  useEffect(() => {
    if (recipe?.name && flowName !== recipe?.name) setFlowName(recipe.name);
  }, [recipe?.name]);
  const throttleSaveNodeChange = useCallback(
    throttle(
      (flowId: string, flow: FlowState, callback: () => void) => {
        updateRecipe(flowId, {
          ...flow,
          name: undefined,
        }).then(() => callback());
      },
      0,
      { leading: false },
    ),
    [],
  );

  const onSaveGraph = useCallback(
    (nodes: NodeState[]) => {
      const flow2 = {
        ...flow,
        name: undefined,
        version: recipeVersion,
        nodes: nodes,
      };
      setFlow(flow2 as FlowState);
      let version = parseInt(recipeVersion as string);
      if (version > 1) {
        // only seal if it is the first version
        version -= 1;
      }
      updateRecipe(flowId, flow2)
        .then(() => {
          alert.success(`The analysis has been saved.`);
        })
        .catch(err => {
          handleError(err, alert);
        });
    },
    [flowId, recipeName, recipeVersion],
  );

  const onNodeChange = useCallback(
    (nodes: NodeState[], callback: () => void) => {
      if (isMounted) {
        const flow2: FlowState = {
          ...flow,
          version: recipeVersion,
          nodes: nodes,
        } as FlowState;
        setFlow(flow2);
        throttleSaveNodeChange(flowId, flow2, callback);
      }
    },
    [flowId, recipeName, recipeVersion, isMounted],
  );

  async function initializeFlow(flowId: string) {
    const [flow, automation] = await Promise.all([getFlow(flowId), getAutomationByRecipeId(flowId)]);
    setFlow({ ...flow, id: flowId });
    setRecipeVersion(flow.version);
    setFlowName(flow.name);
    setRecipeName(flow.name);
    setAutomation(automation);
    setIsLoading(false);
  }

  const startTestRun = async (noticeOption: string): Promise<void> => {
    const { nodes, parameters } = await getGraph();
    try {
      const exec = await submitExecution(
        {
          flowId: flow?.id as string,
          noticeOption,
          nodes,
          parameters,
        },
        'test_run',
      );
      alert.success(messages.submitted.replace('{0}', exec.name));
    } catch (err) {
      handleError(err as AxiosError, alert);
      throw err;
    }
  };
  const startRunNow = async (noticeOption: string): Promise<void> => {
    const { nodes, parameters } = await getGraph();
    const req: SubmitExecutionReq = {
      flowId: flow?.id as string,
      noticeOption,
      nodes,
      parameters,
    };
    try {
      const exec = await submitExecution(req, 'run_now');
      alert.success(messages.submitted.replace('{0}', exec.name));
    } catch (err) {
      handleError(err as AxiosError, alert);
      throw err;
    }
  };

  async function askNLP(req: NLPRequest): Promise<NLPResponse> {
    return askExpression(req).catch(err => {
      handleError(err, alert);
      throw err;
    });
  }

  function giveNLPFeedback(req: NLPFeedback) {
    giveFeedback(req).catch(err => {
      console.error(err);
    });
  }

  // initial loading
  useEffect(() => {
    if (!isInitialized) {
      setIsLoading(true);
      initializeFlow(flowId);
      setIsInitialized(true);
    }
  }, [flowId, isInitialized]);

  // initial loading
  useEffect(() => {
    if (flowName === undefined && flowId !== undefined) {
      getFlow(flowId)
        .then(flow => {
          setFlowName(flow.name);
        })
        .catch(err => {
          handleError(err, alert);
        });
    }
  }, [flowId, flowName]);

  useMounted({
    onMount: () => {
      emitEvent({
        event: 'enter_recipe',
        recipeId: flowId,
      } as Event);
    },
    onUnmount: () => {
      emitEvent({
        event: 'leave_recipe',
        recipeId: flowId,
      } as Event);
    },
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleUnload = () => {
        emitEvent({
          event: 'leave_recipe',
          recipeId: flowId,
        } as Event);
      };
      window.addEventListener('beforeunload', handleUnload);
      // window.addEventListener('unload', handleUnload);
      return () => {
        window.removeEventListener('beforeunload', handleUnload);
        // window.removeEventListener('unload', handleUnload);
      };
    }
  });

  return {
    isLoading,
    flow: { ...flow, id: flowId, name: flowName },
    recipeVersion: recipeVersion as string,
    recipeName: recipeName as string,
    automation: automation as Automation,
    setFlow,
    onNodeChange,
    onSaveGraph,
    startTestRun,
    startRunNow,
    askNLP,
    giveNLPFeedback,
  };
}
