/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import React, { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import { Loader, SvgIcon } from '@savant-components/basic';
import { Button, Button as ButtonV2, Icon, Typography, useTheme } from '@savant-components/theme';
import Tabs from './Tabs';
import { useRuns, useSchedules } from '../../hooks/bot';
import {
  Execution,
  Executions,
  ExecutionType,
  getExecutionTypeLabel,
  Recipe,
  Schedule,
  StateType,
  Trigger,
  TriggerType,
} from '@savant-components/catalog';
import {
  AddSchedule,
  AddTrigger,
  BizzAppConfigureSetup,
  fromCronExpression,
  ScheduleEditor,
  toCronExpression,
  TriggerEditor,
  TrigguerListItem,
  useTrigguerState,
} from '@savant-components/builder';
import { saveAutomation, updateAutomation as updateAutomationViaAPI } from '../../services/automation';
import { handleError } from '../../services/client';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AnalysisContext } from './AnalysisDetailLayout';
import { useFeatureService } from '../../hooks/feature';
import { getTabSession } from '../../services/storage';
import { saveTrigger, updateTrigger as updateTriggerViaAPI } from '../../services/trigger';
import { getAutomationCtx } from '../../contexts';
import { useFlowBuilder } from '../../hooks/flowBuilder';
import { Box, Container } from '@mui/material';

export const Runs = (): React.ReactElement => {
  const ctx = useContext(AnalysisContext);
  const recipe = ctx.recipe;
  const intl = useIntl();
  const tab = getTabSession();
  const [searchText, setSearchText] = useState('');
  function getExecutionLink(exec: Execution) {
    return `/${intl.locale}/app/flow/execution/${exec.id}?rns=${tab.namespace}`;
  }
  const style = css`
    height: 100%;
    padding: 16px 24px;
  `;

  const featureFlag = useFeatureService();

  const setFilterValues = (executions: Execution[]) => {
    const typeFilter = filterOptions.find(f => f.label === 'Type');
    if (typeFilter) {
      typeFilter.options = [...new Set(executions.map(e => (e.type ? getExecutionTypeLabel({ type: e.type }) : '')))];
    }
    const phaseFilter = filterOptions.find(f => f.label === 'Phase');
    if (phaseFilter) {
      phaseFilter.options = [...new Set(executions.map(e => e.phase))];
    }
    setFilterOptions([...filterOptions]);
  };

  const {
    isLoading,
    executions,
    cancelExecution,
    getExecutionsFromExecution,
    downloadReportExecution,
    getRecipeFromExecution,
    updateExecutionMetadata,
  } = useRuns({
    recipeId: recipe?.id as string,
    setFilterValues,
  });

  const [filterOptions, setFilterOptions] = useState([
    {
      label: 'Started At',
      type: 'date',
      placeHolder: 'Select date',
      value: [null, null],
    },
    {
      label: 'Finished At',
      type: 'date',
      placeHolder: 'Select date',
      value: [null, null],
    },
    {
      label: 'Type',
      type: 'select',
      placeHolder: 'Select Type',
      value: [],
      options: [] as ExecutionType[],
    },
    {
      label: 'Phase',
      type: 'select',
      placeHolder: 'Select phase',
      value: [],
      options: [] as string[],
    },
  ]);
  return (
    <div css={style}>
      {isLoading ? (
        <Loader size={200} />
      ) : (
        <Executions
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          key={executions[0]?.progress?.toString()}
          executions={executions || []}
          showRecipeLink={false}
          getExecutionLink={getExecutionLink}
          getRecipe={getRecipeFromExecution}
          cancelExecution={cancelExecution}
          getExecutions={featureFlag.isFeatureEnabled('DOWNLOAD_REPORT') ? getExecutionsFromExecution : undefined}
          downloadReport={featureFlag.isFeatureEnabled('DOWNLOAD_REPORT') ? downloadReportExecution : undefined}
          updateExecutionMetadata={updateExecutionMetadata}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      )}
    </div>
  );
};

export const Schedules = (): React.ReactElement => {
  const ctx = useContext(AnalysisContext);
  const intl = useIntl();
  const automationCtx = getAutomationCtx(intl);
  const recipe = ctx.recipe as Recipe;
  const alert = useAlert();
  const { isTriggerLoading, isAutomationLoading, triggers, automations, updateAutomation, updateTrigger } =
    useSchedules({ recipe });

  const [addingNewSchedule, setAddingNewSchedule] = useState<boolean>();
  const [addingNewTrigger, setAddingNewTrigger] = useState<boolean>();
  const [isAddingSchedule, setIsAddingSchedule] = useState<boolean>(false);
  const [isAddingTrigger, setIsAddingTrigger] = useState<boolean>(false);
  const [isEditingSchedule, setIsEditingSchedule] = useState<boolean>(false);
  const [isEditingTrigger, setIsEditingTrigger] = useState<boolean>(false);

  const recipeId = recipe?.id as string;
  const recipeVersion = recipe?.version ?? '';

  const { isFeatureEnabled } = useFeatureService();
  const isAlwaysOnEnabled = isFeatureEnabled('ALWAYS_ON_SCHEDULE');
  const isEmailTriggerEnabled = isFeatureEnabled('EMAIL_TRIGGER');
  const isWebhookTriggerEnabled = isFeatureEnabled('WEBHOOK_TRIGGER');

  const onAddSchedule = async (schedule: Schedule) => {
    setIsAddingSchedule(true);
    const cron = toCronExpression(schedule);
    saveAutomation({
      id: '',
      recipeId,
      version: recipeVersion,
      cronExpression: cron,
      schedule,
      state: 'ACTIVE',
    })
      .then(data => {
        updateAutomation(data);
      })
      .catch(err => handleError(err, alert))
      .finally(() => {
        setAddingNewSchedule(false);
        setIsAddingSchedule(false);
      });
  };

  const onCreateTrigger = async (type: TriggerType) => {
    setIsAddingTrigger(true);
    // Add Trigger calls after APIs are integrated
    saveTrigger({
      recipeId,
      state: 'ACTIVE',
      type,
      version: recipeVersion,
    }).then(data => {
      updateTrigger(data);
      setIsAddingTrigger(false);
      setAddingNewTrigger(false);
    });
  };

  const ScheduleIcon = styled(Icon)`
    font-size: 40px;
  `;

  const renderTriggerButton = () => {
    if (
      isTriggerLoading ||
      (triggers || []).length > 1 ||
      addingNewSchedule ||
      addingNewTrigger ||
      isEditingSchedule ||
      isEditingTrigger ||
      (!isEmailTriggerEnabled && !isWebhookTriggerEnabled)
    ) {
      return null;
    }
    if (
      isEmailTriggerEnabled &&
      !isWebhookTriggerEnabled &&
      triggers.find((trigger: Trigger) => trigger.type === 'email')
    ) {
      return null;
    }
    if (
      isWebhookTriggerEnabled &&
      !isEmailTriggerEnabled &&
      triggers.find((trigger: Trigger) => trigger.type === 'webhook')
    ) {
      return null;
    }
    return (
      <div className="new-trigger-btn">
        <ButtonV2 icon={'Add'} onClick={() => setAddingNewTrigger(true)} variant="contained" color={'primary'}>
          {isEmailTriggerEnabled && !isWebhookTriggerEnabled
            ? 'Email'
            : !isEmailTriggerEnabled && isWebhookTriggerEnabled
            ? 'Webhook'
            : isEmailTriggerEnabled && isWebhookTriggerEnabled && ''}
          Trigger
        </ButtonV2>
      </div>
    );
  };

  const onSaveTriggerState = (trigger: Trigger, state: StateType) => {
    updateTriggerViaAPI(trigger, state)
      .then(data => {
        updateTrigger(data);
      })
      .catch(err => handleError(err, alert));
  };

  const { builderHandlers } = useFlowBuilder(recipeId);

  const { getBizAppByRecipeId, saveBizApp } = builderHandlers;

  const trigguerState = useTrigguerState(triggers);
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ minWidth: 645, maxWidth: 645, m: 2, gap: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ my: 2 }}>
          <Box display={'flex'} gap={1}>
            <SvgIcon
              icon={'robot'}
              size={21}
              style={css`
                fill: #000;
                top: 2px;
                position: relative;
              `}
            />
            <Typography variant="body1" fontWeight="bold">
              Bot Automation
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption">The analysis will run automatically as follows.</Typography>
          </Box>
        </Box>
        {isAutomationLoading ? (
          <Loader size={200} />
        ) : (
          <React.Fragment>
            {getBizAppByRecipeId && (
              <BizzAppConfigureSetup
                recipeId={recipe?.id}
                getBizAppByRecipeId={getBizAppByRecipeId}
                saveBizApp={saveBizApp}
              />
            )}
            {!isAutomationLoading && (automations || []).length === 0 && !addingNewSchedule && (
              <React.Fragment>
                <TrigguerListItem
                  actions={
                    <Button variant="contained" color="primary" onClick={() => setAddingNewSchedule(true)}>
                      Setup
                    </Button>
                  }
                  icon={<ScheduleIcon name="CalendarMonthOutlined" variant="outlined" fontSize="large" />}
                  caption={
                    <div className="schedule-header">
                      <div className="text-t5">{automationCtx.messages.schedule}</div>
                      <div className="text-t10">The schedule has not been setup.</div>
                    </div>
                  }
                />
              </React.Fragment>
            )}
            {!isAutomationLoading &&
              (automations || []).length > 0 &&
              automations.map(automation => {
                const schedule = automation.schedule || fromCronExpression(automation.cronExpression);
                const onSaveScheduleAndState = (schedule: any, state: string) => {
                  const automationId = automation.id;
                  const cron = toCronExpression(schedule);
                  automation.cronExpression = cron;
                  updateAutomationViaAPI(automationId, cron, state, schedule)
                    .then(data => {
                      updateAutomation(data);
                    })
                    .catch(err => handleError(err, alert));
                };
                return (
                  <div key={`automation-${automation.id}`}>
                    <ScheduleEditor
                      name="Schedule"
                      runs={undefined}
                      nextFireTime={undefined}
                      schedule={schedule}
                      state={automation.state}
                      onSaveScheduleAndState={onSaveScheduleAndState}
                      enableAlwaysOnSchedule={isAlwaysOnEnabled}
                      setIsEditingSchedule={setIsEditingSchedule}
                    />
                  </div>
                );
              })}
            {!isTriggerLoading &&
              !isEditingSchedule &&
              triggers.map(trigger => {
                return (
                  <TriggerEditor
                    key={trigger?.triggerId}
                    trigger={trigger}
                    onSaveTriggerState={onSaveTriggerState}
                    setIsEditingTrigger={setIsEditingTrigger}
                  />
                );
              })}

            {renderTriggerButton()}
            {!isAutomationLoading && (automations || []).length === 0 && addingNewSchedule && (
              <div className="new-schedule">
                {isAddingSchedule && <Loader size={100} />}
                {!isAddingSchedule && (
                  <AddSchedule
                    enableAlwaysOnSchedule={isAlwaysOnEnabled}
                    onCreateBot={onAddSchedule}
                    onCancel={() => setAddingNewSchedule(false)}
                  />
                )}
              </div>
            )}
            {!isTriggerLoading && addingNewTrigger && (
              <Box className="new-trigger">
                {isAddingTrigger ? (
                  <Loader size={100} />
                ) : (
                  <AddTrigger
                    onCreateTrigger={onCreateTrigger}
                    onCancel={() => setAddingNewTrigger(false)}
                    triggers={trigguerState}
                  />
                )}
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>
    </Container>
  );
};

const BotView = (): React.ReactElement => {
  const theme = useTheme();
  const ctx = useContext(AnalysisContext);
  const recipe = ctx.recipe;
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();
  const tab = useMemo(() => {
    if (location.pathname.endsWith('/automation') || location.pathname.includes('/automation/')) {
      return 'automation';
    } else {
      return 'runs';
    }
  }, [location.pathname]);
  const onSwitchTab = (newTab: string) => {
    if (newTab !== tab) {
      const tabSession = getTabSession();
      navigate(`/${intl.locale}/app/flow/${recipe?.id}/bot/${newTab}?rns=${tabSession?.namespace}`);
    }
  };
  const style = css`
    height: 100%;
    & > .tabs-row {
      height: 40px;
      padding: 0 24px;
      border-bottom: 1px solid ${theme.colors.o2};
    }
    & > .tab-content {
      height: calc(100% - 40px);
      overflow-y: auto;
    }
  `;
  return (
    <div css={style}>
      <div className="tabs-row">
        <Tabs
          activeTab={tab}
          setActiveTab={onSwitchTab}
          tabData={[
            { label: 'Runs', value: 'runs' },
            { label: 'Automation', value: 'automation' },
          ]}
        />
      </div>
      <div className="tab-content">{recipe && <Outlet />}</div>
    </div>
  );
};

export default BotView;
