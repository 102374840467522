/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import TagManager from 'react-gtm-module';

import { Divider, Paper, Popover } from '@mui/material';

import { useTheme } from '@savant-components/theme';
import {
  Spacer,
  Button,
  FlexFiller,
  Avatar,
  ClickableIcon,
  EditableText,
  ImageUploaderClickable,
} from '@savant-components/basic';
import { getLogoutRedirect } from '../../services/login';
import { clearLocalStorage, clearSessionStorage, removeBuilderState } from '../../services/storage';
import { handleError } from '../../services/client';
import { useUploader } from '../../services/uploader';
import { useUserProfile } from './useUserProfile';

const UserMenu = (): JSX.Element => {
  const theme = useTheme();
  const intl = useIntl();
  const alert = useAlert();
  //const profileLabel = intl.formatMessage({ id: 'app.profile', defaultMessage: 'Profile' });
  //const settingsLabel = intl.formatMessage({ id: 'app.settings', defaultMessage: 'Settings' });
  const { user, updateUserLogo, updateFirstName, updateLastName } = useUserProfile();
  const signOutLabel = intl.formatMessage({ id: 'header.signOut', defaultMessage: 'Sign out' });
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isEditProfile, setIsEditProfile] = React.useState<boolean>(false);
  const [isProfileImgChange, setIsProfileImgChange] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const avatarLabel = intl.formatMessage({ id: 'app.avatar', defaultMessage: 'Avatar' });
  const firstNameLable = intl.formatMessage({ id: 'signup.givenName' });
  const lastNameLable = intl.formatMessage({ id: 'signup.familyName' });
  const emailLable = intl.formatMessage({ id: 'app.email' });

  const uploader = useUploader();

  useEffect(() => {
    if (user.avatarFileId !== imageUrl) {
      setImageUrl(user.avatarUrl);
    }
  }, [user.avatarUrl]);

  const openUserMenu = Boolean(userMenuAnchorEl);
  const handleClickUserAvatar: MouseEventHandler<HTMLDivElement> = event => {
    setUserMenuAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setIsEditProfile(false);
    setUserMenuAnchorEl(null);
  };
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>();
  const handleSignOut = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isLoggingOut) {
      setIsLoggingOut(true);
      getLogoutRedirect()
        .then(logoutUrl => {
          removeBuilderState();
          const tagManagerArgs = {
            dataLayer: {
              event: 'logout',
            },
            dataLayerName: 'PageDataLayer',
          };
          TagManager.dataLayer(tagManagerArgs);
          clearLocalStorage();
          clearSessionStorage();
          window.location.href = logoutUrl;
        })
        .catch(err => handleError(err, alert))
        .finally(() => {
          setIsLoggingOut(false);
        });
    }
  };

  const btnStyle = css`
    border-radius: 8px;
    // border: 0px solid ${theme.colors.o2};
    background: ${openUserMenu ? theme.colors.b4 : theme.colors.b6};
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 6px 6px;
    :hover {
      background: ${theme.colors.b7};
    }
  `;

  const menuStyle = css`
    border-radius: 4px;
    border: 1px solid ${theme.colors.o2};
    width: 300px;
    & > .loader {
      height: 200px;
    }
    & > .user-menu {
      margin-top: 16px;
      margin-bottom: 16px;
      & > .user-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > .ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 90%;
        }
      }
      & > .user-btns {
        margin-top: 8px;
        display: flex;
        gap: 0.5rem;
      }
    }
  `;
  const contentStyle = css`
    & > .header {
      display: flex;
      align-items: center;
      margin: 16px 24px 8px 8px;
      text-transform: uppercase;
    }
    & > .profile-container {
      margin: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  `;
  return (
    <React.Fragment>
      <div css={btnStyle} aria-describedby={'user-profile'} onClick={handleClickUserAvatar}>
        <Avatar name={user.name} img={user?.avatarUrl} size={24} />
      </div>
      <Popover
        id={'user-profile'}
        open={openUserMenu}
        anchorEl={userMenuAnchorEl}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Paper css={menuStyle}>
          {isEditProfile ? (
            <div css={contentStyle}>
              <div className="header">
                <ClickableIcon
                  color={theme.colors.i2}
                  size={24}
                  icon={['las', 'angle-left']}
                  onClick={() => setIsEditProfile(false)}
                />
                <span className="text-t8">{`Edit Profile`}</span>
              </div>
              <Divider />
              <div className="profile-container">
                <div>
                  <div className="section-title text-t5">{firstNameLable}</div>
                  <EditableText
                    text={user?.firstName || ''}
                    onUpdateText={s => updateFirstName(s)}
                    size="t6"
                    keepEditIconVisible
                    maxWidth="250px"
                    inputWidth="250px"
                  />
                </div>
                <div>
                  <div className="section-title text-t5">{lastNameLable}</div>
                  <EditableText
                    text={user?.lastName || ''}
                    onUpdateText={s => updateLastName(s)}
                    size="t6"
                    keepEditIconVisible
                    inputWidth="250px"
                    maxWidth="250px"
                  />
                </div>
                <div className="section-p">
                  <div className="section-title text-t5">{emailLable}</div>
                  <span>{user.email}</span>
                </div>
                <div>
                  <div className="section-title text-t5">{avatarLabel}</div>
                  <Spacer direction="vertical" size="8px" />
                  <ImageUploaderClickable
                    updateLogo={updateUserLogo}
                    imageUrl={imageUrl || ''}
                    setIsProfileImgChange={setIsProfileImgChange}
                    isProfileImgChange={isProfileImgChange}
                    uploader={uploader}
                    userName={user.name}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="user-menu">
              <div className="user-info">
                <Avatar name={user.name} img={user?.avatarUrl} size={32} />
                <Spacer direction="vertical" size="8px" />
                {user.name && <div className="text-t5 text-nowrap ellipsis">{user.name}</div>}
                {user.email && user.email !== user.name && <div className="text-t10">{user.email}</div>}
              </div>
              <div className="user-btns">
                <FlexFiller />
                <Button
                  design="secondary"
                  disabled={isLoggingOut}
                  label={`Edit Profile`}
                  onClick={() => setIsEditProfile(true)}
                />
                <Button
                  design="secondary"
                  loading={isLoggingOut}
                  disabled={isLoggingOut}
                  label={signOutLabel}
                  onClick={handleSignOut}
                />
                <FlexFiller />
              </div>
            </div>
          )}
        </Paper>
      </Popover>
    </React.Fragment>
  );
};

export default UserMenu;
