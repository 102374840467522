import { useAlert } from 'react-alert';

import { NotificationSetting, WorkspaceSetting, useAsync } from '@savant-components/basic';
import { handleError } from '../services/client';
import { getSession, getTabSession } from '../services/storage';
import {
  addUsers,
  archiveWorkspace,
  createWorkspace,
  getWorkspaceDetail,
  listWorkspacesInOrg,
  removeUser,
  searchUsersWithRequest,
  updateUserRole,
  updateWorkspace,
} from '../services/workspace';
import { useEffect, useState } from 'react';
import { Workspace } from '../types';
import { Connection, User } from '@savant-components/catalog';
import { Option } from '@savant-components/basic';
import { RegisterUserRequest, UpdateUserRequest } from '../services/user';
import { emitCustomEvent } from 'react-custom-events';
import events from '../events';
import {
  getNotificationSetting,
  saveNotificationSetting,
  updateNotificationSetting,
  listWorkspaceSlackConnections as listWorkspaceSlackConnectionsViaApi,
  listWorkspaceTeamsConnections as listWorkspaceTeamsConnectionsViaApi,
  listWorkspaceSlackChannels as listWorkspaceSlackChannelsViaApi,
  describeWorkspaceSlackChannel as describeWorkspaceSlackChannelViaApi,
  getWorkspaceSetting,
  updateWorkspaceSetting,
} from '../services/notifications';
import { SlackChannel } from '@savant-components/builder';
import { AxiosError } from 'axios';
import moment from 'moment';

// FIXME: fetch from api
const rolesInWorkspace = [
  { id: '1', name: 'Admin' },
  { id: '2', name: 'Analyst' },
  { id: '3', name: 'Business User' },
];

export interface IUseWorkspace {
  workspace: {
    members: User[];
  } & Workspace;
  isLoading: boolean;
  isWorkspaceAdmin: boolean;
  addMembers: (members: User[]) => void;
  removeMember: (member: User) => Promise<void>;
  joinAsAdmin: (member: User) => void;
  searchUsers: (email: string) => Promise<Option<string>[]>;
  updateMemberRole: (email: string, role: string) => Promise<string>;
  updateWorkspaceName: (workspaceId: string, newName: string) => Promise<string>;
  recipients: string[];
  setRecipients: (recipients: string[]) => void;
  setting: NotificationSetting;
  setSetting: (setting: NotificationSetting) => void;
  selected: string;
  setSelected: (selected: string) => void;
  updateSettings: () => void;
  isButtonDiabled: boolean;
  listWorkspaceSlackConnections: () => Promise<Connection[]>;
  listWorkspaceTeamsConnections: () => Promise<Connection[]>;
  listWorkspaceSlackChannels: (connectionId: string) => Promise<SlackChannel[]>;
  describeWorkspaceSlackChannel: (connectionId: string, channelId: string) => Promise<SlackChannel>;
  workspaceSetting: WorkspaceSetting | undefined;
  setWorkspaceSetting: (setting: WorkspaceSetting) => void;
}

export function useWorkspaces(): {
  workspaces: Workspace[];
  isLoading: boolean;
  onArchive: (ws: Workspace) => void;
  onAddWorkspace: (workspaceName: string) => void;
} {
  const alert = useAlert();
  const { value, status, error, execute } = useAsync(() => {
    const session = getTabSession();
    return listWorkspacesInOrg(session?.orgId);
  }, true);

  useEffect(() => {
    if (error) {
      handleError(error as AxiosError, alert);
    }
  }, [error]);
  const onArchive = (ws: Workspace): void => {
    if (ws) {
      const session = getTabSession();
      archiveWorkspace(session.orgId, ws.id)
        .then(() => emitCustomEvent(events.ARCHIVE_WORKSPACE, ws))
        .catch(err => handleError(err, alert))
        .finally(() => execute());
    }
  };
  const onAddWorkspace = (workspaceName: string): void => {
    const session = getTabSession();
    createWorkspace(session.orgId, workspaceName)
      .then(() => emitCustomEvent(events.NEW_WORKSPACE, workspaceName))
      .catch(err => handleError(err, alert))
      .finally(() => execute());
  };
  const isLoading = status !== 'success' && status !== 'error';
  return {
    workspaces: value || [],
    isLoading,
    onArchive,
    onAddWorkspace,
  };
}

export function containsAdmin(members: User[], email: string): boolean {
  if ((members || []).length > 0) {
    return (
      members.findIndex(member => {
        return member.email === email && member.role === 'Admin';
      }) >= 0
    );
  } else {
    return false;
  }
}

export function useWorkspace(workspaceId: string, isPurgingExecutionEnabled: boolean): IUseWorkspace {
  const alert = useAlert();
  const [recipients, setRecipients] = useState<string[]>();
  const [isButtonDiabled, setIsButtonDiabled] = useState<boolean>(false);

  const [setting, setSetting] = useState<NotificationSetting>();
  const [workspaceSetting, setWorkspaceSetting] = useState<WorkspaceSetting>();
  const [selected, setSelected] = useState<string>('none');

  const getExecutionExpirationTime = (period: moment.Duration) => {
    if (!period) {
      return undefined;
    }

    const yearCount = period.years();
    const monthCount = period.months();

    if (monthCount > 0) {
      return period.asMonths() + ' MONTHS';
    }

    if (yearCount > 0) {
      return yearCount + ' YEARS';
    }

    return undefined;
  };

  useEffect(() => {
    setIsButtonDiabled(true);
    getNotificationSetting('workspace', undefined, workspaceId)
      .then(data => {
        if (data) {
          setRecipients(data.emailInfo?.recipients);
          setSelected(data.option);
          setSetting(data);
        } else {
          saveNotificationSetting(
            [],
            'workspace',
            selected,
            undefined,
            workspaceId,
            setting?.slackInfo?.connectionId,
            setting?.slackInfo?.channelId,
          )
            .then(saved => {
              setRecipients(saved.emailInfo?.recipients);
              setSelected(saved.option);
              setSetting(data);
            })
            .catch(err => handleError(err, alert));
        }
      })
      .catch(err => handleError(err, alert));

    if (isPurgingExecutionEnabled) {
      getWorkspaceSetting(workspaceId)
        .then(data => {
          if (data) {
            setWorkspaceSetting({
              ...data,
              executionExpirationTime: getExecutionExpirationTime(moment.duration(data.executionExpirationTime)),
            });

            return;
          }

          if (workspaceSetting) {
            updateWorkspaceSetting(workspaceSetting.executionExpirationTime, workspaceId)
              .then(updatedWorkspaceSetting =>
                setWorkspaceSetting({
                  ...updatedWorkspaceSetting,
                  executionExpirationTime: getExecutionExpirationTime(
                    moment.duration(updatedWorkspaceSetting.executionExpirationTime),
                  ),
                }),
              )
              .catch(err => handleError(err, alert));

            return;
          }
        })
        .catch(err => handleError(err, alert));
    }
  }, []);

  useEffect(() => setIsButtonDiabled(false), [setting]);

  const { value, status, error, execute } = useAsync(() => {
    const session = getTabSession();
    return getWorkspaceDetail(session?.orgId, workspaceId).then(ws => {
      const members = (ws.members || []).sort((a, b) => a.name.localeCompare(b.name));
      return {
        ...ws,
        members,
      };
    });
  }, true);
  useEffect(() => {
    if (error) handleError(error as AxiosError, alert);
  }, [error]);
  const isLoading = status !== 'success' && status !== 'error';

  const curSession = getSession();
  const isWorkspaceAdmin = !isLoading && containsAdmin(value?.members || [], curSession.userId);

  const addMembers = (members: User[]): void => {
    const session = getTabSession();
    const users: RegisterUserRequest[] = members.map((m: User) => ({ email: m.email } as RegisterUserRequest));
    addUsers(session.orgId, workspaceId, users)
      .catch(err => handleError(err, alert))
      .finally(() => execute());
  };

  const removeMember = (member: User): Promise<void> => {
    const session = getTabSession();
    return removeUser(session.orgId, workspaceId, { firstName: '', lastName: '', email: member.email || '' })
      .catch(err => handleError(err, alert))
      .finally(() => execute());
  };

  const joinAsAdmin = (): void => {
    const curUserEmail = curSession.userId;
    updateMemberRole(curUserEmail, 'Admin');
  };

  const searchUsers = (email: string): Promise<Option<string>[]> => {
    const session = getTabSession();

    if (email) {
      return searchUsersWithRequest(session.orgId, workspaceId, { partialString: email }).then(users => {
        const options: Option<string>[] = users.map((u: User) => ({
          value: u.email,
          label: u.email,
        }));

        return options;
      });
    }

    return Promise.resolve([]);
  };

  const updateMemberRole = (email: string, role: string): Promise<string> => {
    const roleId = rolesInWorkspace.find(r => r.name.toLowerCase() === role.toLowerCase())?.id;
    if (email && roleId) {
      const updateRequest: UpdateUserRequest = {
        email,
        roleId,
      };
      const session = getTabSession();
      const orgId = session.orgId;
      return updateUserRole(orgId, workspaceId, updateRequest)
        .then(() => role)
        .catch(err => {
          handleError(err, alert);
          return '';
        })
        .finally(() => execute());
    } else {
      return Promise.resolve('');
    }
  };

  const updateWorkspaceName = (workspaceId: string, newName: string): Promise<string> => {
    const session = getTabSession();
    const orgId = session.orgId;
    return updateWorkspace(orgId, workspaceId, newName)
      .then(() => {
        emitCustomEvent(events.NEW_WORKSPACE, newName);
        return newName;
      })
      .catch(err => {
        handleError(err, alert);
        return '';
      })
      .finally(() => execute());
  };

  const updateSettings = () => {
    setIsButtonDiabled(true);

    updateNotificationSetting(
      recipients as string[],
      selected,
      'workspace',
      undefined,
      workspaceId,
      setting?.slackInfo?.connectionId,
      setting?.slackInfo?.channelId,
      setting?.teamsInfo?.connectionId,
      setting?.teamsInfo?.channelId,
    )
      .then(data => {
        setSetting(data);

        if (isPurgingExecutionEnabled) {
          updateWorkspaceSetting(workspaceSetting?.executionExpirationTime, workspaceId)
            .then(updatedWorkspaceSetting => {
              setWorkspaceSetting({
                ...updatedWorkspaceSetting,
                executionExpirationTime: getExecutionExpirationTime(
                  moment.duration(updatedWorkspaceSetting.executionExpirationTime),
                ),
              });

              alert.success('Settings has been saved.');
            })
            .catch(err => handleError(err, alert));
        } else {
          alert.success('Settings has been saved.');
        }
      })
      .catch(err => handleError(err, alert));
  };

  const listWorkspaceSlackConnections = async (): Promise<Connection[]> => {
    return listWorkspaceSlackConnectionsViaApi(workspaceId).catch(err => {
      handleError(err, alert);
      return [];
    });
  };

  const listWorkspaceTeamsConnections = async (): Promise<Connection[]> => {
    return listWorkspaceTeamsConnectionsViaApi(workspaceId).catch(err => {
      handleError(err, alert);
      return [];
    });
  };

  const listWorkspaceSlackChannels = async (connectionId: string): Promise<SlackChannel[]> => {
    return listWorkspaceSlackChannelsViaApi(connectionId, workspaceId).catch(err => {
      handleError(err, alert);
      return [];
    });
  };

  const describeWorkspaceSlackChannel = async (connectionId: string, channelId: string): Promise<SlackChannel> => {
    return describeWorkspaceSlackChannelViaApi(connectionId, channelId, workspaceId).catch(err => {
      handleError(err, alert);
      return undefined as unknown as SlackChannel;
    });
  };

  return {
    workspace: value as { members: User[] } & Workspace,
    isLoading,
    isWorkspaceAdmin,
    addMembers,
    removeMember,
    joinAsAdmin,
    searchUsers,
    updateMemberRole,
    updateWorkspaceName,
    recipients: recipients as string[],
    setRecipients,
    setting: setting as NotificationSetting,
    setSetting,
    selected,
    setSelected,
    updateSettings,
    isButtonDiabled,
    listWorkspaceSlackConnections,
    listWorkspaceTeamsConnections,
    listWorkspaceSlackChannels,
    describeWorkspaceSlackChannel,
    workspaceSetting,
    setWorkspaceSetting,
  };
}
