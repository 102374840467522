import { StateType, Trigger } from '@savant-components/catalog';
import client from './client';

export const saveTrigger = async (params: {
  type: 'email' | 'webhook' | 'application';
  recipeId: string;
  version: string;
  state: StateType;
}) => {
  const payload = { executionTrigger: params };
  return client.post(`/execution-trigger`, payload).then(resp => {
    return resp.data;
  });
};

export const updateTrigger = async (trigger: Trigger, state: StateType) => {
  const payload: Trigger = {
    recipeId: trigger.recipeId,
    state: state as StateType,
    version: trigger.version,
    type: trigger.type,
    triggerId: trigger.triggerId,
  };
  return client.put(`/execution-trigger`, payload).then(resp => {
    return resp.data;
  });
};

export const getTriggerByRecipeId = async (recipeId: string): Promise<Trigger[]> => {
  return client.get(`/recipes/${recipeId}/triggers`).then(resp => {
    return resp.data?.executionTriggers || [];
  });
};
