import { useAlert } from 'react-alert';
import { handleError, is404 } from '../services/client';
import { BizApp } from '@savant-components/catalog';
import { getBizAppByRecipeId as getBizAppByRecipeIdViaAPI, saveBizApp as saveBizAppViaAPI } from '../services/bizapp';

export function useBizAppConfig({
  recipeId,
  recipeVersion,
  recipeName,
}: {
  recipeId: string;
  recipeVersion: string;
  recipeName: string;
}): {
  getBizAppByRecipeId: (recipeId: string) => Promise<BizApp>;
  saveBizApp: (bizApp: BizApp) => Promise<BizApp>;
} {
  const alert = useAlert();
  const getBizAppByRecipeId = async (recipeId: string): Promise<BizApp> => {
    return getBizAppByRecipeIdViaAPI(recipeId).catch(err => {
      if (is404(err)) {
        return {
          name: recipeName || 'My Business App',
        } as BizApp;
      } else {
        handleError(err, alert);
        return undefined as unknown as BizApp;
      }
    });
  };

  const saveBizApp = async (bizApp: BizApp): Promise<BizApp> => {
    return saveBizAppViaAPI(recipeId, recipeVersion, bizApp).catch(err => {
      handleError(err, alert);
      return bizApp;
    });
  };

  return {
    getBizAppByRecipeId,
    saveBizApp,
  };
}
