import { css, styled } from '@mui/material';
import { useTheme, WithTheme } from '@savant-components/theme';
import { Loader } from '@savant-components/basic';
import React from 'react';
interface RootStyleProps extends WithTheme {
  showAutomatePane?: boolean;
}

export const SavantLoader = () => <Loader size={200} color={useTheme().colors.secondary} />;
export const FlowBuilderContainer = styled('div')<RootStyleProps>(
  ({ theme, showAutomatePane }) => css`
    width: 100%;
    height: 100%;
    position: relative;

    .builder-overlay {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

    .automation-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      display: ${showAutomatePane ? 'inherit' : 'none'};
      background: ${theme?.colors.background};
      width: 400px;
      padding: 25px;
      border: 1px solid ${theme?.colors.muted};
      border-radius: 0.5em;
    }
  `,
);
