/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';

import LoginForm from '../components/LoginForm';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { SignInContext } from '../contexts';

const LoginPage = (): React.ReactElement => {
  const { orientation, breakpoint } = useWindowDimensions();

  const standardStyle = css`
    #bg-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      & > img {
        width: 1000px;
      }
    }
  `;

  const style = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #f9f9ff;
    * {
      transition: width 0.25s ease;
    }
    #login-form {
      display: flex;
      height: 100vh;
    }
    ${breakpoint !== 'sm' ? standardStyle : ''}
  `;

  return (
    <div css={style}>
      {breakpoint !== 'sm' && (
        <div id="bg-img">
          <img src="https://assets.savantlabs.io/brand/icon.svg" />
        </div>
      )}
      <div id="login-form">
        <SignInContext.Provider value={{ orientation, breakpoint }}>
          {typeof window !== 'undefined' && <LoginForm />}
        </SignInContext.Provider>
      </div>
    </div>
  );
};

export default LoginPage;
