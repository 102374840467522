/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { useIntl } from 'react-intl';

import { useTheme } from '@savant-components/theme';
import { useFeatureService } from '../../hooks/feature';
import { useNavigate } from 'react-router-dom';
import { getTabSession } from '../../services/storage';

interface SideNavLinkProps {
  title: string;
  module: string;
  active: boolean;
  onClick?: () => void;
}

const SideNavLink = React.memo(({ title, active, module, onClick }: SideNavLinkProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  if (onClick === undefined) {
    onClick = () => {
      navigate(`/${intl.locale}/app/admin/${module}/`);
    };
  }
  const style = css`
    margin: 0.5rem 0;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 196px;
    cursor: pointer;
    background: ${active ? theme.colors.b4 : theme.colors.b6};
    :hover {
      background: ${theme.colors.b5};
    }
  `;
  return (
    <div css={style} onClick={onClick}>
      {title}
    </div>
  );
});

const AdminLayout = ({
  children,
  module,
}: {
  children: JSX.Element | JSX.Element[];
  module: string;
}): React.ReactElement => {
  const theme = useTheme();
  const intl = useIntl();
  const tab = getTabSession();
  const org = tab.organization;

  const adminLabel = intl.formatMessage({ id: 'app.globalAdmin' });
  const profileLabel = intl.formatMessage({ id: 'app.profile' });
  const usersLabel = intl.formatMessage({ id: 'app.users' });
  const usageLogLabel = intl.formatMessage({ id: 'app.usageLog' });
  const workspacesLabel = intl.formatMessage({ id: 'app.workspaces' });
  const notificationsLabel = intl.formatMessage({ id: 'app.notifications' });

  const { isFeatureEnabled } = useFeatureService();
  const canSeeUsers = new Boolean(tab?.orgId) as boolean;
  const canSeeWorkspaces = isFeatureEnabled('SEE_WORKSPACES_TAB');
  const canSeeNotifications = org?.role === 'Admin';
  let showContent = true;
  switch (module) {
    case 'users':
      showContent = canSeeUsers;
      break;
    case 'workspaces':
      showContent = canSeeWorkspaces;
      break;
  }

  const style = css`
    & > .account-side-nav {
      position: absolute;
      left: 24px;
      top: 0;
      bottom: 0;
      width: calc(196px + 16px);
      overflow: auto;
      border-right: 1px solid ${theme.colors.o2};
      & > .account-side-nav-title {
        margin: 0.5rem 0;
        padding: 0.5rem;
        width: 196px;
      }
    }
    & > .account-main-content {
      position: absolute;
      left: calc(24px + 196px + 16px + 1px);
      right: 0;
      top: 0;
      bottom: 0;
      overflow: auto;
    }
  `;

  return (
    <div css={style}>
      <div className="account-side-nav">
        <div className="account-side-nav-title text-t1">{adminLabel}</div>
        <SideNavLink title={profileLabel} active={module === 'profile'} module="profile" />
        {canSeeUsers && <SideNavLink title={usersLabel} active={module === 'users'} module="users" />}
        {canSeeWorkspaces && (
          <SideNavLink title={workspacesLabel} active={module === 'workspaces'} module="workspaces" />
        )}
        <SideNavLink title={usageLogLabel} active={module === 'usage'} module="usage" />
        {canSeeNotifications && (
          <SideNavLink title={notificationsLabel} active={module === 'notifications'} module="notifications" />
        )}
      </div>
      {showContent && <div className="account-main-content">{children}</div>}
    </div>
  );
};

export default AdminLayout;
