import client, { ApiPromise, followPromise } from './client';
import { toDeepLink } from './deeplink';
import { Recipe, Template, Connector } from '@savant-components/catalog';

export const listTemplates = async (): Promise<Template[]> => {
  return client.get(`/templates/list`).then(resp => {
    return resp.data?.slice;
  });
};

export const listWritableTemplates = async (): Promise<Template[]> => {
  return client.get(`/templates/list?writable=1`).then(resp => {
    return resp.data?.slice;
  });
};

export const createTemplateByExecution = async (
  execId: string,
  tmplName: string,
  description: string,
  tags: string[],
): Promise<Template> => {
  return client
    .post(`/templates/async`, {
      executionId: execId,
      templateName: tmplName,
      includeSourceData: true,
      description: description,
      tags: tags,
    })
    .then(resp => {
      const promise: ApiPromise<Template> = resp.data;
      promise.createdTime = new Date();
      return followPromise(promise, undefined).then(result => {
        return result as Template;
      });
    });
};

export const updateTemplateByExecution = async (execId: string, tmplId: string): Promise<Template> => {
  return client
    .post(`/templates`, {
      executionId: execId,
      templateId: tmplId,
      includeSourceData: true,
    })
    .then(resp => {
      return resp.data;
    });
};

export const publishTemplate = async (templateId: string, isPublishing: boolean): Promise<Template> => {
  const req = {
    templateId,
    unpublish: !isPublishing,
  };
  return client.put(`/templates/publish`, req).then(resp => {
    return resp.data;
  });
};

export const installTemplate = async (templateId: string): Promise<Recipe> => {
  const req = {
    templateId,
  };
  return client.post(`/templates/install-async`, req).then(resp => {
    const promise: ApiPromise<Recipe> = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as Recipe;
    });
  });
};

export const deleteTemplate = async (templateId: string): Promise<void> => {
  return client.delete(`/templates/${templateId}`);
};

export const getInstallLink = async (templateId: string): Promise<string> => {
  return client.get(`/templates/${templateId}/install-link`).then(resp => {
    const linkId = resp.data.id;
    return toDeepLink(linkId);
  });
};

export const updateTemplateMetadata = async (req: {
  id: string;
  name: string;
  description?: string;
  tags?: string[];
  helpUrl?: string;
  isNew?: boolean;
  connectors?: Connector[];
}): Promise<Template> => {
  return client
    .put(`/templates/metadata`, {
      id: req.id,
      name: req.name,
      description: req.description,
      tags: req.tags,
      helpUrl: req.helpUrl,
      isNew: req.isNew,
      connectors: req.connectors,
    })
    .then(resp => {
      return resp.data;
    });
};
