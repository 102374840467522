/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';
import { Collapse, Button as MButton } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

import { MInput, Loader, Button } from '@savant-components/basic';
import { useTheme, Icon } from '@savant-components/theme';
import { getLandingUrl, saveLandingUrl } from '../services/storage';

import { handleError } from '../services/client';
import { verifyEmail } from '../services/user';
import { SignInContext } from '../contexts';
import { LANDING_URI } from '../constants';
import { getLoginRedirect, verifyRecapthaToken } from '../services/login';
import { SSO_PROVIDERS, getProviderName } from './LoginForm';
import Footer from './footer';

const DEFAULT_FORM_DATA = {
  workEmail: '',
  givenName: '',
  familyName: '',
  password: '',
  confirmPassword: '',
};

const SignUpForm = (): React.ReactElement => {
  const alert = useAlert();
  const intl = useIntl();
  const locale = intl.locale;
  const theme = useTheme();

  const continueWithLabel = intl.formatMessage({ id: 'signup.continueWith' });
  const invalidEmailLabel = intl.formatMessage({ id: 'signup.invalidEmail' });

  const showReCAPTCHA =
    Boolean(process.env.REACT_APP_RECAPTCHA_SITE_KEY) &&
    (window.location.hostname === 'localhost' ||
      window.location.hostname === 'app.savant-dev.net' ||
      window.location.hostname === 'app.savant-prod.net' ||
      window.location.hostname === 'app.savantlabs.io' ||
      window.location.hostname === 'app-eu.savantlabs.io');
  const reCAPTCHASiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
  const _reCaptchaRef = React.useRef<ReCAPTCHA>(null);

  const ctx = React.useContext(SignInContext);

  const [{ confirmPassword, familyName, givenName, password, workEmail }, setFormData] = useState(DEFAULT_FORM_DATA);
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [showConfirmPasswordRequirements, setShowConfirmPasswordRequirements] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [signUpChannel, setSignUpChannel] = useState('');
  const [fieldErrors, setFieldErrors] = useState({
    workEmail: '',
    password: '',
    confirmPassword: '',
    givenName: '',
    familyName: '',
  });
  const [verifyingEmailSend, setVerifyingEmailSend] = useState(false);

  const { criterion1, criterion2, criterion3, criterion4, criterion5 } = useMemo(
    () => ({
      criterion1: {
        isMet: password.length >= 8,
        message: intl.formatMessage({ id: 'password.policy1' }).replace('{0}', '8'),
      },
      criterion2: {
        isMet: password.match(/[a-z]/) !== null,
        message: intl.formatMessage({ id: 'password.policy2' }) + ` (a-z)`,
      },
      criterion3: {
        isMet: password.match(/[A-Z]/) !== null,
        message: intl.formatMessage({ id: 'password.policy3' }) + ` (A-Z)`,
      },
      criterion4: {
        isMet: password.match(/[0-9]/) !== null,
        message: intl.formatMessage({ id: 'password.policy4' }) + ` (0-9)`,
      },
      criterion5: {
        isMet: password.match(/[!@#$%^&*]/) !== null,
        message: intl.formatMessage({ id: 'password.policy5' }) + ` (!@#$%^&*)`,
      },
    }),
    [password],
  );

  // Returns true if field is valid
  const validateField = useCallback(
    (field: 'workEmail' | 'password' | 'confirmPassword' | 'givenName' | 'familyName') => {
      let error = '';
      switch (field) {
        case 'workEmail':
          const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(workEmail);
          error = !isValidEmail ? invalidEmailLabel : '';
          break;
        case 'password':
          const [firstUnmetCondition] = [
            {
              isMet: Boolean(password),
              message: intl
                .formatMessage({ id: 'signup.requiredFieldCheck' })
                .replace('{0}', intl.formatMessage({ id: 'login.password' })),
            },
            criterion1,
            criterion2,
            criterion3,
            criterion4,
            criterion5,
          ].filter(criterion => !criterion.isMet);
          error = firstUnmetCondition?.message;
          break;
        case 'confirmPassword':
          error =
            !confirmPassword || password !== confirmPassword
              ? intl.formatMessage({ id: 'signup.confirmPasswordCheck' })
              : '';
          break;
        case 'givenName':
        case 'familyName':
          const name = field === 'familyName' ? familyName : givenName;
          const doesNameContainNumbers = /\d/.test(name);
          const [firstError] = [
            {
              error: !name,
              message: intl
                .formatMessage({ id: 'signup.requiredFieldCheck' })
                .replace('{0}', intl.formatMessage({ id: `signup.${field}` })),
            },
            {
              error: doesNameContainNumbers,
              message: intl.formatMessage({ id: 'signup.nameFieldCheck' }),
            },
          ].filter(({ error }) => error);
          error = firstError?.message;
          break;
        default: {
          const exhaustiveCheck: never = field;
          throw new Error(`Unhandled field: ${exhaustiveCheck}`);
        }
      }
      setFieldErrors(p => ({ ...p, [field]: error }));
      return !error;
    },
    [
      workEmail,
      password,
      confirmPassword,
      givenName,
      familyName,
      criterion1,
      criterion2,
      criterion3,
      criterion4,
      criterion5,
    ],
  );

  const disableSSO = isSigningUp || isVerifyingEmail;

  const onClickSSO = (provider: string) => () => {
    if (!isSigningUp && typeof window !== 'undefined') {
      setSignUpChannel(provider);
      if (provider !== 'email') {
        setIsSigningUp(true);
        getLoginRedirect(provider)
          .then(redirectUrl => {
            const landing = getLandingUrl() || `/${locale}${LANDING_URI}`;
            saveLandingUrl(landing);
            window.location.href = redirectUrl;
          })
          .catch(err => {
            handleError(err, alert);
            setIsSigningUp(false);
            setSignUpChannel('');
          });
      }
    }
  };
  const onClickBackPage: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    setSignUpChannel('');
    setVerifyingEmailSend(false);
    setFormData(DEFAULT_FORM_DATA);
    e.stopPropagation();
  };

  const onClickVerifyEmail = () => {
    setIsVerifyingEmail(true);
    const validations = (['workEmail', 'password', 'confirmPassword', 'givenName', 'familyName'] as const).map(field =>
      validateField(field),
    );
    if (!validations.every(Boolean)) {
      setIsVerifyingEmail(false);
      return;
    }

    const recaptchaValue = _reCaptchaRef.current.getValue();
    // _reCaptchaRef.current.reset();

    verifyRecapthaToken(recaptchaValue).then(({ success }) => {
      if (success) {
        verifyEmail({
          email: workEmail,
          firstName: givenName,
          lastName: familyName,
          password: btoa(password),
        })
          .then(() => {
            setVerifyingEmailSend(true);
          })
          .catch(err => {
            let showToaster = true;
            if (err.response && err.response.data) {
              if (err.response.data.error_detail.includes('is not a valid email address')) {
                setFieldErrors(p => ({ ...p, workEmail: invalidEmailLabel }));
                showToaster = false;
              } else if (
                err.response.data.error_detail.includes('Please use your work email address') ||
                err.response.data.error_detail.includes('Please use a work email instead')
              ) {
                setFieldErrors(p => ({ ...p, workEmail: intl.formatMessage({ id: 'signup.workEmailRequired' }) }));
                showToaster = false;
              }
            }
            if (showToaster) {
              handleError(err, alert);
            }
          })
          .finally(() => {
            setIsSigningUp(false);
            setIsVerifyingEmail(false);
          });
      } else {
        alert.error(intl.formatMessage({ id: 'signup.recaptchaFailed' }));
        setIsVerifyingEmail(false);
        return;
      }
    });
  };

  const responsiveLayout =
    ctx.breakpoint === 'sm'
      ? css`
          width: 100%;
        `
      : css`
          width: 400px;
          border-radius: 16px;
        `;

  const rootStyle = css`
    ${responsiveLayout};
    padding: 50px 35px 50px 35px;
    text-align: center;
    background: ${theme.colors.b6}e6;

    .subtitle {
      margin-left: 3px;
      margin-top: 16px;
      text-align: left;
    }
    .top-form {
      margin: 20px 0;
    }
    .MuiDivider-root {
      margin: 25px 0;
    }
    .bottom-form {
      margin: 20px 0;
      .MuiButtonBase-root {
        border-radius: 6px;
        text-transform: none;
        margin: 20px 0;
      }
      & > .login-error {
        color: ${theme.colors.s4};
        text-align: left;
        margin: 8px 0;
      }
      & .input {
        margin: 16px 0;
        text-align: left;
        .password-policy {
          margin-top: 0.5rem;
          margin-left: 8px;
          font-size: 12px;
          .policy-item-unmet {
            color: ${theme.colors.f1};
          }
          .policy-item-met {
            color: ${theme.colors.p2};
          }
        }
        .MuiFormControl-root {
          .input-label-with-icon {
            margin: -4px 4px;
            .MuiFormLabel-root {
              font-size: 16px;
              color: ${theme.colors.f1};
            }
          }
          .MuiInputBase-root {
            .MuiInputBase-input {
              border-radius: 6px;
              font-size: 0.75rem;
            }
          }
          &:error {
            .MuiInputBase-input {
              border-color: '#FF0F4B';
              .MuiFormLabel-root {
                color: ${theme.colors.error};
              }
            }
          }
        }
        .MuiFormControl-root {
          &:hover {
            .MuiInputBase-input {
              border-color: ${theme.colors.o6};
            }
          }
          width: 100%;
          .MuiInputBase-input {
            width: 100%;
          }
        }
      }
      & > .forgot-password {
        text-align: left;
        .forgot-password-prompt {
          margin-bottom: 8px;
        }
      }
    }

    .consent {
      width: 100%;
      min-width: max-content;
      text-align: left;
      display: table-footer-group;
      flex-direction: row;
      align-items: center;
      a {
        margin: 0 4px;
      }
    }

    .agree-terms-checkbox {
      width: 25px;
    }

    .recaptcha-row {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-row {
      margin: 10px 0;
      border-radius: 6px;
      .btn-content {
        width: 100%;
        padding: 4px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-spacer {
          flex: ${ctx.breakpoint === 'sm' ? 0 : 1};
        }
      }
      .img-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: right;
      }
      .btn-label {
        flex: 4;
        margin-left: 16px;
        text-align: left;
        color: ${theme.colors.b1};
        text-transform: none;
      }
      .btn-label-disabled {
        color: inherit;
      }
    }

    .btn-row-sso {
      border: 1px solid ${theme.colors.o2};
      &:hover {
        border-color: ${theme.colors.p2};
      }
    }

    .verify-email-desc {
      margin: 8px 0;
      text-align: left;
    }

    .register-user-email {
      a {
        margin-left: 4px;
      }
    }
  `;

  const mBtnStyle = {
    boxShadow: 'unset',
    background: 'white',
  };

  const view = useMemo(() => (signUpChannel === 'email' ? 'verify-email' : 'choose-channel'), [signUpChannel]);

  return (
    <div css={rootStyle}>
      <div id="logo">
        <img src="https://assets.savantlabs.io/brand/logo.svg" width="113px" />
      </div>

      {view === 'choose-channel' && (
        <Collapse in={!verifyingEmailSend}>
          <div className="subtitle text-t10">
            A <b>work email</b> or <b>LinkedIn profile</b> is required for sales and support reasons.
          </div>
          <div className="subtitle text-t10">
            {intl.formatMessage({ id: 'signup.alreadyUser' })}{' '}
            <a href={`/${locale}/login`}>{intl.formatMessage({ id: 'login.signIn' })}</a>
          </div>
          <div className="top-form">
            {SSO_PROVIDERS.map(provider => (
              <div key={provider} className="btn-row btn-row-sso">
                <MButton
                  variant="contained"
                  onClick={onClickSSO(provider)}
                  fullWidth
                  style={mBtnStyle}
                  disabled={disableSSO}
                >
                  <div className="btn-content">
                    <div className="btn-spacer" />
                    <div className="img-container">
                      {signUpChannel === provider ? (
                        <Loader size={24} />
                      ) : (
                        <img src={`https://assets.savantlabs.io/img/signin_${provider}.png`} height="20px" />
                      )}
                    </div>
                    <div className={`btn-label text-t9` + (disableSSO ? ' btn-label-disabled' : '')}>
                      {continueWithLabel.replace('{0}', getProviderName(provider))}
                    </div>
                    <div className="btn-spacer" />
                  </div>
                </MButton>
              </div>
            ))}
            <div className="btn-row btn-row-sso">
              <MButton
                variant="contained"
                onClick={onClickSSO('email')}
                fullWidth
                style={mBtnStyle}
                disabled={disableSSO}
              >
                <div className="btn-content">
                  <div className="btn-spacer" />
                  <div className="img-container">
                    <Icon variant={'outlined'} name="Email" color={disableSSO ? 'disabled' : 'secondary'} />
                  </div>
                  <div className={'btn-label text-t9' + (disableSSO ? ' btn-label-disabled' : '')}>
                    {continueWithLabel.replace('{0}', intl.formatMessage({ id: 'login.workingEmail' }))}
                  </div>
                  <div className="btn-spacer" />
                </div>
              </MButton>
            </div>
            <Footer showConsent />
          </div>
        </Collapse>
      )}

      {view === 'verify-email' && (
        <React.Fragment>
          <div className="subtitle text-t10">
            <a href="_blank" onClick={onClickBackPage}>
              {intl.formatMessage({ id: 'signup.backToPreviousPage' })}
            </a>
          </div>
          <form className="bottom-form" autoComplete="off">
            {!verifyingEmailSend ? (
              <React.Fragment>
                <div className="input">
                  <MInput
                    value={workEmail}
                    name="email"
                    onChange={e => setFormData(prev => ({ ...prev, workEmail: e.target.value }))}
                    onBlur={() => validateField('workEmail')}
                    label={intl.formatMessage({ id: 'login.workingEmail' }) + '*'}
                    placeholder=""
                    dataTestId="input-email-test-id"
                    required
                    disabled={isSigningUp}
                    error={Boolean(fieldErrors.workEmail)}
                    errorMsg={fieldErrors.workEmail}
                  />
                </div>
                <div className="input">
                  <MInput
                    value={givenName}
                    name="first-name"
                    onChange={e => setFormData(prev => ({ ...prev, givenName: e.target.value }))}
                    label={intl.formatMessage({ id: 'signup.givenName' }) + '*'}
                    placeholder=""
                    required
                    dataTestId="input-first-name-test-id"
                    disabled={isSigningUp}
                    onBlur={() => validateField('givenName')}
                    error={Boolean(fieldErrors.givenName)}
                    errorMsg={fieldErrors.givenName}
                  />
                </div>

                <div className="input">
                  <MInput
                    value={familyName}
                    name="last-name"
                    onChange={e => setFormData(prev => ({ ...prev, familyName: e.target.value }))}
                    label={intl.formatMessage({ id: 'signup.familyName' }) + '*'}
                    placeholder=""
                    required
                    dataTestId="input-last-name-test-id"
                    disabled={isSigningUp}
                    onBlur={() => validateField('familyName')}
                    error={Boolean(fieldErrors.familyName)}
                    errorMsg={fieldErrors.familyName}
                  />
                </div>
                <div className="input">
                  <MInput
                    value={password}
                    onChange={e => setFormData(prev => ({ ...prev, password: e.target.value }))}
                    name="password"
                    type="password"
                    required
                    label={intl.formatMessage({ id: 'login.password' }) + '*'}
                    placeholder=""
                    dataTestId="input-password-test-id"
                    disabled={isSigningUp}
                    onFocus={() => {
                      setFieldErrors(p => ({ ...p, password: '' }));
                      setShowPasswordRequirements(true);
                    }}
                    onBlur={() => {
                      validateField('password');
                      setShowPasswordRequirements(false);
                    }}
                    error={Boolean(fieldErrors.password)}
                    errorMsg={fieldErrors.password}
                  />
                  <Collapse in={showPasswordRequirements && Boolean(password)}>
                    <div className="password-policy">
                      {[criterion1, criterion2, criterion3, criterion4, criterion5].map(criterion => (
                        <div
                          key={criterion.message}
                          className={`policy-item policy-item-${criterion.isMet ? 'met' : 'unmet'}`}
                        >
                          {criterion.isMet && '✓ '}
                          {criterion.message}
                        </div>
                      ))}
                    </div>
                  </Collapse>
                </div>
                <div className="input">
                  <MInput
                    value={confirmPassword}
                    onChange={e => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                    name="confirmPassword"
                    type="password"
                    required
                    label={intl.formatMessage({ id: 'login.confirmPassword' }) + '*'}
                    placeholder=""
                    dataTestId="input-confirm-password-test-id"
                    disabled={isSigningUp}
                    onFocus={() => {
                      setFieldErrors(p => ({ ...p, confirmPassword: '' }));
                      setShowConfirmPasswordRequirements(true);
                    }}
                    onBlur={() => {
                      validateField('confirmPassword');
                      setShowConfirmPasswordRequirements(false);
                    }}
                    error={Boolean(fieldErrors.confirmPassword)}
                    errorMsg={fieldErrors.confirmPassword}
                  />
                  <Collapse in={showConfirmPasswordRequirements} className="password-policy">
                    <div
                      className={`policy-item policy-item-${
                        Boolean(confirmPassword) && confirmPassword === password ? 'met' : 'unmet'
                      }`}
                    >
                      {confirmPassword === password && '✓ '}
                      {intl.formatMessage({ id: 'signup.confirmPasswordCheck' })}
                    </div>
                  </Collapse>
                </div>
              </React.Fragment>
            ) : (
              <div className="verify-email-desc text-t10">
                {intl.formatMessage({ id: 'signup.verifyEmailDescription' })}
              </div>
            )}
            {!(isVerifyingEmail || verifyingEmailSend) && showReCAPTCHA && (
              <div className="recaptcha-row">
                <ReCAPTCHA sitekey={reCAPTCHASiteKey} ref={_reCaptchaRef} />
              </div>
            )}
            {isVerifyingEmail ? (
              <Loader size={24} />
            ) : (
              <Button
                label={
                  verifyingEmailSend
                    ? intl.formatMessage({ id: 'signup.resendEmail' })
                    : intl.formatMessage({ id: 'signup.verifyEmail' })
                }
                // Using onMouseDown instead of onClick because the validations
                // of the fields triggered by onBlur do state updates and cause
                // onClick not to be called
                onMouseDown={onClickVerifyEmail}
                design="primary"
              />
            )}
          </form>
        </React.Fragment>
      )}
    </div>
  );
};

export default SignUpForm;
