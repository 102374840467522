import { createContext } from 'react';
import { IntlShape } from 'react-intl';
import { Uploader, BasicContextProps } from '@savant-components/basic';
import { EditorContextProps } from '@savant-components/editor';
import {
  ConnectionsContextProps,
  SourcesContextProps,
  RecipeContextProps,
  UsersContextProps,
  WorkspaceContextProps,
  ExecutionsContextProps,
  TableCatalogContextProps,
  AuthenticationContextProps,
  ConnectorsContextProps,
  UsageLogContextProps,
  TemplateContextProps,
  ExecutionTask,
} from '@savant-components/catalog';
import { Engine, BuilderContextProps, AutomationContextProps } from '@savant-components/builder';

export type SignInContextProps = {
  orientation?: 'portrait' | 'landscape';
  breakpoint?: 'sm' | 'md' | 'lg';
};

export const SignInContext = createContext<SignInContextProps>({ orientation: 'landscape', breakpoint: 'lg' });

export function getBasicContext(intl: IntlShape): BasicContextProps {
  return {
    locale: intl.locale,
    messages: {
      all: intl.formatMessage({ id: 'basic.all' }),
      apply: intl.formatMessage({ id: 'basic.apply' }),
      search: intl.formatMessage({ id: 'basic.search' }),
      download: intl.formatMessage({ id: 'basic.download' }),
      edit: intl.formatMessage({ id: 'basic.edit' }),
      save: intl.formatMessage({ id: 'basic.save' }),
      saveload: intl.formatMessage({ id: 'basic.saveload' }),
      load: intl.formatMessage({ id: 'basic.load' }),
      cancel: intl.formatMessage({ id: 'basic.cancel' }),
      delete: intl.formatMessage({ id: 'basic.delete' }),
      archive: intl.formatMessage({ id: 'basic.archive' }),
      copy: intl.formatMessage({ id: 'basic.copy' }),
      copied: intl.formatMessage({ id: 'basic.copied' }),
      preview: intl.formatMessage({ id: 'basic.preview' }),
      configuration: intl.formatMessage({ id: 'basic.configuration' }),
      open: intl.formatMessage({ id: 'basic.open' }),
      close: intl.formatMessage({ id: 'basic.close' }),
      retry: intl.formatMessage({ id: 'basic.retry' }),
      add: intl.formatMessage({ id: 'basic.add' }),
      remove: intl.formatMessage({ id: 'basic.remove' }),
      back: intl.formatMessage({ id: 'basic.back' }),
      previous: intl.formatMessage({ id: 'basic.previous' }),
      next: intl.formatMessage({ id: 'basic.next' }),
      confirm: intl.formatMessage({ id: 'basic.confirm' }),
      expand: intl.formatMessage({ id: 'basic.expand' }),
      collapse: intl.formatMessage({ id: 'basic.collapse' }),
      connectors: intl.formatMessage({ id: 'basic.connectors' }),
      isNew: intl.formatMessage({ id: 'basic.isNew' }),
      applyFilter: intl.formatMessage({ id: 'basic.applyFilter' }),
      reset: intl.formatMessage({ id: 'basic.reset' }),
      dropzone: intl.formatMessage({ id: 'basic.dropzone' }),
      uploadFile: intl.formatMessage({ id: 'basic.uploadFile' }),
      upload: intl.formatMessage({ id: 'basic.upload' }),
      name: intl.formatMessage({ id: 'basic.name' }),
      description: intl.formatMessage({ id: 'basic.description' }),
      maxWords: intl.formatMessage({ id: 'basic.maxWords' }),
      maxLengthErrorMsg: intl.formatMessage({ id: 'basic.maxLengthErrorMsg' }),
      dataType: intl.formatMessage({ id: 'basic.dataType' }),
      type: intl.formatMessage({ id: 'basic.type' }),
      confirmDelete: intl.formatMessage({ id: 'basic.confirmDelete' }),
      confirmDelete2: intl.formatMessage({ id: 'basic.confirmDelete2' }),

      // >>>>> field metadata table >>>>>
      fillRate: intl.formatMessage({ id: 'basic.fillRate' }),
      // <<<<< field metadata table <<<<<
      // >>>>> edit column menu >>>>>
      sourceColumn: intl.formatMessage({ id: 'basic.sourceColumn' }),
      calculatedColumn: intl.formatMessage({ id: 'basic.calculatedColumn' }),
      sort: intl.formatMessage({ id: 'basic.sort' }),
      hide: intl.formatMessage({ id: 'basic.hide' }),
      rename: intl.formatMessage({ id: 'basic.rename' }),
      move: intl.formatMessage({ id: 'basic.move' }),
      moveLeft: intl.formatMessage({ id: 'basic.moveLeft' }),
      moveRight: intl.formatMessage({ id: 'basic.moveRight' }),
      addLeft: intl.formatMessage({ id: 'basic.addLeft' }),
      addRight: intl.formatMessage({ id: 'basic.addRight' }),
      argument: intl.formatMessage({ id: 'basic.argument' }),
      delimiter: intl.formatMessage({ id: 'basic.delimiter' }),
      rowOffset: intl.formatMessage({ id: 'basic.rowOffset' }),
      // <<<<< edit column menu <<<<<
      // <<<<< no data <<<<<
      noDataTitle: intl.formatMessage({ id: 'basic.noDataTitle' }),
      noDataDescription: intl.formatMessage({ id: 'basic.noDataDescription' }),
      runs: intl.formatMessage({ id: 'basic.runs' }),
      triggers: intl.formatMessage({ id: 'basic.triggers' }),
      goToSchedule: intl.formatMessage({ id: 'basic.goToSchedule' }),
      manageAutomation: intl.formatMessage({ id: 'basic.manageAutomation' }),
      botAutomationTitle: intl.formatMessage({ id: 'basic.botAutomationTitle' }),
      emailTrigger: intl.formatMessage({ id: 'basic.emailTrigger' }),
      appUrl: intl.formatMessage({ id: 'basic.appUrl' }),
      webhook: intl.formatMessage({ id: 'basic.webhook' }),
      email: intl.formatMessage({ id: 'basic.email' }),
      webhookUrl: intl.formatMessage({ id: 'basic.webhookUrl' }),
      // <<<<< no data <<<<<
      key: intl.formatMessage({ id: 'basic.key' }),
      value: intl.formatMessage({ id: 'basic.value' }),
      details: intl.formatMessage({ id: 'basic.details' }),
      at: intl.formatMessage({ id: 'basic.at' }),
      every: intl.formatMessage({ id: 'basic.every' }),
    },
  };
}

function getCatalogContext(intl: IntlShape): TableCatalogContextProps {
  return {
    messages: {
      connector: intl.formatMessage({ id: 'app.connector' }),
      connection: intl.formatMessage({ id: 'app.connection' }),
      recipe: intl.formatMessage({ id: 'app.analysis' }),
      recipes: intl.formatMessage({ id: 'app.analyses' }),
      bot: intl.formatMessage({ id: 'app.bot' }),
      bots: intl.formatMessage({ id: 'app.bots' }),
      source: intl.formatMessage({ id: 'app.source' }),
      status: intl.formatMessage({ id: 'app.status' }),
      state: intl.formatMessage({ id: 'app.state' }),
      alerts: intl.formatMessage({ id: 'app.alerts' }),
      placeholder: intl.formatMessage({ id: 'app.placeholder' }),
      createdTime: intl.formatMessage({ id: 'app.createdTime' }),
      createdBy: intl.formatMessage({ id: 'app.createdBy' }),
      modifiedTime: intl.formatMessage({ id: 'app.modifiedTime' }),
      owner: intl.formatMessage({ id: 'app.owner' }),
      workspace: intl.formatMessage({ id: 'app.workspace' }),
      workspaceAdmin: intl.formatMessage({ id: 'app.workspaceAdmin' }),
      workspaceMember: intl.formatMessage({ id: 'app.workspaceMember' }),
      srcConnectors: intl.formatMessage({ id: 'app.srcConnectors' }),
      dstConnectors: intl.formatMessage({ id: 'app.dstConnectors' }),
      moveToFolder: intl.formatMessage({ id: 'app.moveToFolder' }),
      moveItemToFolder: intl.formatMessage({ id: 'app.moveItemToFolder' }),
      selectFolder: intl.formatMessage({ id: 'app.selectFolder' }),
      addFolder: intl.formatMessage({ id: 'app.addFolder' }),
      updateFolder: intl.formatMessage({ id: 'app.updateFolder' }),
      folderName: intl.formatMessage({ id: 'app.folderName' }),
      folderAlreadyExists: intl.formatMessage({ id: 'app.folderAlreadyExists' }),
      folderNameMaxCharactorsError: intl.formatMessage({ id: 'app.folderNameMaxCharactorsError' }),
      folderNameRequired: intl.formatMessage({ id: 'app.folderNameRequired' }),
      deleteFolder: intl.formatMessage({ id: 'app.deleteFolder' }),
      tags: intl.formatMessage({ id: 'app.tags' }),
    },
  };
}

function getConnectorsContext(intl: IntlShape): ConnectorsContextProps {
  return {
    messages: {
      all: intl.formatMessage({ id: 'app.connector.all' }),
      connectedSystem: intl.formatMessage({ id: 'app.source.connectedSystem' }),
      createASystem: intl.formatMessage({ id: 'app.connector.createASystem' }),
      catalog: intl.formatMessage({ id: 'app.source.catalog' }),
      schema: intl.formatMessage({ id: 'app.source.schema' }),
      object: intl.formatMessage({ id: 'app.source.object' }),
      database: intl.formatMessage({ id: 'app.database' }),
      table: intl.formatMessage({ id: 'app.table' }),
      tab: intl.formatMessage({ id: 'app.tab' }),
      fields: intl.formatMessage({ id: 'app.fields' }),
      files: intl.formatMessage({ id: 'app.connector.files' }),
      databases: intl.formatMessage({ id: 'app.connector.databases' }),
      sales: intl.formatMessage({ id: 'app.connector.sales' }),
      marketing: intl.formatMessage({ id: 'app.connector.marketing' }),
      supplyChain: intl.formatMessage({ id: 'app.connector.supplyChain' }),
      finance: intl.formatMessage({ id: 'app.connector.finance' }),
      communication: intl.formatMessage({ id: 'app.connector.communication' }),
      it: intl.formatMessage({ id: 'app.connector.it' }),
      analytics: intl.formatMessage({ id: 'app.connector.analytics' }),
    },
  };
}

export function getAuthenticationContext(intl: IntlShape): AuthenticationContextProps {
  return {
    messages: {
      expiresAt: intl.formatMessage({ id: 'app.connection.expiresAt' }),
      addConnection: intl.formatMessage({ id: 'app.connection.newConnection' }),
      chooseConnector: intl.formatMessage({ id: 'app.chooseConnector' }),
      chooseConnection: intl.formatMessage({ id: 'app.selectConnection' }),
      authenticate: intl.formatMessage({ id: 'app.connection.authenticate' }),
      changeAuthentication: intl.formatMessage({ id: 'app.connection.changeAuthentication' }),
      configureConnection: intl.formatMessage({ id: 'app.connection.configureConnection' }),
      authenticatedUntil: intl.formatMessage({ id: 'app.connection.authenticatedUntil' }),
      nameDesc: intl.formatMessage({ id: 'app.nameDesc' }),
      server: intl.formatMessage({ id: 'app.connector.server' }),
      port: intl.formatMessage({ id: 'app.connector.port' }),
      warehouse: intl.formatMessage({ id: 'app.connector.warehouse' }),
      role: intl.formatMessage({ id: 'app.connector.role' }),
      database: intl.formatMessage({ id: 'app.connector.database' }),
      username: intl.formatMessage({ id: 'app.connector.username' }),
      password: intl.formatMessage({ id: 'app.connector.password' }),
      useSSL: intl.formatMessage({ id: 'app.connector.useSSL' }),
      serverSslCA: intl.formatMessage({ id: 'app.connector.serverSslCA' }),
      apiKey: intl.formatMessage({ id: 'app.connector.apiKey' }),
      tableName: intl.formatMessage({ id: 'app.connector.tableName' }),
      privateKey: intl.formatMessage({ id: 'app.connector.privateKey' }),
      passphrase: intl.formatMessage({ id: 'app.connector.passphrase' }),
      sshTunnel: intl.formatMessage({ id: 'app.connector.sshTunnel' }),
      transferMode: intl.formatMessage({ id: 'app.connector.transferMode' }),
      accessKey: intl.formatMessage({ id: 'app.connector.accessKey' }),
      secretKey: intl.formatMessage({ id: 'app.connector.secretKey' }),
      region: intl.formatMessage({ id: 'app.connector.region' }),
      roleArn: intl.formatMessage({ id: 'app.connector.roleArn' }),
      s3StagingDirectory: intl.formatMessage({ id: 'app.connector.s3StagingDirectory' }),
      reAuthenticate: intl.formatMessage({ id: 'app.connector.reAuthenticate' }),
      headers: intl.formatMessage({ id: 'app.connector.headers' }),
      nameTooLong: intl.formatMessage({ id: 'app.connector.nameTooLong' }),
      environment: intl.formatMessage({ id: 'app.connector.environment' }),
      numberOnly: intl.formatMessage({ id: 'app.connector.numberOnly' }),
      jsonValidationMessage: intl.formatMessage({ id: 'app.connector.jsonValidationMessage' }),
      genericApiAuthForm: {
        method: 'GET',
        rawData: { label: '', placeholder: '' },
        url: 'URL',
        jsonPath: {
          label: 'JSON Path',
          placeholder: 'JSON path to the data array, e.g. ".results"',
        },
        resultFormat: 'Result Format',
        runOption: 'Run Option',
        rateLimiting: 'Use Rate Limiting',
        quota: {
          label: 'Quota',
          placeholder: 'Number of requests in a period.',
        },
        period: 'Period',
        pagination: 'Use Pagination',
        offset: {
          label: 'Offset Paramter',
          help: 'The URL parameter for offset (page start).',
        },
        startingOffset: {
          label: 'Starting Offset',
          help: 'The offset value for the first page.',
        },
        page: {
          label: 'Page Paramter',
          help: 'The URL parameter for page (page number).',
        },
        startingPage: {
          label: 'Starting Page',
          help: 'The page number of the first page.',
        },
        limit: {
          label: 'Limit Paramter',
          help: 'The URL parameter for limit (page size).',
        },
        pageSize: {
          label: 'Page Size',
          help: 'Number of records in each page.',
        },
        nextPageUrlJsonPath: {
          label: 'JSON Path to Next Page URL',
          placeholder: 'e.g. ".pathTo.nextPage"',
        },
        rfc: {
          label: 'Next Rel',
          placeholder: 'The "rel" value for the link header pointing to the next page',
        },
      },
    },
  };
}

export function getConnectionContext(intl: IntlShape): ConnectionsContextProps {
  return {
    messages: {
      ...getCatalogContext(intl).messages,
      ...getConnectorsContext(intl).messages,
      expiresAt: intl.formatMessage({ id: 'app.connection.expiresAt' }),
      addSystem: intl.formatMessage({ id: 'app.connection.newConnection' }),
      addConnection: intl.formatMessage({ id: 'app.connection.newConnection' }),
      deleteConnection: intl.formatMessage({ id: 'app.connection.deleteConnection' }),
      chooseConnector: intl.formatMessage({ id: 'app.chooseConnector' }),
      chooseConnection: intl.formatMessage({ id: 'app.selectConnection' }),
      authenticate: intl.formatMessage({ id: 'app.connection.authenticate' }),
      changeAuthentication: intl.formatMessage({ id: 'app.connection.changeAuthentication' }),
      configureConnection: intl.formatMessage({ id: 'app.connection.configureConnection' }),
      authenticatedUntil: intl.formatMessage({ id: 'app.connection.authenticatedUntil' }),
      nameDesc: intl.formatMessage({ id: 'app.nameDesc' }),
      server: intl.formatMessage({ id: 'app.connector.server' }),
      port: intl.formatMessage({ id: 'app.connector.port' }),
      warehouse: intl.formatMessage({ id: 'app.connector.warehouse' }),
      database: intl.formatMessage({ id: 'app.connector.database' }),
      username: intl.formatMessage({ id: 'app.connector.username' }),
      password: intl.formatMessage({ id: 'app.connector.password' }),
      useSSL: intl.formatMessage({ id: 'app.connector.useSSL' }),
      sslServerCert: intl.formatMessage({ id: 'app.connector.serverSslCA' }),
      apiKey: intl.formatMessage({ id: 'app.connector.apiKey' }),
      tableName: intl.formatMessage({ id: 'app.connector.tableName' }),
      addDestination: intl.formatMessage({ id: 'app.connection.addDestination' }),
      tools: intl.formatMessage({ id: 'app.connection.tools' }),
      noDataTitle: intl.formatMessage({ id: 'app.connection.noDataTitle' }),
      noDataDescription: intl.formatMessage({ id: 'app.connection.noDataDescription' }),
      blankStateTitle: intl.formatMessage({ id: 'app.connection.blankStateTitle' }),
      blankStateDescription: intl.formatMessage({ id: 'app.connection.blankStateDescription' }),
      analyses: intl.formatMessage({ id: 'app.analyses' }),
    },
  };
}

export function getSourceContext(intl: IntlShape): SourcesContextProps {
  return {
    messages: {
      ...getCatalogContext(intl).messages,
      ...getConnectorsContext(intl).messages,
      sampleRows: intl.formatMessage({ id: 'app.sampleRows' }),
      totalRows: intl.formatMessage({ id: 'app.totalRows' }),
      addSource: intl.formatMessage({ id: 'app.source.newSource' }),
      deleteSource: intl.formatMessage({ id: 'app.source.deleteSource' }),
      fileName: intl.formatMessage({ id: 'app.source.fileName' }),
      fileSize: intl.formatMessage({ id: 'app.source.fileSize' }),
      changeFile: intl.formatMessage({ id: 'app.sourcec.changeFile' }),
      chooseConnector: intl.formatMessage({ id: 'app.chooseConnector' }),
      connect: intl.formatMessage({ id: 'app.connect' }),
      authenticate: intl.formatMessage({ id: 'app.connection.authenticate' }),
      chooseConnection: intl.formatMessage({ id: 'app.selectConnection' }),
      addConnectionMsg: '',
      addConnectionBtn: intl.formatMessage({ id: 'app.connection.addConnection' }),
      chooseSource: intl.formatMessage({ id: 'app.source.chooseSource' }),
      uploadFile: intl.formatMessage({ id: 'app.source.uploadFile' }),
      configureSource: intl.formatMessage({ id: 'app.source.configureSource' }),
      chooseCatalog: intl.formatMessage({ id: 'app.source.chooseCatalog' }),
      chooseSchema: intl.formatMessage({ id: 'app.source.chooseSchema' }),
      chooseObject: intl.formatMessage({ id: 'app.source.chooseObject' }),
      filter: intl.formatMessage({ id: 'app.source.filter' }),
      fields: intl.formatMessage({ id: 'app.source.fields' }),
      nameDesc: intl.formatMessage({ id: 'app.nameDesc' }),
      uploadFileSource: intl.formatMessage({ id: 'app.source.uploadFileSource' }),
      cursor: intl.formatMessage({ id: 'app.source.cursor' }),
      fileType: intl.formatMessage({ id: 'app.source.fileType' }),
      skipRows: intl.formatMessage({ id: 'app.source.skipRows' }),
      skipRowsTooltip: intl.formatMessage({ id: 'app.source.skipRowsTooltip' }),
      numberOfFiles: intl.formatMessage({ id: 'app.source.numberOfFiles' }),
      fetchBy: intl.formatMessage({ id: 'app.source.fetchBy' }),
      folderLink: intl.formatMessage({ id: 'app.source.folderLink' }),
      refreshTabs: intl.formatMessage({ id: 'app.source.refreshTabs' }),
      folderURLError: intl.formatMessage({ id: 'app.source.folderURLError' }),
      // fileParserPropsError: intl.formatMessage({ id: 'app.source.fileParserPropsError' }),
      fileLink: intl.formatMessage({ id: 'app.source.fileLink' }),
      fileURL: intl.formatMessage({ id: 'app.source.fileURL' }),
      folderURL: intl.formatMessage({ id: 'app.source.folderURL' }),
      fileURLError: intl.formatMessage({ id: 'app.source.fileURLError' }),
      filePattern: intl.formatMessage({ id: 'app.source.filePattern' }),
      filePatternError: intl.formatMessage({ id: 'app.source.filePatternError' }),
      tab: intl.formatMessage({ id: 'app.source.tab' }),
      numberOfTabs: intl.formatMessage({ id: 'app.source.tabSettings' }),
      tabPattern: intl.formatMessage({ id: 'app.source.tabPattern' }),
      headerLess: intl.formatMessage({ id: 'app.source.headerLess' }),
      includeMetadata: intl.formatMessage({ id: 'app.source.includeMetadata' }),
      includeCreatedTime: intl.formatMessage({ id: 'app.source.includeCreatedTime' }),
      includeLastModifiedTime: intl.formatMessage({ id: 'app.source.includeLastModifiedTime' }),
      includePath: intl.formatMessage({ id: 'app.source.includePath' }),
      useLatestFile: intl.formatMessage({ id: 'app.source.useLatestFile' }),
      includeFutureColumnLabel: intl.formatMessage({ id: 'app.source.includeFutureColumnLabel' }),
      includeFutureColumnInfo: intl.formatMessage({ id: 'app.source.includeFutureColumnInfo' }),
      tabPatternError: intl.formatMessage({ id: 'app.source.tabPatternError' }),
      compression: intl.formatMessage({ id: 'app.source.compression' }),
      upload: intl.formatMessage({ id: 'app.source.upload' }),
      emptyTree: intl.formatMessage({ id: 'app.source.emptyTree' }),
      noSlectedObject: intl.formatMessage({ id: 'app.source.noSlectedObject' }),
      noSlectedFile: intl.formatMessage({ id: 'app.source.noSlectedFile' }),
      noFieldSelected: intl.formatMessage({ id: 'app.source.noFieldSelected' }),
      noDataTitle: intl.formatMessage({ id: 'app.source.noDataTitle' }),
      noDataDescription: intl.formatMessage({ id: 'app.source.noDataDescription' }),
      blankStateTitle: intl.formatMessage({ id: 'app.source.blankStateTitle' }),
      blankStateDescription: intl.formatMessage({ id: 'app.source.blankStateDescription' }),
      reSync: intl.formatMessage({ id: 'app.source.reSync' }),
      reSample: intl.formatMessage({ id: 'app.source.reSample' }),
      nameTooLong: intl.formatMessage({ id: 'app.source.nameTooLong' }),
      dropzone: intl.formatMessage({ id: 'basic.dropzone' }),
      qlQueryLabelPlaceholder: intl.formatMessage({ id: 'app.source.qlQueryLabelPlaceholder' }),
      gaqlQueryLabelPlaceholder: intl.formatMessage({ id: 'app.source.gaqlQueryLabelPlaceholder' }),
      delimiter: intl.formatMessage({ id: 'app.source.delimiter' }),
      qualifier: intl.formatMessage({ id: 'app.source.qualifier' }),
      replaceFile: intl.formatMessage({ id: 'app.source.replaceFile' }),
      dataPullWindow: intl.formatMessage({ id: 'app.source.dataPullWindow' }),
      daysToSync: intl.formatMessage({ id: 'app.source.daysToSync' }),
      headerMasks: intl.formatMessage({ id: 'app.source.headerMasks' }),
    },
  };
}

export function getExecutionContext(intl: IntlShape): ExecutionsContextProps {
  return {
    messages: {
      ...getCatalogContext(intl).messages,
      runName: intl.formatMessage({ id: 'app.executuion.runName' }),
      runId: intl.formatMessage({ id: 'app.executuion.runId' }),
      phase: intl.formatMessage({ id: 'app.executuion.phase' }),
      progress: intl.formatMessage({ id: 'app.executuion.progress' }),
      startedAt: intl.formatMessage({ id: 'app.executuion.startedAt' }),
      finishedAt: intl.formatMessage({ id: 'app.executuion.finishedAt' }),
      blankStateTitle: intl.formatMessage({ id: 'app.executuion.blankStateTitle' }),
      blankStateDescription: intl.formatMessage({ id: 'app.executuion.blankStateDescription' }),
      saveAsTemplate: intl.formatMessage({ id: 'app.executuion.saveAsTemplate' }),
      createNewTemlate: intl.formatMessage({ id: 'app.executuion.createNewTemlate' }),
      updateExistingTemplate: intl.formatMessage({ id: 'app.executuion.updateExistingTemplate' }),
      templateName: intl.formatMessage({ id: 'app.executuion.templateName' }),
      selectTemplate: intl.formatMessage({ id: 'app.executuion.selectTemplate' }),
      editExecutionAction: intl.formatMessage({ id: 'app.executuion.editExecutionAction' }),
      downloadReportAction: intl.formatMessage({ id: 'app.executuion.downloadReportAction' }),
      auditRunConfirmAction: intl.formatMessage({ id: 'app.executuion.auditRunConfirmAction' }),
      auditRunFormLabel: intl.formatMessage({ id: 'app.executuion.auditRunFormLabel' }),
      editExecutionTitle: intl.formatMessage({ id: 'app.executuion.editExecutionTitle' }),
    },
  };
}

export function getRecipeContext(intl: IntlShape): RecipeContextProps {
  return {
    messages: {
      ...getCatalogContext(intl).messages,
      addRecipe: intl.formatMessage({ id: 'app.recipe.newRecipe' }),
      deleteRecipe: intl.formatMessage({ id: 'app.recipe.deleteRecipe' }),
      lastRunDate: intl.formatMessage({ id: 'app.recipe.lastRunDate' }),
      blankStateTitle: intl.formatMessage({ id: 'app.recipe.blankStateTitle' }),
      blankStateDescription: intl.formatMessage({ id: 'app.recipe.blankStateDescription' }),
      parameterDataType: intl.formatMessage({ id: 'app.recipe.parameterDataType' }),
      name: intl.formatMessage({ id: 'app.recipe.name' }),
      label: intl.formatMessage({ id: 'app.recipe.label' }),
      defaultValue: intl.formatMessage({ id: 'app.recipe.defaultValue' }),
      dataset: intl.formatMessage({ id: 'basic.source' }),
      valueField: intl.formatMessage({ id: 'app.recipe.valueField' }),
      labelField: intl.formatMessage({ id: 'app.recipe.labelField' }),
      labelHelp: intl.formatMessage({ id: 'app.recipe.labelHelp' }),
      values: intl.formatMessage({ id: 'app.recipe.values' }),
      labels: intl.formatMessage({ id: 'app.recipe.labels' }),
      staticProviderLabel: intl.formatMessage({ id: 'app.recipe.staticProviderLabel' }),
      datasetProviderLabel: intl.formatMessage({ id: 'app.recipe.datasetProviderLabel' }),
      dependencies: intl.formatMessage({ id: 'app.recipe.dependencies' }),
      runNow: intl.formatMessage({ id: 'builder.runNow' }),
      editAnalysis: intl.formatMessage({ id: 'app.recipe.editAnalysis' }),
      importJson: intl.formatMessage({ id: 'app.recipe.importJson' }),
      importJsonError: intl.formatMessage({ id: 'app.recipe.importJsonError' }),
      exportJson: intl.formatMessage({ id: 'app.recipe.exportJson' }),
      hidden: intl.formatMessage({ id: 'app.recipe.hidden' }),
    },
  };
}

export function getTemplateContext(intl: IntlShape): TemplateContextProps {
  return {
    messages: {
      ...getCatalogContext(intl).messages,
      version: intl.formatMessage({ id: 'app.version' }),
      publisher: intl.formatMessage({ id: 'app.template.publisher' }),
      template: intl.formatMessage({ id: 'app.template' }),
      open: intl.formatMessage({ id: 'app.template.open' }),
      learnMore: intl.formatMessage({ id: 'app.template.learnMore' }),
      listed: intl.formatMessage({ id: 'app.template.listed' }),
      list: intl.formatMessage({ id: 'app.template.list' }),
      unlist: intl.formatMessage({ id: 'app.template.unlist' }),
      editTemplate: intl.formatMessage({ id: 'app.template.editTemplate' }),
      getInstallLink: intl.formatMessage({ id: 'app.template.getInstallLink' }),
      installLink: intl.formatMessage({ id: 'app.template.installLink' }),
      helpUrl: intl.formatMessage({ id: 'app.template.helpUrl' }),
    },
  };
}

export function getUsersContext(intl: IntlShape): UsersContextProps {
  return {
    messages: {
      ...getCatalogContext(intl).messages,
      addUser: intl.formatMessage({ id: 'app.user.addMember' }),
      deleteUser: 'Remove User',
      joinAsAdmin: intl.formatMessage({ id: 'app.user.joinAsAdmin' }),
      user: intl.formatMessage({ id: 'app.user' }),
      role: intl.formatMessage({ id: 'app.role' }),
      email: intl.formatMessage({ id: 'app.email' }),
      removeConfirm: intl.formatMessage({ id: 'app.user.removeConfirm' }),
    },
  };
}

export function getWorkspaceContext(intl: IntlShape): WorkspaceContextProps {
  return {
    messages: {
      ...getCatalogContext(intl).messages,
      addWorkspace: intl.formatMessage({ id: 'app.workspace.addWorkspace' }),
      deleteWorkspace: 'Delete Workspace',
      members: intl.formatMessage({ id: 'app.workspace.members' }),
      archiveConfirm: intl.formatMessage({ id: 'app.workspace.archiveConfirm' }),
      newWorkspaceName: intl.formatMessage({ id: 'app.workspace.newWorkspaceName' }),
    },
  };
}

export function getUsageLogContext(intl: IntlShape): UsageLogContextProps {
  return {
    messages: {
      ...getCatalogContext(intl).messages,
      downloadCSV: intl.formatMessage({ id: 'app.usage.downloadCSV' }),
      startedAt: intl.formatMessage({ id: 'app.usage.startedAt' }),
      currentlyUsing: intl.formatMessage({ id: 'app.usage.currentlyUsing' }),
      active: intl.formatMessage({ id: 'app.usage.active' }),
      ran: intl.formatMessage({ id: 'app.usage.ran' }),
      analyzed: intl.formatMessage({ id: 'app.usage.analyzed' }),
      consumed: intl.formatMessage({ id: 'app.usage.consumed' }),
      botName: intl.formatMessage({ id: 'app.usage.botNamee' }),
      botOwner: intl.formatMessage({ id: 'app.usage.botOwner' }),
      workspace: intl.formatMessage({ id: 'app.workspace' }),
      runType: intl.formatMessage({ id: 'app.usage.runType' }),
      runId: intl.formatMessage({ id: 'app.executuion.runId' }),
      rows: intl.formatMessage({ id: 'app.usage.rows' }),
      processedRows: intl.formatMessage({ id: 'app.usage.processedRows' }),
    },
  };
}

function getEditorContext(intl: IntlShape): EditorContextProps {
  return {
    messages: {
      searchFunction: intl.formatMessage({ id: 'editor.searchFunction' }),
    },
  };
}

export function getBuilderContext(
  intl: IntlShape,
  engine: Engine,
  uploader: Uploader,
  tasks: ExecutionTask[],
): BuilderContextProps {
  return {
    tasks,
    messages: {
      addDestination: intl.formatMessage({ id: 'builder.addDestination' }),
      addModule: intl.formatMessage({ id: 'builder.addModule' }),
      addSource: intl.formatMessage({ id: 'builder.addSource' }),
      canvas: intl.formatMessage({ id: 'builder.canvas' }),
      moreActions: intl.formatMessage({ id: 'builder.moreActions' }),
      runBot: intl.formatMessage({ id: 'builder.runBot' }),
      runbotDesc: intl.formatMessage({ id: 'builder.runbotDesc' }),
      runHistory: intl.formatMessage({ id: 'builder.runHistory' }),
      runNow: intl.formatMessage({ id: 'builder.runBot' }),
      runTest: intl.formatMessage({ id: 'builder.runTest' }),
      runtestDesc: intl.formatMessage({ id: 'builder.runtestDesc' }),
      saving: intl.formatMessage({ id: 'builder.saving' }),
      schedule: intl.formatMessage({ id: 'builder.schedule' }),
      testNow: intl.formatMessage({ id: 'builder.runTest' }),
      testRun: intl.formatMessage({ id: 'builder.runTest' }),
      viewBotRuns: intl.formatMessage({ id: 'builder.viewBotRuns' }),
      viewTests: intl.formatMessage({ id: 'builder.viewTests' }),
    },
    node: {
      messages: {
        icon: {
          unsaved: intl.formatMessage({ id: 'builder.node.icon.unsaved' }),
          not_configured: intl.formatMessage({ id: 'builder.node.icon.not_configured' }),
        },
        error: {
          invalidConfig: intl.formatMessage({ id: 'builder.node.error.invalidConfig' }),
        },
        pivotKeyField: intl.formatMessage({ id: 'builder.node.pivot.pivotField' }),
        pivotValueField: intl.formatMessage({ id: 'builder.node.pivot.valueField' }),
        pivotAggregation: intl.formatMessage({ id: 'builder.node.pivot.aggregation' }),
        keepFields: intl.formatMessage({ id: 'builder.node.depivot.keepFields' }),
        ignoreFields: intl.formatMessage({ id: 'builder.node.depivot.ignoreFields' }),
        nameField: intl.formatMessage({ id: 'builder.node.depivot.nameField' }),
        valueField: intl.formatMessage({ id: 'builder.node.depivot.valueField' }),
        nameTooLong: intl.formatMessage({ id: 'builder.node.error.nameTooLong' }),
        numSamples: intl.formatMessage({ id: 'builder.node.numSamples' }),

        renamedSourceFields: intl.formatMessage({ id: 'builder.node.renamedSourceFields' }),
        deleteRenameWarningMsg: intl.formatMessage({ id: 'builder.node.deleteRenameWarningMsg' }),
        useWindowError: intl.formatMessage({ id: 'builder.node.useWindowError' }),
        dataGridFooterhelp: intl.formatMessage({ id: 'builder.node.dataGridFooterhelp' }),
        refreshSample: intl.formatMessage({ id: 'builder.node.refreshSample' }),
        calcFields: intl.formatMessage({ id: 'builder.node.calcFields' }),
        manageFields: intl.formatMessage({ id: 'builder.node.manageFields' }),
        showAll: intl.formatMessage({ id: 'builder.node.showAll' }),
        hideAll: intl.formatMessage({ id: 'builder.node.hideAll' }),

        moveField: intl.formatMessage({ id: 'builder.node.moveField' }),
        toTheFirst: intl.formatMessage({ id: 'builder.node.toTheFirst' }),
        toTheLast: intl.formatMessage({ id: 'builder.node.toTheLast' }),
        toTheLeft: intl.formatMessage({ id: 'builder.node.toTheLeft' }),
        toTheRight: intl.formatMessage({ id: 'builder.node.toTheRight' }),
        toBefore: intl.formatMessage({ id: 'builder.node.toBefore' }),
        toAfter: intl.formatMessage({ id: 'builder.node.toAfter' }),

        duplicateNameErrorMsg: intl.formatMessage({ id: 'builder.node.duplicateNameErrorMsg' }),
        renameField: intl.formatMessage({ id: 'builder.node.renameField' }),
        oldFieldName: intl.formatMessage({ id: 'builder.node.oldFieldName' }),
        newFieldName: intl.formatMessage({ id: 'builder.node.newFieldName' }),

        deleteField: intl.formatMessage({ id: 'builder.node.deleteField' }),
        revertField: intl.formatMessage({ id: 'builder.node.revertField' }),
        revertFieldDesc: intl.formatMessage({ id: 'builder.node.revertFieldDesc' }),
        revertFieldDescUpdated: intl.formatMessage({ id: 'builder.node.revertFieldDescUpdated' }),
        confirmDeleteColumn: intl.formatMessage({ id: 'builder.node.confirmDeleteColumn' }),

        editCalculatedField: intl.formatMessage({ id: 'builder.node.editCalculatedField' }),
        fieldName: intl.formatMessage({ id: 'builder.node.fieldName' }),
        expression: intl.formatMessage({ id: 'builder.node.expression' }),
        builder: intl.formatMessage({ id: 'builder.node.builder' }),
        nlpAssistant: intl.formatMessage({ id: 'builder.node.nlpAssistant' }),
        calculation: intl.formatMessage({ id: 'builder.node.calculation' }),
        dense: intl.formatMessage({ id: 'builder.node.dense' }),
        backfill: intl.formatMessage({ id: 'builder.node.backfill' }),
        percent: intl.formatMessage({ id: 'builder.node.percent' }),
        partition: intl.formatMessage({ id: 'builder.node.partition' }),
        sort: intl.formatMessage({ id: 'builder.node.sort' }),
        removeNode: intl.formatMessage({ id: 'builder.node.removeNode' }),

        inputColumn: intl.formatMessage({ id: 'builder.node.inputColumn' }),
        inputColumnDesc: intl.formatMessage({ id: 'builder.node.inputColumnDesc' }),
        separator: intl.formatMessage({ id: 'builder.node.separator' }),
        separatorDesc: intl.formatMessage({ id: 'builder.node.separatorDesc' }),

        jsonModeDesc: intl.formatMessage({ id: 'builder.node.jsonModeDesc' }),
        jsonPathToSplit: intl.formatMessage({ id: 'builder.node.jsonPathToSplit' }),
        keepOriginalField: intl.formatMessage({ id: 'builder.node.keepOriginalField' }),
        keepOriginalFieldDesc: intl.formatMessage({ id: 'builder.node.keepOriginalFieldDesc' }),
        aggregationWindow: intl.formatMessage({ id: 'builder.node.aggregationWindow' }),
        from: intl.formatMessage({ id: 'builder.node.from' }),
        to: intl.formatMessage({ id: 'builder.node.to' }),

        hiddenFields: intl.formatMessage({ id: 'builder.node.hiddenFields' }),
        edit: intl.formatMessage({ id: 'basic.edit' }),
        column: intl.formatMessage({ id: 'builder.node.column' }),

        nlpPromptInEdit: intl.formatMessage({ id: 'builder.node.nlpPromptInEdit' }),
        askNLP: intl.formatMessage({ id: 'builder.node.askNLP' }),
        askAgain: intl.formatMessage({ id: 'builder.node.askAgain' }),
        nlpSuggestExpression: intl.formatMessage({ id: 'builder.node.nlpSuggestExpression' }),
        nlpError: intl.formatMessage({ id: 'builder.node.nlpError' }),
        totalRows: intl.formatMessage({ id: 'builder.node.totalRows' }),
      },
      engine,
      uploader,
    },
    titleBar: {
      messages: {
        save: intl.formatMessage({ id: 'basic.save' }),
        editFlow: intl.formatMessage({ id: 'builder.titleBar.editFlow' }),
        sources: intl.formatMessage({ id: 'app.source' }),
        ingredients: intl.formatMessage({ id: 'app.ingredient' }),
        destinations: intl.formatMessage({ id: 'app.destination' }),
        testRun: intl.formatMessage({ id: 'builder.titleBar.testRun' }),
        executionHistory: intl.formatMessage({ id: 'builder.titleBar.executionHistory' }),
        submitted: intl.formatMessage({ id: 'builder.submitted' }),
        existRunningExecution: intl.formatMessage({ id: 'builder.titleBar.existRunningExecution' }),
      },
    },
    downloader: {
      messages: {
        dowloadFullData: intl.formatMessage({ id: 'builder.downloader.dowloadFullData' }),
        failedToGenerate: intl.formatMessage({ id: 'builder.downloader.failedToGenerate' }),
        generating: intl.formatMessage({ id: 'builder.downloader.generating' }),
      },
    },
    layout: {
      messages: {
        organizeLabel: intl.formatMessage({ id: 'builder.layout.organize' }),
        fitViewLabel: intl.formatMessage({ id: 'builder.layout.fitView' }),
        lockLabel: intl.formatMessage({ id: 'builder.layout.lock' }),
        unlockLabel: intl.formatMessage({ id: 'builder.layout.unlock' }),
      },
    },
    configurer: {
      messages: {
        title: {
          adapter: intl.formatMessage({ id: 'builder.node.title.adapter' }),
          apiService: intl.formatMessage({ id: 'builder.node.title.apiService' }),
          blend: intl.formatMessage({ id: 'builder.node.title.blend' }),
          deduplicate: intl.formatMessage({ id: 'builder.node.title.deduplicate' }),
          edit: intl.formatMessage({ id: 'builder.node.title.edit' }),
          filter: intl.formatMessage({ id: 'builder.node.title.filter' }),
          flip: intl.formatMessage({ id: 'builder.node.title.flip' }),
          format: intl.formatMessage({ id: 'builder.node.title.format' }),
          hierarchy: intl.formatMessage({ id: 'builder.node.title.hierarchy' }),
          join: intl.formatMessage({ id: 'builder.node.title.join' }),
          json: intl.formatMessage({ id: 'builder.node.title.json' }),
          llmService: intl.formatMessage({ id: 'builder.node.title.llmService' }),
          multi_stack: intl.formatMessage({ id: 'builder.node.title.multi_stack' }),
          notes: intl.formatMessage({ id: 'builder.node.title.notes' }),
          pivot: intl.formatMessage({ id: 'builder.node.title.pivot' }),
          rollup: intl.formatMessage({ id: 'builder.node.title.timeseries' }),
          sample: intl.formatMessage({ id: 'builder.node.title.sample' }),
          split: intl.formatMessage({ id: 'builder.node.title.split' }),
          stack: intl.formatMessage({ id: 'builder.node.title.stack' }),
          subtract: intl.formatMessage({ id: 'builder.node.title.subtract' }),
          summarize: intl.formatMessage({ id: 'builder.node.title.summarize' }),
          unpivot: intl.formatMessage({ id: 'builder.node.title.unpivot' }),
          xml: intl.formatMessage({ id: 'builder.node.title.xml' }),
        },
        desc: {
          adapter: intl.formatMessage({ id: 'builder.node.desc.adapter' }),
          apiService: intl.formatMessage({ id: 'builder.node.desc.apiService' }),
          llmService: intl.formatMessage({ id: 'builder.node.desc.llmService' }),
          blend: intl.formatMessage({ id: 'builder.node.desc.blend' }),
          deduplicate: intl.formatMessage({ id: 'builder.node.desc.deduplicate' }),
          edit: intl.formatMessage({ id: 'builder.node.desc.edit' }),
          filter: intl.formatMessage({ id: 'builder.node.desc.filter' }),
          flip: intl.formatMessage({ id: 'builder.node.desc.flip' }),
          helperText: intl.formatMessage({ id: 'builder.configurer.helperText' }),
          hierarchy: intl.formatMessage({ id: 'builder.node.desc.hierarchy' }),
          join: intl.formatMessage({ id: 'builder.node.desc.join' }),
          json: intl.formatMessage({ id: 'builder.node.desc.json' }),
          multi_stack: intl.formatMessage({ id: 'builder.node.desc.multi_stack' }),
          pivot: intl.formatMessage({ id: 'builder.node.desc.pivot' }),
          rollup: intl.formatMessage({ id: 'builder.node.desc.rollup' }),
          sample: intl.formatMessage({ id: 'builder.node.desc.sample' }),
          split: intl.formatMessage({ id: 'builder.node.desc.split' }),
          stack: intl.formatMessage({ id: 'builder.node.desc.stack' }),
          subtract: intl.formatMessage({ id: 'builder.node.desc.subtract' }),
          summarize: intl.formatMessage({ id: 'builder.node.desc.summarize' }),
          unpivot: intl.formatMessage({ id: 'builder.node.desc.unpivot' }),
          xml: intl.formatMessage({ id: 'builder.node.desc.xml' }),
        },
        ...getConnectorsContext(intl).messages,
        invalidFolderLink: intl.formatMessage({ id: 'builder.configurer.invalidFolderLink' }),
        required: intl.formatMessage({ id: 'builder.configurer.required' }),
        configuration: intl.formatMessage({ id: 'builder.configurer.configuration' }),
        save: intl.formatMessage({ id: 'builder.configurer.save' }),
        apply: intl.formatMessage({ id: 'builder.configurer.apply' }),
        cancel: intl.formatMessage({ id: 'builder.configurer.cancel' }),
        expand: intl.formatMessage({ id: 'builder.configurer.expand' }),
        collapse: intl.formatMessage({ id: 'builder.configurer.collapse' }),
        dropzone: intl.formatMessage({ id: 'builder.configurer.dropzone' }),
        changeFile: intl.formatMessage({ id: 'builder.configurer.changeFile' }),
        selectAttr: intl.formatMessage({ id: 'builder.configurer.selectAttr' }),
        newField: intl.formatMessage({ id: 'builder.configurer.newField' }),
        calculation: intl.formatMessage({ id: 'builder.configurer.calculation' }),
        transforms: intl.formatMessage({ id: 'builder.configurer.transforms' }),
        dedupe: intl.formatMessage({ id: 'builder.configurer.dedupe' }),
        filterRules: intl.formatMessage({ id: 'builder.configurer.filterRules' }),
        groupBy: intl.formatMessage({ id: 'builder.configurer.groupBy' }),
        orderBy: intl.formatMessage({ id: 'builder.configurer.orderBy' }),
        aggregations: intl.formatMessage({ id: 'builder.configurer.aggregations' }),
        duplicatedName: intl.formatMessage({ id: 'builder.configurer.duplicatedName' }),
        delimeter: intl.formatMessage({ id: 'builder.configurer.delimeter' }),
        catalog: intl.formatMessage({ id: 'builder.configurer.catalog' }),
        schema: intl.formatMessage({ id: 'builder.configurer.schema' }),
        object: intl.formatMessage({ id: 'builder.configurer.object' }),
        mapping: intl.formatMessage({ id: 'builder.configurer.mapping' }),
        mapFields: intl.formatMessage({ id: 'builder.configurer.mapFields' }),
        flowOutput: intl.formatMessage({ id: 'builder.configurer.flowOutput' }),
        destinationSystem: intl.formatMessage({ id: 'builder.configurer.destinationSystem' }),
        noDataTitle: intl.formatMessage({ id: 'builder.configurer.noDataTitle' }),
        // noDataDescription: intl.formatMessage({ id: 'builder.configurer.noDataDescription' }),
        destinationIsNotWritable: intl.formatMessage({ id: 'builder.configurer.destinationIsNotWritable' }),
        partition: intl.formatMessage({ id: 'builder.configurer.partition' }),
        sort: intl.formatMessage({ id: 'builder.configurer.sort' }),
        windowSort: intl.formatMessage({ id: 'builder.configurer.windowSort' }),
        dense: intl.formatMessage({ id: 'builder.configurer.dense' }),
        percent: intl.formatMessage({ id: 'builder.configurer.percent' }),
        newName: intl.formatMessage({ id: 'builder.configurer.newName' }),
        left: intl.formatMessage({ id: 'builder.configurer.left' }),
        right: intl.formatMessage({ id: 'builder.configurer.right' }),
        action: intl.formatMessage({ id: 'builder.configurer.action' }),
        autoMap: intl.formatMessage({ id: 'builder.configurer.autoMap' }),
        autoMapHelperText: intl.formatMessage({ id: 'builder.configurer.autoMapHelperText' }),
        fieldsMapped: intl.formatMessage({ id: 'builder.configurer.fieldsMapped' }),
        createNew: intl.formatMessage({ id: 'builder.configurer.createNew' }),
        postActions: intl.formatMessage({ id: 'builder.configurer.postActions' }),
        updateExisting: intl.formatMessage({ id: 'builder.configurer.updateExisting' }),
        recordPreferences: intl.formatMessage({ id: 'builder.configurer.recordPreferences' }),
        recordPreferencesSubText: intl.formatMessage({ id: 'builder.configurer.recordPreferencesDesc' }),
        recordPreferencesSubTextExcelGsheet: intl.formatMessage({
          id: 'builder.configurer.recordPreferencesDescExcelGsheet',
        }),
        replace: intl.formatMessage({ id: 'builder.configurer.replace' }),
        replaceRows: intl.formatMessage({ id: 'builder.configurer.replaceRows' }),
        replaceRowsSubText: intl.formatMessage({ id: 'builder.configurer.replaceRowsDesc' }),
        replaceFieldsNames: intl.formatMessage({ id: 'builder.configurer.replaceFieldsNames' }),
        insertRows: intl.formatMessage({ id: 'builder.configurer.insertRows' }),
        insertRowsSubText: intl.formatMessage({ id: 'builder.configurer.insertRowsDesc' }),
        append: intl.formatMessage({ id: 'builder.configurer.append' }),
        upsert: intl.formatMessage({ id: 'builder.configurer.upsert' }),
        upsertSubText: intl.formatMessage({ id: 'builder.configurer.upsertDesc' }),
        update: intl.formatMessage({ id: 'builder.configurer.update' }),
        updateValues: intl.formatMessage({ id: 'builder.configurer.updateValues' }),
        updateValuesSubText: intl.formatMessage({ id: 'builder.configurer.updateValuesDesc' }),
        groupReplace: intl.formatMessage({ id: 'builder.configurer.groupReplace' }),
        groupReplaceSubText: intl.formatMessage({ id: 'builder.configurer.groupReplaceDesc' }),
        builder: intl.formatMessage({ id: 'builder.configurer.builder' }),
        expression: intl.formatMessage({ id: 'builder.configurer.expression' }),
        table1: intl.formatMessage({ id: 'builder.configurer.table1' }),
        table2: intl.formatMessage({ id: 'builder.configurer.table2' }),
        leftTable: intl.formatMessage({ id: 'builder.configurer.leftTable' }),
        argument: intl.formatMessage({ id: 'builder.configurer.argument' }),
        selectChannel: intl.formatMessage({ id: 'builder.configurer.selectChannel' }),
        note: intl.formatMessage({ id: 'builder.configurer.note' }),
        privateChannelNote: intl.formatMessage({ id: 'builder.configurer.privateChannelNote' }),
        channelId: intl.formatMessage({ id: 'builder.configurer.channelId' }),
        channelName: intl.formatMessage({ id: 'builder.configurer.channelName' }),
        message: intl.formatMessage({ id: 'builder.configurer.message' }),
        emptyOutputFile: intl.formatMessage({ id: 'builder.configurer.emptyOutputFile' }),
        subject: intl.formatMessage({ id: 'builder.configurer.subject' }),
        emailTo: intl.formatMessage({ id: 'builder.configurer.emailTo' }),
        dateField: intl.formatMessage({ id: 'builder.configurer.dateField' }),
        periodicity: intl.formatMessage({ id: 'builder.configurer.periodicity' }),
        matchKeys: intl.formatMessage({ id: 'builder.configurer.matchKeys' }),
        primaryKeys: intl.formatMessage({ id: 'builder.configurer.primaryKeys' }),
        selectedFields: intl.formatMessage({ id: 'builder.configurer.selectedFields' }),
        folderLink: intl.formatMessage({ id: 'builder.configurer.folderLink' }),
        subFolderName: intl.formatMessage({ id: 'builder.configurer.subFolderName' }),
        subFolderNamePlaceholder: intl.formatMessage({ id: 'builder.configurer.subFolderNamePlaceholder' }),
        tabName: intl.formatMessage({ id: 'builder.configurer.tabName' }),
        tabNamePlaceholder: intl.formatMessage({ id: 'builder.configurer.tabNamePlaceholder' }),
        parquetWarningMsg: intl.formatMessage({ id: 'builder.configurer.parquetWarningMsg' }),
        fileName: intl.formatMessage({ id: 'builder.configurer.fileName' }),
        fileExtension: intl.formatMessage({ id: 'builder.configurer.fileExtension' }),
        fileType: intl.formatMessage({ id: 'builder.configurer.fileType' }),
        attachmentName: intl.formatMessage({ id: 'builder.configurer.attachmentName' }),
        attachmentType: intl.formatMessage({ id: 'builder.configurer.attachmentType' }),
        skipRows: intl.formatMessage({ id: 'builder.configure.skipRows' }),
        ifFileExists: intl.formatMessage({ id: 'builder.configurer.ifFileExists' }),
        selectRecipientField: intl.formatMessage({ id: 'builder.configurer.selectRecipientField' }),
        pushTODestination: intl.formatMessage({ id: 'builder.configurer.pushTODestination' }),
        topLeftCell: intl.formatMessage({ id: 'builder.configurer.topLeftCell' }),
        topLeftCellError: intl.formatMessage({ id: 'builder.configurer.topLeftCellError' }),
        noMessage: intl.formatMessage({ id: 'builder.configurer.noMessage' }),
        newFieldRequiredError: intl.formatMessage({ id: 'builder.configurer.newFieldRequiredError' }),
        fieldMappings: intl.formatMessage({ id: 'builder.configurer.fieldMappings' }),
        bootstrapSubText: intl.formatMessage({ id: 'builder.configurer.bootstrapSubText' }),
        bootstrapRefreshSubText: intl.formatMessage({ id: 'builder.configurer.bootstrapRefreshSubText' }),
        addField: intl.formatMessage({ id: 'builder.configurer.addField' }),
        addStep: intl.formatMessage({ id: 'builder.configurer.addStep' }),
        passThroughUnmapped: intl.formatMessage({ id: 'builder.configurer.passThroughUnmapped' }),
        startDate: intl.formatMessage({ id: 'builder.configurer.startDate' }),
        endDate: intl.formatMessage({ id: 'builder.configurer.endDate' }),
        useAggregationWindow: intl.formatMessage({ id: 'builder.configurer.useAggregationWindow' }),
        windowAggregation: intl.formatMessage({ id: 'builder.configurer.windowAggregation' }),
        from: intl.formatMessage({ id: 'builder.configurer.from' }),
        to: intl.formatMessage({ id: 'builder.configurer.to' }),
        randomSampleStrategy: intl.formatMessage({ id: 'builder.configurer.randomSampleStrategy' }),
        samplingDimension: intl.formatMessage({ id: 'builder.configurer.samplingDimension' }),
        slackSystem: intl.formatMessage({ id: 'builder.configurer.slackSystem' }),
        slackChannel: intl.formatMessage({ id: 'builder.configurer.slackChannel' }),
        teamsSystem: intl.formatMessage({ id: 'builder.configurer.teamsSystem' }),
        teamsChannel: intl.formatMessage({ id: 'builder.configurer.teamsChannel' }),
        applyToFullData: intl.formatMessage({ id: 'builder.configurer.applyToFullData' }),
        parameterInputVariables: intl.formatMessage({ id: 'builder.configurer.parameterInputVariables' }),
        parametersHelp: intl.formatMessage({ id: 'builder.configurer.parametersHelp' }),
        emailNotification: intl.formatMessage({ id: 'builder.configurer.emailNotification' }),
        emailNotificationHelp: intl.formatMessage({ id: 'builder.configurer.emailNotificationHelp' }),
        publish: intl.formatMessage({ id: 'builder.configurer.publish' }),
        templateUploader: intl.formatMessage({ id: 'builder.configurer.templateUploader' }),
        useTemplateLabel: intl.formatMessage({ id: 'builder.configurer.useTemplateLabel' }),
        replyTo: intl.formatMessage({ id: 'builder.configurer.replyTo' }),
        senderName: intl.formatMessage({ id: 'builder.configurer.senderName' }),
      },
      engine,
      uploader,
    },
    inspector: {
      messages: {
        explore: intl.formatMessage({ id: 'builder.inspector.explore' }),
        sampleRows: intl.formatMessage({ id: 'app.sampleRows' }),
        totalRows: intl.formatMessage({ id: 'app.totalRows' }),
        latestSample: intl.formatMessage({ id: 'builder.inspector.latestSample' }),
        updateSample: intl.formatMessage({ id: 'builder.inspector.updateSample' }),
        noDataTitle: intl.formatMessage({ id: 'builder.inspector.noDataTitle' }),
        noDataDescription1: intl.formatMessage({ id: 'builder.inspector.noDataDescription1' }),
        noDataDescription2: intl.formatMessage({ id: 'builder.inspector.noDataDescription2' }),
        editInTab: intl.formatMessage({ id: 'builder.inspector.editInTab' }),
        editInTabPopover: intl.formatMessage({ id: 'builder.inspector.editInTabPopover' }),
      },
    },
    editor: getEditorContext(intl),
  };
}

export function getAutomationCtx(intl: IntlShape): AutomationContextProps {
  return {
    messages: {
      automation: intl.formatMessage({ id: 'app.automation' }),
      repeat: intl.formatMessage({ id: 'app.automation.repeat' }),
      every: intl.formatMessage({ id: 'app.automation.every' }),
      schedule: intl.formatMessage({ id: 'app.automation.schedule' }),
      update: intl.formatMessage({ id: 'app.recipe.update' }),
      active: intl.formatMessage({ id: 'app.automation.active' }),
      runNow: intl.formatMessage({ id: 'app.automation.runNow' }),
      hour: intl.formatMessage({ id: 'app.automation.hour' }),
      day: intl.formatMessage({ id: 'app.automation.day' }),
      week: intl.formatMessage({ id: 'app.automation.week' }),
      month: intl.formatMessage({ id: 'app.automation.month' }),
      on: intl.formatMessage({ id: 'app.automation.on' }),
      at: intl.formatMessage({ id: 'app.automation.at' }),
      cancel: intl.formatMessage({ id: 'basic.cancel' }),
      createBot: intl.formatMessage({ id: 'app.automation.createBot' }),
      createTrigger: intl.formatMessage({ id: 'app.automation.createTrigger' }),
      saveTrigger: intl.formatMessage({ id: 'app.automation.saveTrigger' }),
      triggerEmail: intl.formatMessage({ id: 'app.automation.triggerEmail' }),
      triggerUrl: intl.formatMessage({ id: 'app.automation.triggerUrl' }),
      triggerEmailToBeMsg: intl.formatMessage({ id: 'app.automation.triggerEmailToBeMsg' }),
      triggerUrlToBeMsg: intl.formatMessage({ id: 'app.automation.triggerUrlToBeMsg' }),
      run: intl.formatMessage({ id: 'app.automation.run' }),
      scheduleSetUpMessage: intl.formatMessage({ id: 'app.automation.scheduleSetUpMessage' }),
      scheduleNotSetUpMessage: intl.formatMessage({ id: 'app.automation.scheduleNotSetUpMessage' }),
      edit: intl.formatMessage({ id: 'app.automation.edit' }),
      runTime: intl.formatMessage({ id: 'app.automation.runTime' }),
      alwaysOn: intl.formatMessage({ id: 'app.automation.alwaysOn' }),
      save: intl.formatMessage({ id: 'basic.save' }),
      runs: intl.formatMessage({ id: 'app.automation.runs' }),
      delete: intl.formatMessage({ id: 'basic.delete' }),
      pause: intl.formatMessage({ id: 'app.automation.pause' }),
      timeZone: intl.formatMessage({ id: 'app.automation.timeZone' }),
      errorMsgTime: intl.formatMessage({ id: 'app.automation.errorMsgTime' }),
      alwayson: intl.formatMessage({ id: 'app.automation.alwayson' }),
      startDate: intl.formatMessage({ id: 'app.automation.startDate' }),
      endDate: intl.formatMessage({ id: 'app.automation.endDate' }),
    },
  };
}
