import * as React from 'react';
import queryString from 'query-string';

import { getLandingUrl, removeLandingUrl, saveSession, saveTabSession } from '../../services/storage';
import { bootstrapTab, saveAuthResponse } from '../../services/login';
import { LANDING_URI } from '../../constants';
import { getBackendSession } from '../../services/session';
import { Loader } from '@savant-components/basic';
import { parseLoginCode } from '../../utils/utils';

const LoginCallback = (): React.ReactElement => {
  const landingUri = getLandingUrl();
  const parsed = queryString.parse(window.location.search);
  const error = parsed.error;
  if (error) {
    const error_description = parsed.error_description;
    console.error(error_description);
    window.location.href = `/`;
  } else {
    const code = parsed.code as string;
    if (code) {
      const authToken = parseLoginCode(code);
      saveAuthResponse({ access_token: authToken });
      const newUserState = parsed.newUserState as string;
      if (newUserState) {
        window.location.href = `/signup/?state=${newUserState}`;
      } else {
        getBackendSession().then(session => {
          saveSession(session);
          return bootstrapTab(authToken).then(tab => {
            saveTabSession(tab);
            if (landingUri) {
              removeLandingUrl();
              window.location.href = landingUri;
            } else {
              window.location.href = LANDING_URI;
            }
          });
        });
      }
    }
  }
  return (
    <div style={{ height: '100vh' }}>
      <Loader size={100} />
    </div>
  );
};

export default LoginCallback;
