import { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

import { Folder, Recipe, RecipeContext, BotAutomationSummary, getConnDef } from '@savant-components/catalog';
import { handleError } from '../services/client';
import {
  copyRecipe,
  deleteRecipe,
  exportRecipe,
  getFlow,
  importRecipe,
  listRecipes,
  moveRecipe,
  runRecipeNow,
  updateRecipeMetadata,
  updateRecipeName,
  getBotAutomation,
} from '../services/recipes';
import { deleteFolder as deleteFolderViaApi, listFolders, saveFolder } from '../services/folder';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';
import { getTabSession } from '../services/storage';

export interface IUseRecipes {
  isLoading: boolean;
  recipes: Recipe[];
  onRunNow: (item: Recipe, noticeOption: string) => Promise<Recipe>;
  onDelete: (item: Recipe) => Promise<void>;
  onEdit: (item: Recipe) => Promise<Recipe>;
  onExport: (item: Recipe) => Promise<void>;
  onImport: (formData: FormData) => Promise<void>;
  onCopy: (item: Recipe) => Promise<void>;
  getRecipeSummary: (recipeId: string) => Promise<BotAutomationSummary>;
  onGoToSchedule: (recipeId: string) => void;
  onMoveToFolder: (item: Recipe, folderId: string) => Promise<void>;
  folders: Folder[];
  addFolder: (folder: Folder) => Promise<Folder>;
  updateFolder: (folder: Folder) => Promise<Folder>;
  deleteFolder: (id: string) => Promise<void>;
}

export function useRecipes({
  messages,
  setFilterValues,
}: {
  messages: {
    submitted: string;
  };
  setFilterValues: (recipes: Recipe[]) => void;
}): IUseRecipes {
  const alert = useAlert();
  const intl = useIntl();
  const navigate = useNavigate();
  const recipeCtx = useContext(RecipeContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [version, setVersion] = useState<number>(0);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [folders, setFolders] = useState<Folder[]>([]);

  // const {}

  const onRunNow = (item: Recipe, noticeOption: string) => {
    return runRecipeNow(item.id, noticeOption)
      .then(exec => {
        alert.success(messages.submitted.replace('{0}', exec?.name || ''));
        return item;
      })
      .catch(err => {
        handleError(err, alert);
        return item;
      });
  };
  const onDelete = async (item: Recipe) => {
    const getRecipes = (r: Recipe[]) => r.filter(a => a.id !== item.id);
    new Promise(async resolve => {
      await deleteRecipe(item.id).catch(err => {
        handleError(err, alert);
        return item;
      });
      setRecipes(r => getRecipes(r));
      resolve(getRecipes(recipes));
    });
  };

  const onCopy = async (item: Recipe) => {
    setIsLoading(true);
    copyRecipe(item.id)
      .then(data => {
        setVersion(prevVersion => prevVersion + 1);
        return data;
      })
      .catch(err => {
        handleError(err, alert);
        setIsLoading(false);
        return undefined;
      });
  };

  const onExport = async ({ id }: Recipe) => {
    setIsLoading(true);
    await exportRecipe(id);
    setIsLoading(false);
  };

  const onImport = async (formData: FormData) => {
    const reader = new FileReader();
    reader.onload = async e => {
      try {
        JSON.parse(e.target?.result as string);
        setIsLoading(true);
        const newRecipe = await importRecipe(formData);
        setRecipes(current => [newRecipe, ...current]);
      } catch (er) {
        alert.error(recipeCtx.messages.importJsonError);
      } finally {
        setIsLoading(false);
      }
    };
    const file = formData.get('file') as File;
    reader.readAsText(file);
  };

  const onMoveToFolder = async (item: Recipe, folderId: string) => {
    moveRecipe(item.id, folderId)
      .then(() => {
        setVersion(version + 1);
      })
      .catch(err => {
        handleError(err, alert);
      });
  };

  const onEdit = async (item: Recipe): Promise<Recipe> => {
    return updateRecipeMetadata(
      item.id,
      item,
      true, // do not wipe out paramters with this request
    )
      .then(res => {
        setVersion(version + 1);
        return res;
      })
      .catch(err => {
        handleError(err, alert);
        return item;
      });
  };

  const getRecipeSummary = async (recipeId: string) => getBotAutomation(recipeId).then(data => data);

  const onGoToSchedule = (recipeId: string) => {
    const tab = getTabSession();
    navigate(`/${intl.locale}/app/flow/${recipeId}/bot/automation?rns=${tab.namespace}`);
  };

  const updateFolder = (folder: Folder) => {
    const existing = (folders || []).find(f2 => f2.id === folder.id);
    if (!existing) {
      return new Promise((_, reject) => {
        handleError(new Error('No such folder') as AxiosError, alert);
        reject('No such folder');
      });
    } else {
      return saveFolder(folder)
        .then(data => {
          setVersion(version + 1);
          return data;
        })
        .catch(err => {
          handleError(err, alert);
          throw err;
        });
    }
  };

  const addFolder = (folder: Folder) => {
    return saveFolder(folder)
      .then(data => {
        setVersion(version + 1);
        return data;
      })
      .catch(err => {
        handleError(err, alert);
        throw err;
      });
  };

  const deleteFolder = (id: string) => {
    return deleteFolderViaApi(id)
      .then(() => {
        setVersion(version + 1);
      })
      .catch(err => {
        handleError(err, alert);
        throw err;
      });
  };

  useEffect(() => {
    setIsLoading(true);
    listRecipes()
      .then(recipes => {
        const sorted = [...recipes];
        sorted.sort((a, b) => a.name.localeCompare(b.name));
        setRecipes(sorted);
        setFilterValues(sorted);
        setIsLoading(false);
      })
      .catch(err => {
        handleError(err, alert);
      });
    listFolders()
      .then(folders => {
        setFolders(folders);
      })
      .catch(err => {
        handleError(err, alert);
      });
  }, [version]);

  return {
    isLoading,
    recipes,
    onRunNow,
    onDelete,
    onCopy,
    onImport,
    onExport,
    onEdit,
    getRecipeSummary,
    onGoToSchedule,
    onMoveToFolder,
    folders,
    addFolder,
    updateFolder: updateFolder as (folder: Folder) => Promise<Folder>,
    deleteFolder,
  };
}

export const getSourceSystemArray = (recipes: Recipe[]): string[] => {
  const optionArray: string[] = [];
  recipes.forEach(info =>
    (info.srcConnectors || []).forEach(value => {
      const name = getConnDef(value)?.name || '';
      if (!optionArray.includes(name)) {
        optionArray.push(name);
      }
    }),
  );
  return optionArray;
};

export const getDestinationSystemArray = (recipes: Recipe[]): string[] => {
  const optionArray: string[] = [];
  recipes.forEach(info =>
    (info.dstConnectors || []).forEach(value => {
      const name = getConnDef(value)?.name || '';
      if (!optionArray.includes(name)) {
        optionArray.push(name);
      }
    }),
  );
  return optionArray;
};

export const getTagArray = (recipes: Recipe[]) => {
  const optionArray: string[] = [];
  recipes.forEach(info => {
    (info.tags || []).forEach(tag => {
      if (!optionArray.includes(tag)) {
        optionArray.push(tag);
      }
    });
  });
  return optionArray;
};

export function useRecipe({ recipeId }: { recipeId: string }): {
  recipe: Recipe;
  isLoading: boolean;
  onUpdateName: (newName: string) => Promise<string>;
} {
  const alert = useAlert();
  const [recipe, setRecipe] = useState<Recipe>();
  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    setIsLoading(true);
    getFlow(recipeId)
      .then(data => {
        setRecipe(data as Recipe);
        setIsLoading(false);
      })
      .catch(err => handleError(err, alert));
  }, [recipeId]);

  const onUpdateName = async (newName: string): Promise<string> => {
    return updateRecipeName(recipeId, newName)
      .then(() => {
        setRecipe({
          ...(recipe as Recipe),
          name: newName,
        });
        return newName;
      })
      .catch(err => {
        handleError(err, alert);
        return recipe ? recipe.name : '';
      });
  };
  return {
    recipe: recipe as Recipe,
    isLoading: isLoading as boolean,
    onUpdateName,
  };
}
