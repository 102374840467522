import { css, styled } from '@mui/material';

export const ViewerStyle = styled('div')(
  () =>
    css`
      position: fixed;
      top: 3rem;
      left: 0;
      bottom: 0;
      right: 0;
    `,
);
export const RootStyle = styled('div')(
  () =>
    css`
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
    `,
);
