import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Breadcrumbs, Link as MLink, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { BasicContext, EditableText, Loader } from '@savant-components/basic';
import { Users, UsersContext } from '@savant-components/catalog';
import { useTheme } from '@savant-components/theme';

import { getUsersContext } from '../../contexts';
import { useFeatureService } from '../../hooks/feature';
import { useUsers } from '../../hooks/users';
import { useWorkspace } from '../../hooks/workspaces';
import AdminLayout from './AdminLayout';
import WorkspaceDetailLayout from './WorkspaceDetailLayout';
import { WorkspaceNotifications } from './WorkspaceNotifications';

const Container = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 16,
    paddingRight: '4.5rem',

    '& > .members-table': {
      flex: 1,
    },
  };
});

const WorkspacesView = (): React.ReactElement => {
  const intl = useIntl();
  const workspacesLabel = intl.formatMessage({ id: 'app.workspaces' });
  const notificationText = 'Send notifications to an admin for bot jobs for this workspace.';
  const destinationText = 'Notifications can be sent to one or many email addresses and a Slack channel.';

  const theme = useTheme();

  const userCtx = React.useMemo(() => getUsersContext(intl), []);
  const ctx = useContext(BasicContext);

  const { workspaceId } = useParams();
  const { isLoading: usersLoading, isOrgAdmin } = useUsers();

  const { isFeatureEnabled } = useFeatureService();
  const isPurgingExecutionEnabled = isFeatureEnabled('PURGING_EXECUTIONS');

  const [searchText, setSearchText] = useState('');
  const [name, setName] = useState<string>('');
  const [module, setModule] = useState<string>('members');

  const {
    addMembers,
    describeWorkspaceSlackChannel,
    isButtonDiabled,
    isLoading: workspaceLoading,
    isWorkspaceAdmin,
    joinAsAdmin,
    listWorkspaceSlackChannels,
    listWorkspaceSlackConnections,
    listWorkspaceTeamsConnections,
    recipients,
    removeMember,
    searchUsers,
    selected,
    setRecipients,
    setSelected,
    setSetting,
    setting,
    setWorkspaceSetting,
    updateMemberRole,
    updateSettings,
    updateWorkspaceName,
    workspace,
    workspaceSetting,
  } = useWorkspace(workspaceId as string, isPurgingExecutionEnabled);

  useEffect(() => {
    if (workspace?.name && !name) {
      setName(workspace.name);
    }
  }, [workspace?.name, name]);

  return (
    <AdminLayout module="workspaces">
      <Container>
        {usersLoading || workspaceLoading ? (
          <Loader size={200} />
        ) : (
          <UsersContext.Provider value={userCtx}>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
              <div className="header">
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <MLink underline="hover" color="inherit" href={`/${intl.locale}/app/admin/workspaces`}>
                    {workspacesLabel}
                  </MLink>
                  <Typography color={theme.colors.text}>{workspace.name}</Typography>
                </Breadcrumbs>
              </div>

              <div className="text-t5" style={{ marginTop: '16px' }}>
                {ctx.messages.name}
              </div>

              {isWorkspaceAdmin ? (
                <EditableText
                  text={workspace.name}
                  onUpdateText={s => updateWorkspaceName(workspace.id, s)}
                  size="t6"
                  keepEditIconVisible
                />
              ) : (
                <span>{workspace.name}</span>
              )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
              <WorkspaceDetailLayout module={module} setModule={setModule} notificationEnabled={isWorkspaceAdmin}>
                {module === 'members' ? (
                  <Users
                    addMembers={addMembers}
                    enableBusinessUser={true}
                    hideWorkspaces
                    isOrganizationAdmin={isOrgAdmin}
                    isWorkspaceAdmin={isWorkspaceAdmin}
                    joinAsAdmin={joinAsAdmin}
                    removeMember={removeMember}
                    searchText={searchText}
                    searchUsers={searchUsers}
                    setSearchText={setSearchText}
                    updateMemberRole={updateMemberRole}
                    users={workspace?.members || []}
                  />
                ) : (
                  <WorkspaceNotifications
                    destinationText={destinationText}
                    discribeChannel={describeWorkspaceSlackChannel}
                    emails={recipients}
                    emails1={setRecipients}
                    enabled={isWorkspaceAdmin}
                    isButtonDiabled={isButtonDiabled}
                    isPurgingExecutionEnabled={isPurgingExecutionEnabled}
                    listChannels={listWorkspaceSlackChannels}
                    listSlackConnections={listWorkspaceSlackConnections}
                    listTeamsConnections={listWorkspaceTeamsConnections}
                    notificationSettings={setting}
                    notificationSettings1={setSetting}
                    notificationText={notificationText}
                    onClick={updateSettings}
                    selected={selected}
                    setSelected={setSelected}
                    setWorkspaceSetting={setWorkspaceSetting}
                    theme={theme}
                    workspaceSetting={workspaceSetting}
                  />
                )}
              </WorkspaceDetailLayout>
            </Box>
          </UsersContext.Provider>
        )}
      </Container>
    </AdminLayout>
  );
};

export default WorkspacesView;
