/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { Suspense } from 'react';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

const ResetPasswordView = React.lazy(() => import('../components/ResetPasswordView'));

const ResetPasswordPage = (): React.ReactElement => {
  const { breakpoint } = useWindowDimensions();

  const standardStyle = css`
    #bg-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      & > img {
        width: 1000px;
      }
    }
    #reset-password {
      display: flex;
      height: 100vh;
      width: 100%;
    }
  `;

  const style = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #f9f9ff;
    * {
      transition: width 0.25s ease;
    }
    ${breakpoint !== 'sm' ? standardStyle : ''}
  `;
  if (typeof window === 'undefined') {
    return <React.Fragment />;
  } else {
    return (
      <div css={style}>
        {breakpoint !== 'sm' && (
          <div id="bg-img">
            <img src="https://assets.savantlabs.io/brand/icon.svg" />
          </div>
        )}
        <div id="reset-password">
          <Suspense fallback={<React.Fragment />}>
            <ResetPasswordView />
          </Suspense>
        </div>
      </div>
    );
  }
};

export default ResetPasswordPage;
