import { AlertContainer as AlertManager } from 'react-alert';
import queryString from 'query-string';

import { AxiosError, AxiosResponse } from 'axios';
import { nanoid } from 'nanoid';

import {
  AnalysisNoticeSetting,
  ConnectionInfo,
  DataFrame,
  FieldMetadata,
  getValidFieldId,
  Uploader,
} from '@savant-components/basic';
import {
  ApiPagination,
  ComputationStatusResponse,
  ComputeAction,
  ComputeNodeRes,
  DBTJob,
  Engine,
  MockEngine,
  NodeState,
  SlackChannel,
  SourceConfig,
  SubmitExecutionReq,
  TransformConfig,
} from '@savant-components/builder';
import {
  Connection,
  Execution,
  FileType,
  ObjectPath,
  ObjectSchema,
  ObjectTree,
  Recipe,
  RecipeParameter,
  Source,
} from '@savant-components/catalog';
import { PipelineConfig, PipelineStep, toPipelineSteps } from '@savant-components/editor';

import {
  getFileSignedUrl as getFileSignedUrlApi,
  getObjectTree as getFileObjectTreeApi,
  getUploadedFiles as getUploadedFilesApi,
} from './upload';
import client, { followPromise, handleError } from './client';
import { getTabSession } from './storage';
import {
  describeSlackChannel as describeSlackChannelViaApi,
  getObjectSchema,
  getObjectTree,
  listConnections,
  listDbtJobs as listDbtJobsViaApi,
  listSlackChannels as listSlackChannelsViaApi,
} from './connection';
import { StartDebugSessionRequest } from './debugSession';
import { getSample, listSources } from './source';
import {
  getNewDestNode,
  getNewSourceNode,
  saveAddConnectionWizardContext,
  saveAddSourceWizardContext,
} from './storage';
import {
  cancelExecution,
  downloadReportExecution,
  getExecution,
  getExecutionsInRecipe,
  updateExecutionMetadata,
} from './execution';
import { createTemplateByExecution, listWritableTemplates, updateTemplateByExecution } from './template';
import { getRecipeFromExecution } from './recipes';
import { NavigateFunction } from 'react-router-dom';
import { IntlShape } from 'react-intl';

const mockModules = ['mock', 'mock2', 'fileOutput', 'destination'];

const noSignedURLErroMessage = 'Something bad happened. No Signed URL returned';
function convertToPipeline(config: TransformConfig, inputSchema: FieldMetadata[]): PipelineConfig {
  const dAttrs = config.derivedAttrs;
  let steps: PipelineStep[] = [];
  const schema = [...inputSchema];
  dAttrs.forEach(dAttr => {
    const lookup = dAttr.lookup;
    const fieldName = dAttr.fieldName;
    const fieldId = getValidFieldId(schema, fieldName);
    const tgtCol: FieldMetadata = {
      id: fieldId,
      name: dAttr.fieldName,
      dataType: lookup?.dataType,
    };
    schema.push(tgtCol);
    if (lookup) {
      const pipelineSteps = toPipelineSteps(lookup, tgtCol);
      steps = [...steps, ...pipelineSteps];
    }
  });
  let dropCols: string[] = [];
  steps.forEach(step => {
    if (step.transient_cols) {
      dropCols = [...dropCols, ...step.transient_cols];
    }
  });
  return {
    steps,
    drop_cols: dropCols,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function resultSizeSummaries(res: AxiosResponse<any>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resulteSizes: Record<string, any> = {};
  if (res.data.dataSetSummaries) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    res.data.dataSetSummaries.forEach((summary: any, idx: number) => {
      const outlet = `out_${idx}`;
      resulteSizes[outlet] = summary.count;
    });
  }
  return Object.keys(resulteSizes).length > 0 ? resulteSizes : undefined;
}

class ApiEngine implements Engine {
  locale = 'en';
  mockEngine = new MockEngine(2000);
  uploader: Uploader;
  alert: AlertManager;
  recipeId?: string;
  isInDebugMode?: boolean;
  navigate: NavigateFunction;

  saveAddSourceWizardContext = saveAddSourceWizardContext;
  saveAddConnectionWizardContext = saveAddConnectionWizardContext;
  getNewDestNode = getNewDestNode;
  getNewSourceNode = getNewSourceNode;

  namespace = getTabSession().namespace;

  execution = {
    cancelExecution,
    createTemplateByExecution,
    downloadReportExecution,
    getExecution,
    getExecutionsInRecipe,
    getRecipeFromExecution,
    handleError,
    listWritableTemplates,
    updateExecutionMetadata,
    updateTemplateByExecution,
  };
  intl?: IntlShape;

  constructor(
    uploader: Uploader,
    shape: IntlShape,
    alert: AlertManager,
    recipeId: string,
    isInDebugMode: boolean,
    navigate: NavigateFunction,
  ) {
    this.locale = shape.locale;
    this.intl = shape;
    this.uploader = uploader;
    this.alert = alert;
    this.navigate = navigate;
    this.recipeId = recipeId;
    this.isInDebugMode = isInDebugMode;
  }

  saveRecipeMetadata(recipe: Recipe): Promise<Recipe> {
    return Promise.resolve(recipe);
  }
  getAllConnections = async (): Promise<Connection[]> => {
    const csv: Connection = {
      id: nanoid(10), // to avoid being treated as placeholder
      name: 'CSV',
      type: 'csv',
      connector: 'csv',
    };

    const email: Connection = {
      id: nanoid(10), // to avoid being treated as placeholder
      name: 'Email',
      type: 'email',
      connector: 'email',
    };

    return listConnections({
      excludeReadOnly: true,
      excludeDbt: true,
    })
      .then(conns => {
        // APP-830, ESUP-84
        conns.sort((c1, c2) => {
          const type1: string = (c1.mockConnector || c1.type || '').toLowerCase();
          const type2: string = (c2.mockConnector || c2.type || '').toLowerCase();
          if (type1 === type2) {
            return c1.name.localeCompare(c2.name);
          } else {
            return type1.localeCompare(type2);
          }
        });

        const filteredConns = conns;

        return [email, csv, ...filteredConns];
      })
      .catch(err => {
        handleError(err, this.alert);
        return [email, csv];
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  discardFile(_: string): Promise<void> {
    return Promise.resolve(undefined);
  }
  handleError = (err: Error) => {
    handleError(err as AxiosError, this.alert);
  };
  getAllSources = async (): Promise<Source[]> => {
    return listSources()
      .then(sources => {
        // APP-873
        const sources2 = sources.map(src => {
          const connector = src.type || src['connector'];
          return {
            ...src,
            type: connector,
            connectionName: src.connectionName || connector?.toUpperCase(),
          };
        });
        // APP-1555, ESUP-84
        sources2.sort((s1, s2) => {
          const type1: string = (s1.mockConnector || s1.type || '').toLowerCase();
          const type2: string = (s2.mockConnector || s2.type || '').toLowerCase();
          if (type1 === type2) {
            return s1.name.localeCompare(s2.name);
          } else {
            return type1.localeCompare(type2);
          }
        });
        return sources2;
      })
      .catch(err => {
        handleError(err, this.alert);
        return [];
      });
  };

  getComputationStatus = async (flowId: string, sampleTier?: string): Promise<ComputationStatusResponse> => {
    return client
      .get(`/interactive/graph-computation-status`, { params: { flowId, sampleTier } })
      .then(res => res.data);
  };

  getInteractiveDownloadUrl = async (flowId: string, nodeId: string, outlet: string): Promise<string> => {
    return client
      .get(`/interactive/download_url-async?flowId=${flowId}&nodeId=${encodeURIComponent(nodeId)}&outlet=${outlet}`)
      .then(resp => {
        const promise = resp.data;
        promise.createdTime = new Date();
        return followPromise(promise, undefined)
          .then(result => {
            const resultElement = (result as { signedUrl: string })?.['signedUrl'];
            if (resultElement) {
              return resultElement;
            }
            throw new Error(noSignedURLErroMessage);
          })
          .catch(err => {
            handleError(err, this.alert);
            return '';
          });
      });
  };

  stopSession = (flowId: string): Promise<boolean> => {
    return client.put(`/debug-session/stop-session?flowId=${flowId}`).then(resp => {
      const success = Boolean(resp.data);
      if (success) {
        this.alert.success('Exploration session stopped');
      }
      return success;
    });
  };
  evictCache = (req: { sampleTier: '1k' | 'max'; flowId: string; nodeIds: string[] }): Promise<boolean> => {
    return client
      .post(`/interactive/evict-cache?sampleTier=${req.sampleTier}&flowId=${req.flowId}`, req.nodeIds)
      .then(() => true)
      .catch(e => {
        this.alert.error(e.response.data.error_detail);
        return false;
      });
  };
  startSession = (req: StartDebugSessionRequest): Promise<boolean> => {
    return client
      .post(`/debug-session/start-session`, req)
      .then(resp => {
        const success = Boolean(resp.data);
        if (success) {
          this.alert.success('Exploration session started');
        }
        return success;
      })
      .catch(e => {
        this.alert.error(e.response.data.error_detail);
        return false;
      });
  };

  _bypassErrorOnNodes = (nodes: NodeState[]) => {
    return nodes.map(currentNode => {
      if (currentNode.type === 'edit') {
        return {
          ...currentNode,
          config: {
            ...currentNode.config,
            bypassError: true,
          },
        };
      }
      return currentNode;
    });
  };

  recomputeGraph = async (
    flowId: string,
    nodes: NodeState[],
    parameters?: RecipeParameter[],
    sampleTier?: string,
    startingNodeIds?: string[],
    sendSampleExecutionId?: boolean,
    action?: ComputeAction,
  ): Promise<ComputationStatusResponse> => {
    const processedNodes = this._bypassErrorOnNodes(nodes);

    const hasStartingNodes = (startingNodeIds || []).length > 0;
    const sampleExecutionId = sampleTier === '1k' && sendSampleExecutionId ? 'latest' : undefined;
    const data = hasStartingNodes
      ? { flowId, startingNodes: startingNodeIds, nodes: processedNodes, sampleExecutionId, parameters }
      : { flowId, nodes: processedNodes, parameters };
    const query = queryString.stringify({
      sampleTier,
      action,
    });
    return client.post(`/interactive/graph-computation?${query}`, data).then(res => res.data);
  };

  computeNodeWithGraphAPI = async (
    nodeId: string,
    pagination?: ApiPagination,
    sampleTier?: string,
  ): Promise<{
    outputs?: DataFrame[];
    resultSizes?: Record<string, number>;
    startedAt?: number;
    finishedAt?: number;
  }> => {
    return client
      .post(
        `/interactive/graph-computation-sort?flowId=${this.recipeId}&nodeId=${encodeURI(
          nodeId,
        )}&sampleTier=${sampleTier}`,
        {
          sorts: pagination?.sorts || [],
        },
      )
      .then(res => {
        return {
          outputs: res.data.outputData || [],
          resultSizes: resultSizeSummaries(res),
          startedAt: res.data.startedAt,
          finishedAt: res.data.finishedAt,
          hiddenFieldsFMs: res.data.outputJson,
        };
      })
      .catch(err => {
        let errMsg = err.message;
        if (err['response']?.data?.error_detail) {
          errMsg = err['response']?.data?.error_detail;
        }
        throw new Error(errMsg);
      });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  computeNode = async ({ nodeType, config, inputs, noCache }: any): Promise<{ outputs?: DataFrame[] }> => {
    if (mockModules.indexOf(nodeType) >= 0) {
      return this.mockEngine.computeNode({ nodeType, config, inputs });
    }

    if (nodeType.toLowerCase() === 'source') {
      const source: SourceConfig = config as SourceConfig;
      if (source.id === 'placeholder' || source.id === undefined) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              outputs: [
                {
                  schema: [],
                  data: [],
                },
              ],
            });
          }, 100);
        });
      }

      return new Promise(async resolve => {
        let frame: DataFrame | undefined = undefined;
        try {
          frame = await getSample(source.id);
        } catch (err) {
          // pass
        }
        // retry
        if ((frame?.schema || []).length === 0) {
          try {
            frame = await getSample(source.id);
          } catch (err) {
            // pass
          }
        }
        resolve({
          outputs: [frame!],
        });
      });
    }

    let payload = { launcher: nodeType, inputData: inputs, config };

    if (nodeType === 'service') {
      return client.post(`/interactive/node-computation-async?noCache=${noCache ? 1 : 0}`, payload).then(resp => {
        const promise = resp.data;
        promise.createdTime = new Date();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return followPromise(promise, undefined).then((result: any) => {
          return {
            outputs: result?.outputData,
          };
        });
      });
    }

    if (nodeType === 'transform') {
      const pipeline: PipelineConfig = convertToPipeline(config as TransformConfig, inputs[0].schema);
      payload = {
        ...payload,
        config: {
          ...config,
          pipeline: pipeline,
        },
      };
    }
    if (nodeType === 'edit') {
      payload = {
        ...payload,
        config: {
          ...config,
          bypassError: true,
        },
      };
    } else if (nodeType === 'filter') {
      payload = {
        ...payload,
        config: config,
      };
    }

    return client
      .post(`/interactive/node-computation?noCache=${noCache ? 1 : 0}`, payload)
      .then(res => {
        return {
          outputs: res.data.outputData,
        };
      })
      .catch(err => {
        let errMsg = err.message;
        if (err['response']?.data?.error_detail) {
          errMsg = err['response']?.data?.error_detail;
        }
        throw new Error(errMsg);
      });
  };
  submitExecution = async (req: SubmitExecutionReq): Promise<Execution> => {
    const nodes = req.nodes.map(node => {
      return {
        ...node,
        config: {
          ...node.config,
          data: undefined,
        },
      };
    });
    const req2 = {
      ...req,
      nodes: nodes,
    };
    return client
      .post(`/executions`, req2)
      .then(res => {
        return res.data.execution;
      })
      .catch(err => {
        handleError(err, this.alert);
        return '';
      });
  };
  getExecutions = async (recipeId: string): Promise<Execution[]> => {
    return client.get(`/recipes/${recipeId}/executions?types=test_run`).then(res => {
      return res.data.executions;
    });
  };
  getExecution = getExecution;
  getExecutionSample = async (executionId: string, nodeId: string, outlet: string): Promise<ComputeNodeRes> => {
    return client
      .get(`/executions/${executionId}/nodes/${encodeURIComponent(nodeId)}/sample?outlet=${outlet}`)
      .then(res => {
        const w = {
          schema: res.data.schema.map((field: FieldMetadata) => {
            if (field.dataType) {
              return field;
            } else {
              return {
                ...field,
                dataType: 'string',
              };
            }
          }),
          data: res.data.data,
        };
        return {
          outputs: [w],
          resultSizes: resultSizeSummaries(res),
        };
      });
  };
  getDownloadUrl = async (executionId: string, nodeId: string, outlet: string): Promise<string> => {
    return client
      .get(`/executions/${executionId}/nodes/${encodeURIComponent(nodeId)}/download_url-async?outlet=${outlet}`)
      .then(resp => {
        const promise = resp.data;
        promise.createdTime = new Date();
        return followPromise(promise, undefined)
          .then(result => {
            const resultElement = (result as { signedUrl: string })['signedUrl'];
            if (resultElement) {
              return resultElement;
            }
            throw new Error(noSignedURLErroMessage);
          })
          .catch(err => {
            handleError(err, this.alert);
            return '';
          });
      });
  };

  getConnectionInfo = async (connectionId: string): Promise<ConnectionInfo> => {
    return client
      .get(`/connections/${connectionId}/info`)
      .then(resp => {
        return resp.data;
      })
      .catch(err => {
        handleError(err, this.alert);
      });
  };

  getSourceInfo = async (sourceId: string): Promise<ConnectionInfo> => {
    return client
      .get(`/sources/${sourceId}/info`)
      .then(resp => {
        return resp.data;
      })
      .catch(err => {
        handleError(err, this.alert);
      });
  };

  getObjectTree = async (connectionId: string): Promise<ObjectTree> => {
    return getObjectTree(connectionId, 'WRITE')
      .then(tree => {
        if (tree && tree.catalogs) {
          return tree;
        } else {
          return { catalogs: [] };
        }
      })
      .catch(err => {
        handleError(err, this.alert);
        return { catalogs: [] };
      });
  };
  getObjectSchema = async (connectionId: string, objectPath: ObjectPath): Promise<ObjectSchema> => {
    return getObjectSchema(connectionId, objectPath, 'WRITE').catch(err => {
      handleError(err, this.alert);
      return undefined as unknown as ObjectSchema;
    });
  };
  listSlackChannels = async (connectionId: string): Promise<SlackChannel[]> => {
    return listSlackChannelsViaApi(connectionId).catch(err => {
      handleError(err, this.alert);
      return [];
    });
  };
  describeSlackChannel = async (connectionId: string, channelId: string): Promise<SlackChannel> => {
    return describeSlackChannelViaApi(connectionId, channelId).catch(err => {
      handleError(err, this.alert);
      return undefined as unknown as SlackChannel;
    });
  };
  listDbtConnections = async (): Promise<Connection[]> => {
    // return listConnections({ connector: 'dbtcloud', excludeReadOnly: true }).catch(err => {
    return listConnections({ connector: 'dbtcloud' }).catch(err => {
      handleError(err, this.alert);
      return [];
    });
  };
  listDbtJobs = (connectionId: string): Promise<DBTJob[]> => {
    return listDbtJobsViaApi(connectionId).catch(err => {
      handleError(err, this.alert);
      return [];
    });
  };
  getUploadedFiles = (fileId: string) => {
    return getUploadedFilesApi(fileId).catch(err => {
      handleError(err, this.alert);
      return undefined;
    });
  };
  getFileObjectTree = (fileId: string, fileType: FileType) => {
    return getFileObjectTreeApi(fileId, fileType).catch(err => {
      handleError(err, this.alert);
      return {
        catalogs: [],
      };
    });
  };
  getFileSignedUrl = (fileId: string) => {
    return getFileSignedUrlApi(fileId).catch(err => {
      handleError(err, this.alert);
      return [];
    });
  };
  getRecipeNoticeOption = async (recipeId: string): Promise<string> => {
    return await client
      .get(`/notifications/analysis/${recipeId}`)
      .then(resp => resp.data)
      .catch(err => handleError(err, this.alert));
  };
  saveRecipeNoticeOption = async (recipeId: string, noticeOption: string): Promise<AnalysisNoticeSetting> => {
    return await client
      .post(`/notifications/analysis/${recipeId}?noticeOption=${noticeOption}`)
      .then(resp => {
        // this.alert.success('Analysis notification setting saved');
        return resp.data;
      })
      .catch(err => handleError(err, this.alert));
  };
  updateRecipeNoticeOption = async (recipeId: string, noticeOption: string): Promise<AnalysisNoticeSetting> => {
    return await client
      .put(`/notifications/analysis/${recipeId}?noticeOption=${noticeOption}`)
      .then(resp => {
        this.alert.success('Analysis notification setting updated');
        return resp.data;
      })
      .catch(err => handleError(err, this.alert));
  };
}

export default ApiEngine;
