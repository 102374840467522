import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material';

import { Loader, LoaderModal } from '@savant-components/basic';
import { AddSource, AggregatedAddSourceState as AggregatedState, Connection, Source } from '@savant-components/catalog';

import { useAddConnection, useConnections } from '../../hooks/connection';
import { useFeatureService } from '../../hooks/feature';
import { useAddSource } from '../../hooks/source';
import { getSchema } from '../../services/source';
import { getAddSourceWizardContext, removeAddSourceWizardContext, saveNewSourceNode } from '../../services/storage';
import { getConnectorsArray, getOwnerArray } from '../../utils/utils';

const Container = styled('div')(() => {
  return {
    height: 'calc(100vh - 4rem)',
    maxHeight: 'calc(100vh - 4rem)',
  };
});

const AddSourceView = (): JSX.Element => {
  const intl = useIntl();
  const locale = intl.locale;

  const [connections, setConnections] = useState<Connection[]>([]);

  const [filterOptions, setFilterOptions] = useState([
    {
      label: 'Last Modified',
      type: 'date',
      placeHolder: 'Select date',
      value: [null, null],
    },
    {
      label: 'Connector(s)',
      type: 'select',
      placeHolder: 'All systems',
      value: [],
      options: getConnectorsArray([...connections]),
    },
    {
      label: 'Owner',
      type: 'select',
      placeHolder: 'All systems',
      value: [],
      options: getOwnerArray([...connections]),
    },
  ]);

  const { isFeatureEnabled } = useFeatureService();

  const {
    configService,
    confirmAddSource,
    getAllConnections,
    initialConnection,
    initialConnector,
    isLoading,
    onConfirmNewConnection,
    restoreSteps,
    sourceWizard,
  } = useAddSource();

  const {
    authenticateApiKeyAuth,
    authenticateAwsAuth,
    authenticateBasicAuth,
    authenticateCustomOAuth,
    authenticateDbAuth,
    authenticateGenericApiAuth,
    authenticateGoogleSAAuth,
    authenticateSshAuth,
    isRedirecting,
    saveAndAuthenticate,
  } = useAddConnection();

  const { getDependingRecipes } = useConnections({
    connecionsUrl: `/${intl.locale}/app/connection`,
  });

  useEffect(() => {
    getAllConnections().then(conns => {
      setConnections(conns || []);
      setFilterValues(conns || []);
    });
  }, []);

  const isRedirectingMessage = intl.formatMessage({ id: 'app.connection.isRedirecting' });

  const experimentalConnectors: string[] = [];
  if (isFeatureEnabled('CONNECTOR_GOOGLECAMPAIGNMANAGER')) {
    experimentalConnectors.push('googlecampaignmanager');
  }
  if (isFeatureEnabled('CONNECTOR_AZURE_SYNAPSE')) {
    experimentalConnectors.push('azuresynapse');
  }
  if (isFeatureEnabled('CONNECTOR_TWITTER')) {
    experimentalConnectors.push('twitter');
  }

  const setFilterValues = (connections: Connection[]) => {
    const typeFilter = filterOptions.find(f => f.label === 'Connector(s)');
    if (typeFilter) {
      typeFilter.options = getConnectorsArray(connections || []);
    }
    const phaseFilter = filterOptions.find(f => f.label === 'Owner');
    if (phaseFilter) {
      phaseFilter.options = getOwnerArray(connections || []);
    }
    setFilterOptions([...filterOptions]);
  };

  const onCancelAdd = () => {
    let cancelUrl = `/${locale}/app/source`;

    const ctx = getAddSourceWizardContext();
    if (ctx === 'new-connection') {
      cancelUrl = `/${locale}/app/connection`;
    } else if (ctx !== undefined && ctx.startsWith('edit-recipe')) {
      cancelUrl = ctx.replace('edit-recipe', `/${locale}/app/flow`);
    }

    removeAddSourceWizardContext();

    window.location.href = cancelUrl;
  };

  const onConfirmAdd = (state: AggregatedState) => {
    confirmAddSource(state).then(source => {
      let returnUrl = `/${locale}/app/source`;

      const ctx = getAddSourceWizardContext();
      if (ctx === 'first-recipe' || (ctx !== undefined && ctx.startsWith('edit-recipe'))) {
        getSchema(source?.id || '').then(schema => {
          saveNewSourceNode({
            ...source,
            schema,
            createdTime: undefined,
          } as Source);

          if (ctx.startsWith('edit-recipe')) {
            returnUrl = ctx.replace('edit-recipe', `/${locale}/app/flow`);
          } else {
            returnUrl = `/${locale}/app/flow/create`;
          }

          window.location.href = returnUrl;
          removeAddSourceWizardContext();
        });

        returnUrl = undefined as unknown as string;
      }

      if (returnUrl !== undefined) {
        window.location.href = returnUrl;
        removeAddSourceWizardContext();
      }
    });
  };

  return (
    <Container>
      {isLoading ? (
        <Loader size={200} />
      ) : (
        <AddSource
          authenticateApiKeyAuth={authenticateApiKeyAuth}
          authenticateAwsAuth={authenticateAwsAuth}
          authenticateBasicAuth={authenticateBasicAuth}
          authenticateCustomDbAuth={authenticateDbAuth}
          authenticateCustomOAuth={authenticateCustomOAuth}
          authenticateDbAuth={authenticateDbAuth}
          authenticateGenericApi={authenticateGenericApiAuth}
          authenticateGoogleSA={authenticateGoogleSAAuth}
          authenticateSshAuth={authenticateSshAuth}
          configService={configService}
          experimentalConnectors={experimentalConnectors}
          filterOptions={filterOptions}
          getAllConnections={getAllConnections}
          getDependingRecipes={getDependingRecipes}
          initialConnection={initialConnection}
          initialConnector={initialConnector}
          onAddConnection={() => {
            console.debug('onAddConnection');
          }}
          onCancel={onCancelAdd}
          onConfirm={onConfirmAdd}
          onConfirmNewConnection={onConfirmNewConnection}
          onSaveAndAuthenticate={saveAndAuthenticate}
          restoreSteps={restoreSteps}
          setFilterOptions={setFilterOptions}
          sourceWizard={sourceWizard || undefined}
        />
      )}

      <LoaderModal isOpen={isRedirecting} message={isRedirectingMessage} />
    </Container>
  );
};

export default AddSourceView;
