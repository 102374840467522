import axios, { AxiosError } from 'axios';
import { AlertContainer as AlertManager } from 'react-alert';

import {
  clearLocalStorage,
  clearSessionStorage,
  getTabSession,
  getUpToDateAuth,
  removeAuthentication,
} from './storage';

// special error message that won't trigger alert toast
const IDLE_TIMEOUT = 'IDLE_TIMEOUT';

function getAuthHeader() {
  let authHeader = '';
  if (typeof window !== 'undefined') {
    const auth = getUpToDateAuth();
    if (!auth?.access_token) {
      throw Error(IDLE_TIMEOUT);
    }
    authHeader = `Bearer ${auth?.access_token}`;
  }
  return authHeader;
}

function getRecordingURL(): string {
  if (typeof window !== 'undefined' && typeof window['recordingURL'] !== 'undefined') {
    return window['recordingURL'];
  } else {
    return '';
  }
}

export interface ApiPromise<T> {
  promiseId: string;
  status: string;
  progress: number;
  createdTime: Date;
  result?: T;
  exception?: {
    code: string;
    message: string;
  };
}

export async function followPromise<T>(
  promise: ApiPromise<T>,
  onProcessUpdate?: (progress: number) => void,
): Promise<T> {
  return new Promise((resolve, reject) => {
    if (promise.progress >= 1) {
      if (promise.exception === undefined) {
        resolve(promise.result as T);
      } else {
        reject(new Error(promise.exception.message));
      }
    } else if (new Date().getTime() - promise.createdTime.getTime() > 3600 * 1000) {
      reject(new Error('waiting for promise timed out.'));
    } else {
      if (onProcessUpdate) {
        onProcessUpdate(promise.progress);
      }
      setTimeout(async () => {
        client.get(`/promises/${promise.promiseId}`).then(res => {
          const promise2 = res.data;
          if (promise2.exception) {
            reject(new Error(promise2.exception.message));
          } else {
            if (promise2.progress == undefined) {
              promise2.progress = promise.progress;
            }
            promise2.createdTime = promise.createdTime;
            followPromise<T>(promise2, onProcessUpdate)
              .then(res2 => {
                resolve(res2);
              })
              .catch(err => reject(err));
          }
        });
      }, 500);
    }
  });
}

const client = axios.create({
  baseURL: '/api',
  headers: {
    // can be common or any other method
    Accept: 'application/json',
  },
  onUploadProgress: progressEvent => console.log(progressEvent.loaded),
});

function getTabId() {
  if (typeof window !== 'undefined') {
    const tab = getTabSession();
    return tab?.tabId;
  }
}

// Set the AUTH token for any request
client.interceptors.request.use(function (config) {
  config.headers.Authorization = getAuthHeader();
  config.headers['X-SAVANT-TAB'] = getTabId();
  const recordingURL = getRecordingURL();
  if (recordingURL) {
    config.headers['X-RECORDING-URL'] = recordingURL;
  }
  return config;
});

// redirect to logout
client.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      if (!error['response']?.data) {
        removeAuthentication();
        clearLocalStorage();
        clearSessionStorage();
        window.location.href = window.location.href;
      }
    }
    throw error;
  },
);

export function handleError(error: AxiosError, alert?: AlertManager): void {
  let errMsg = error.message;
  const errData = error?.response?.data;
  if (errData?.error_detail) {
    errMsg = errData.error_detail;
  }
  if (errMsg !== IDLE_TIMEOUT) {
    if (errMsg.startsWith('Bad request: ')) {
      errMsg = errMsg.replace('Bad request: ', '');
    }
    alert?.error(errMsg, {
      timeout: 0,
    });
  }
  if (typeof window !== 'undefined' && typeof window?.['LogRocket'] !== 'undefined') {
    const LogRocket = window?.['LogRocket'];
    LogRocket.captureException(error);
  }
}

export function is404(error: AxiosError) {
  return error?.response?.status === 404;
}

export default client;
