import React, { ChangeEvent, useEffect, useState } from 'react';
import { css, styled, Theme, Tooltip, tooltipClasses } from '@mui/material';
import { Button, EmailInput, MInput, NotificationSetting, WorkspaceSetting } from '@savant-components/basic';
import { NotificationOptions, SlackChannel, SlackChannelSettings } from '@savant-components/builder';
import { Connection } from '@savant-components/catalog';
import { Icon, ThemeExtra } from '@savant-components/theme';
import { MDropdownComponent } from '@savant-components/basic';

const Styles = styled('div')(
  () => css`
    margin-top: 40px;
    margin-bottom: 200px;
    margin-left: 18px;

    & > .header-title {
      height: 32px;
      margin-bottom: 8px;
      margin-top: 30px;
    }

    & > .options-section {
      margin-top: 16px;
      display: flex;

      & > .global-notification {
        margin-right: 6px;
      }
    }

    & > .options {
      margin-top: 16px;
    }

    & > .destinations {
      margin-top: 16px;

      & > .email-section {
        margin-top: 20px;
        margin-bottom: 16px;
        margin-right: 500px;
      }

      & > .slack-section {
        margin-top: 20px;
        margin-bottom: 16px;
        margin-right: 500px;
      }
    }

    & > .notification-onsave {
      margin-top: 40px;
    }
  `,
);

const ExecutionExpirationTimeInput = ({
  workspaceSetting,
  setWorkspaceSetting,
}: {
  workspaceSetting: WorkspaceSetting | undefined;
  setWorkspaceSetting: (setting: WorkspaceSetting) => void;
}) => {
  const duration = {
    time: Number(workspaceSetting?.executionExpirationTime?.split(' ')[0]) || 2,
    period: workspaceSetting?.executionExpirationTime?.split(' ')[1] || 'YEARS',
  };

  const initialOption = duration.period.charAt(0) + duration.period.slice(1).toLowerCase();

  const [selectedPeriod, setSelectedPeriod] = useState<number>(duration.time);
  const [selectedOption, setSelectedOption] = useState<string>(initialOption);

  useEffect(() => {
    const executionExpirationTime = `${selectedPeriod} ${selectedOption.toUpperCase()}`;

    if (workspaceSetting?.executionExpirationTime !== executionExpirationTime) {
      setWorkspaceSetting({
        ...workspaceSetting,
        executionExpirationTime,
      });
    }
  }, [selectedPeriod, selectedOption, setWorkspaceSetting, workspaceSetting]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{
          width: '115px',
          marginRight: '5px',
        }}
      >
        <MInput
          label=""
          value={selectedPeriod}
          type="number"
          isCompact
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSelectedPeriod(Number(event.target.value))}
          fullWidth
        />
      </div>
      <MDropdownComponent
        value={selectedOption}
        options={['Years', 'Months']}
        isCompact
        allowTyping={false}
        onChange={value => setSelectedOption(value as string)}
      />
    </div>
  );
};

export function WorkspaceNotifications(props: {
  notificationText: string;
  theme: Theme & ThemeExtra;
  selected: string;
  setSelected: (selected: string) => void;
  destinationText: string;
  emails: string[];
  emails1: (recipients: string[]) => void;
  notificationSettings: NotificationSetting;
  notificationSettings1: (setting: NotificationSetting) => void;
  listChannels: (connectionId: string) => Promise<SlackChannel[]>;
  listSlackConnections: () => Promise<Connection[]>;
  listTeamsConnections: () => Promise<Connection[]>;
  discribeChannel: (connectionId: string, channelId: string) => Promise<SlackChannel>;
  onClick: () => void;
  isButtonDiabled: boolean;
  enabled: boolean;
  workspaceSetting: WorkspaceSetting | undefined;
  setWorkspaceSetting: (setting: WorkspaceSetting) => void;
  isPurgingExecutionEnabled: boolean;
}) {
  const tooptipProps = {
    tooltip: {
      sx: {
        color: '#191919',
        fontSize: '11',
        fontStyle: 'normal',
        boxShadow: 2,
        // Override MUI defaults
        bgcolor: 'rgb(255, 255, 255)',
        [`& .${tooltipClasses.arrow}`]: {
          color: 'rgb(255, 255, 255)',
        },
      },
    },
  };
  return props.enabled ? (
    <Styles>
      <div className="header-title text-t1">Workspace Notifications</div>
      <div className="options-section">
        <span className="global-notification text-t6">{props.notificationText}</span>
        <Tooltip
          componentsProps={tooptipProps}
          arrow
          placement="top"
          title="If desired, you can also set different notification rules globally (on the left)."
        >
          <div>
            <Icon
              sx={{
                fontSize: '15px',
                color: '#B0B4BF',
                ':hover': {
                  color: props.theme.colors.p1,
                },
              }}
              name="Info"
              variant="outlined"
            />
          </div>
        </Tooltip>
      </div>
      <div className="options">
        <NotificationOptions
          isViewMode={false}
          selected={props.selected}
          setSelected={ss => {
            props.setSelected(ss);
          }}
        />
      </div>
      <div className="header-title text-t3">Destinations</div>
      <span className="text-t6">{props.destinationText}</span>
      <div className="email-section">
        <div className="text-t7">Email Address</div>
        <EmailInput
          isViewMode={false}
          emails={props.emails}
          setEmails={props.emails1}
          notificationSettings={props.notificationSettings}
          setNotificationSettings={props.notificationSettings1}
        ></EmailInput>
      </div>
      <div className="slack-section">
        <div className="text-t7" style={{ marginTop: '10px', marginBottom: '20px' }}>
          Slack Channel
        </div>
        <SlackChannelSettings
          isViewMode={false}
          listChannels={props.listChannels}
          listConnections={props.listSlackConnections}
          discribeChannel={props.discribeChannel}
          notificationSettings={props.notificationSettings}
          setNotificationSettings={props.notificationSettings1}
          type="slack"
        />
      </div>
      <div className="teams-section">
        <div className="text-t7" style={{ marginTop: '10px', marginBottom: '20px' }}>
          Teams Channel
        </div>
        <SlackChannelSettings
          isViewMode={false}
          listChannels={props.listChannels}
          listConnections={props.listTeamsConnections}
          discribeChannel={props.discribeChannel}
          notificationSettings={props.notificationSettings}
          setNotificationSettings={props.notificationSettings1}
          type="teams"
        />
      </div>
      {props.isPurgingExecutionEnabled && (
        <>
          <div className="header-title text-t1">Data Governance Policy</div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '5px',
              }}
            >
              Keep run history for{' '}
            </span>
            <ExecutionExpirationTimeInput
              workspaceSetting={props.workspaceSetting}
              setWorkspaceSetting={props.setWorkspaceSetting}
            />
          </div>
        </>
      )}

      <div className="notification-onsave">
        <Button
          className="save-button"
          type={'submit'}
          design={'primary'}
          onClick={props.onClick}
          label={'Save'}
          disabled={props.isButtonDiabled}
        />
      </div>
    </Styles>
  ) : (
    <div></div>
  );
}
