import React, { MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useBusinessApp } from '../../hooks/useBusinessApp';
import { Loader, MarkdownParagraph, Spacer } from '@savant-components/basic';

import BackgroundIcon from '../../images/home_icon_bg.svg';
import { Button, useTheme } from '@savant-components/theme';
import { useIntl } from 'react-intl';
import { RecipeParameter, RecipeParameterSetter } from '@savant-components/catalog';
import { Box, Divider } from '@mui/material';
import { useDynamicOptionProvider } from '../../hooks/useDynamicOptionProvider';

const BusinessAppView = (): React.ReactElement => {
  const theme = useTheme();
  const intl = useIntl();
  const { appId } = useParams();
  const paramsInputLabel = intl.formatMessage({ id: 'bizApp.input' });
  const runAppLabel = intl.formatMessage({ id: 'bizApp.runApp' });
  const runAppConfirmLabel = intl.formatMessage({ id: 'bizApp.runAppConfirmation' });
  const { isLoading, app, runApp, isSubmitting, isReadyToSubmit, justSubmitted } = useBusinessApp({
    appId: appId || '',
  });
  const [parameters, setParameters] = React.useState<RecipeParameter[]>([]);
  const { getDynamicOptions } = useDynamicOptionProvider();
  useEffect(() => {
    setParameters(app?.parameters || []);
  }, [app]);

  return (
    <Box
      sx={{
        py: 3,
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(254.57deg, rgba(246, 245, 255, 0.7) 13.59%, rgba(212, 208, 255, 0.7) 97.83%)',
      }}
    >
      <Box sx={{ position: 'absolute', zIndex: 1, height: '95%' }}>
        <img src={BackgroundIcon} style={{ height: '100%' }} />
      </Box>
      {isLoading ? (
        <Loader size={200} />
      ) : (
        <Box
          id="app-content"
          sx={{
            backgroundColor: '#ffffffc7',
            border: `1px solid ${theme.colors.o1}`,
            width: 520,
            minWidth: '30em',
            //     //position: relative;
            zIndex: 2,
            borderRadius: '12px',
            padding: '30px 30px',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            //     .sub-title {
            //       margin-bottom: 1rem;
            //     }
            //     margin-top: 30px;
            //     margin-bottom: 30px;
          }}
        >
          {app && (
            <React.Fragment>
              <h3 className="text-t1 sub-title" style={{ marginBottom: '1rem' }}>
                {app?.name}
              </h3>
              {Boolean(app?.description) && (
                <MarkdownParagraph width={'100%'} content={app.description || ''} readOnly />
              )}
              <Divider sx={{ borderColor: theme.colors.muted }} />
              <Spacer direction="vertical" size="16px" />
              {parameters && parameters?.length > 0 && (
                <React.Fragment>
                  <h4>{paramsInputLabel}</h4>
                  <RecipeParameterSetter
                    parameters={parameters}
                    setParameters={setParameters}
                    getDynamicOptions={getDynamicOptions}
                  />
                  <Spacer direction="vertical" size="16px" />
                  <Divider className="divider" />
                </React.Fragment>
              )}

              <Spacer direction="vertical" size="16px" />
              <Box>
                <Button
                  variant="contained"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    runApp(runAppConfirmLabel, parameters);
                  }}
                  loading={isSubmitting}
                  disabled={!isReadyToSubmit || justSubmitted}
                >
                  {runAppLabel}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BusinessAppView;
