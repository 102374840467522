import React from 'react';

const LogoSvg = ({ width = 89, color }: { width?: number; color: string }): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 49.84" width={width}>
      <defs>
        <linearGradient id="GradientFill_1" x1="16.8" y1="-9.36" x2="46.21" y2="39.38" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#19b8d1" />
          <stop offset="0.25" stopColor="#20b8cc" />
          <stop offset="0.62" stopColor="#33b9be" />
          <stop offset="1" stopColor="#4ebbaa" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M29.57,1.2H63.86a10.63,10.63,0,0,0,4.2-.83A4.74,4.74,0,0,1,69.91,0a4.86,4.86,0,0,1,0,9.71,4.92,4.92,0,0,1-1.85-.36,10.46,10.46,0,0,0-4.2-.83H29.57a3.66,3.66,0,0,1,0-7.32ZM56.81,13.38a4.86,4.86,0,1,1,0,9.71A4.77,4.77,0,0,1,55,22.72a10.55,10.55,0,0,0-4.19-.83H16.47a3.67,3.67,0,0,1-3.66-3.66h0a3.67,3.67,0,0,1,3.66-3.66h34.3A10.38,10.38,0,0,0,55,13.74a5,5,0,0,1,1.85-.36Zm13,13.37a4.86,4.86,0,0,1,0,9.71A5,5,0,0,1,68,36.1a10.38,10.38,0,0,0-4.19-.83H29.5a3.67,3.67,0,0,1-3.66-3.66h0A3.66,3.66,0,0,1,29.5,28H63.79A10.55,10.55,0,0,0,68,27.12a4.79,4.79,0,0,1,1.86-.37ZM44,40.13a4.86,4.86,0,1,1,0,9.71,4.92,4.92,0,0,1-1.85-.36A10.46,10.46,0,0,0,38,48.64H3.66A3.67,3.67,0,0,1,0,45H0a3.67,3.67,0,0,1,3.66-3.66H38a10.45,10.45,0,0,0,4.19-.83A4.92,4.92,0,0,1,44,40.13Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const BrandSvg = ({ orgId }: { orgId?: string }): React.ReactElement => {
  const isCents = orgId === 'rqmfsupouf'; // APP-3238 - hard code for POC
  return (
    <img
      src={isCents ? 'https://assets.savantlabs.io/img/cents_brand.svg' : 'https://assets.savantlabs.io/brand/logo.svg'}
      height="34.25px"
    />
  );
};

export const Logo = React.memo(LogoSvg);
export const Brand = React.memo(BrandSvg);
