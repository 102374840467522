import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';
import { useUploader } from '../../services/uploader';
import { useMemo } from 'react';
import ApiEngine from '../../services/engine';
import { useNavigate } from 'react-router-dom';

export interface UseEngineReturn {
  engine: ApiEngine;
  // theme: Theme & ThemeExtra;
  // intl: IntlShape;
  // featureFlags: FeatureFlagContextModel;
}

export function useEngine(recipeId: string): UseEngineReturn {
  const uploader = useUploader();
  const intlShape = useIntl();
  const alert = useAlert();
  const navigate = useNavigate();
  return {
    engine: useMemo(() => new ApiEngine(uploader, intlShape, alert, recipeId, false, navigate), []),
  };
}
