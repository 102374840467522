import { DataFrame, LogicalDataType } from '@savant-components/basic';
import { FileType, ObjectSchema, ObjectTree, SourceConfigType } from '@savant-components/catalog';

import client, { followPromise, handleError } from './client';
import { UploadTemplateResponse } from '@savant-components/builder';

export async function deleteSource(id: string): Promise<void> {
  return client.delete(`/sources/${id}`).then(() => {
    return;
  });
}

export async function getSample(
  config: SourceConfigType,
  overwrittenTypes: { [key: string]: LogicalDataType },
): Promise<DataFrame> {
  if (!config?.fileId) {
    return { schema: [], data: [] };
  }
  const url = `/upload/files/${config?.fileId}/sample-async`;

  let payload: {
    overwrittenTypes: { [key: string]: LogicalDataType };
    tab?: string;
    skipRows?: number;
    tabPattern?: string;
    fileParserProps?: {
      delimiter?: string;
      qualifier?: string;
    };
    useTabPattern?: boolean;
    headerless?: boolean;
    dataFormat?: FileType;
  } = {
    skipRows: config?.skipRows,
    useTabPattern: config?.useTabPattern,
    overwrittenTypes,
    headerless: config?.headerless,
    dataFormat: config?.fileType,
  };

  if (config?.table && !config?.useTabPattern) {
    payload = {
      ...payload,
      tab: config?.table,
      fileParserProps: {
        delimiter: config?.delimiter,
        qualifier: config?.qualifier,
      },
    };
  } else if (config?.tabPattern && config?.useTabPattern) {
    payload = {
      ...payload,
      tabPattern: config?.tabPattern,
    };
  }

  return client.post(url, payload).then(resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as DataFrame;
    });
  });
}

export async function getObjectTree(id: string, fileType: FileType): Promise<ObjectTree> {
  return client.get(`/upload/files/${id}/tree-async?dataFormat=${fileType}`).then(async resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as ObjectTree;
    });
  });
}

export async function getObjectSchema(
  id: string,
  dataFormat: FileType,
  tab: string,
  skipRows?: number,
  tabPattern?: string,
  delimiter?: string,
  qualifier?: string,
  useTabPattern?: boolean,
  headerless?: boolean,
): Promise<ObjectSchema> {
  const url = `/upload/files/${id}/schema-async`;
  let payload: {
    tab?: string;
    skipRows?: number;
    tabPattern?: string;
    fileParserProps?: {
      delimiter?: string;
      qualifier?: string;
    };
    useTabPattern?: boolean;
    headerless?: boolean;
    dataFormat?: FileType;
  } = {
    skipRows,
    useTabPattern,
    headerless,
    dataFormat,
  };
  if (tab && !useTabPattern) {
    payload = {
      ...payload,
      tab,
      fileParserProps: {
        delimiter,
        qualifier,
      },
    };
  } else if (tabPattern && useTabPattern) {
    payload = {
      ...payload,
      tabPattern,
    };
  }
  return client.post(url, payload).then(async resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as ObjectSchema;
    });
  });
}

export async function getUploadedFiles(fileId: string): Promise<UploadTemplateResponse> {
  return client
    .get(`upload/files/${fileId}`)
    .then(resp => {
      return resp.data;
    })
    .catch(err => handleError(err));
}

export async function getFileSignedUrl(fileId: string): Promise<{ signedUrl: string }> {
  return client
    .get(`upload/files/${fileId}/signed-url`)
    .then(resp => {
      return resp.data;
    })
    .catch(err => handleError(err));
}
