import { NotificationSetting } from '@savant-components/basic';
import { useAlert } from 'react-alert';
import { useEffect, useState } from 'react';
import { handleError } from '../services/client';
import { getTabSession } from '../services/storage';
import {
  getNotificationSetting,
  saveNotificationSetting,
  updateNotificationSetting,
  listOrgSlackConnections as listOrgSlackConnectionsViaApi,
  listOrgTeamsConnections as listOrgTeamsConnectionsViaApi,
  listOrgSlackChannels as listOrgSlackChannelsViaApi,
  describeOrgSlackChannel as describeOrgSlackChannelViaApi,
} from '../services/notifications';
import { SlackChannel } from '@savant-components/builder';
import { Connection } from '@savant-components/catalog';

export function useNotifications(type: string): {
  isLoading: boolean;
  seeNotification: boolean;
  recipients: string[];
  setRecipients: (recipients: string[]) => void;
  setting: NotificationSetting;
  setSetting: (setting: NotificationSetting) => void;
  selected: string;
  setSelected: (selected: string) => void;
  updateSettings: () => void;
  isButtonDiabled: boolean;
  listOrgSlackConnections: () => Promise<Connection[]>;
  listOrgTeamsConnections: () => Promise<Connection[]>;
  listOrgSlackChannels: (connectionId: string) => Promise<SlackChannel[]>;
  describeOrgSlackChannel: (connectionId: string, channelId: string) => Promise<SlackChannel>;
} {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [seeNotification, setSeeNotification] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<string[]>();
  const [isButtonDiabled, setIsButtonDiabled] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setIsButtonDiabled(true);
    const session = getTabSession();
    const org = session.organization;
    if (org?.role === 'Admin') {
      setSeeNotification(true);
    }
    const orgId = session.orgId;
    getNotificationSetting(type, orgId, undefined)
      .then(data => {
        if (data) {
          setRecipients(data.emailInfo?.recipients);
          setSelected(data.option);
          setSetting(data);
        } else {
          saveNotificationSetting(
            [],
            type,
            selected,
            orgId,
            undefined,
            setting?.slackInfo?.connectionId,
            setting?.slackInfo?.channelId,
          )
            .then(saved => {
              setRecipients(saved.emailInfo?.recipients);
              setSelected(saved.option);
              setSetting(data);
            })
            .catch(err => handleError(err, alert));
        }
      })
      .catch(err => handleError(err, alert))
      .finally(() => setIsLoading(false));
  }, []);

  const [setting, setSetting] = useState<NotificationSetting>();
  const [selected, setSelected] = useState<string>('none');

  useEffect(() => {
    setIsButtonDiabled(false);
  }, [setting]);

  const updateSettings = () => {
    const session = getTabSession();
    const orgId = session.orgId;
    setIsButtonDiabled(true);
    updateNotificationSetting(
      recipients as string[],
      selected,
      type,
      orgId,
      undefined,
      setting?.slackInfo?.connectionId,
      setting?.slackInfo?.channelId,
      setting?.teamsInfo?.connectionId,
      setting?.teamsInfo?.channelId,
    )
      .then(data => {
        setSetting(data);
        alert.success('Settings has been saved.');
      })
      .catch(err => handleError(err, alert));
  };

  const listOrgSlackConnections = async (): Promise<Connection[]> => {
    return listOrgSlackConnectionsViaApi().catch(err => {
      handleError(err, alert);
      return [];
    });
  };

  const listOrgTeamsConnections = async (): Promise<Connection[]> => {
    return listOrgTeamsConnectionsViaApi().catch(err => {
      handleError(err, alert);
      return [];
    });
  };

  const listOrgSlackChannels = async (connectionId: string): Promise<SlackChannel[]> => {
    return listOrgSlackChannelsViaApi(connectionId).catch(err => {
      handleError(err, alert);
      return [];
    });
  };

  const describeOrgSlackChannel = async (connectionId: string, channelId: string): Promise<SlackChannel> => {
    return describeOrgSlackChannelViaApi(connectionId, channelId).catch(err => {
      handleError(err, alert);
      return undefined as unknown as SlackChannel;
    });
  };

  return {
    isLoading,
    seeNotification,
    recipients: recipients as string[],
    setRecipients,
    setting: setting as NotificationSetting,
    setSetting,
    selected,
    setSelected,
    updateSettings,
    isButtonDiabled,
    listOrgSlackConnections,
    listOrgTeamsConnections,
    listOrgSlackChannels,
    describeOrgSlackChannel,
  };
}
