import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const Seo = ({ title }: { title: string }): React.ReactElement => {
  const metaDescription = 'Site description';

  const intl = useIntl();
  const locale = intl.locale;

  return (
    <Helmet
      htmlAttributes={{
        locale,
      }}
      title={title}
      titleTemplate={title ? `%s | Savant` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@savant`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  );
};

export default Seo;
