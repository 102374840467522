import { useAlert } from 'react-alert';
import { useAsync } from '@savant-components/basic';
import { BizApp, RecipeParameter } from '@savant-components/catalog';
import { getBizApp, submitBizAppExec as submitBizAppExecViaAPI } from '../services/bizapp';
import { useState } from 'react';
import { handleError } from '../services/client';
import { AxiosError } from 'axios';

export function useBusinessApp({ appId }: { appId: string }): {
  isLoading: boolean;
  app?: BizApp;
  runApp: (confirmMsg: string, parameters: RecipeParameter[]) => Promise<void>;
  isSubmitting: boolean;
  isReadyToSubmit: boolean;
  justSubmitted: boolean;
} {
  const alert = useAlert();
  const { value: app, status } = useAsync<BizApp | undefined, AxiosError>(async () => {
    try {
      return await getBizApp(appId);
    } catch (err) {
      handleError(err as AxiosError, alert);
    }
  }, true);

  const isLoading = status === 'pending' || status === 'idle';
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [justSubmitted, setJustSubmitted] = useState<boolean>(false);
  const isReadyToSubmit = !isLoading && !isSubmitting;

  async function runApp(confirmMsg: string, parameters: RecipeParameter[]) {
    setIsSubmitting(true);
    return submitBizAppExecViaAPI(appId, parameters)
      .then(resp => {
        alert.success(confirmMsg);
        setJustSubmitted(true);
        setTimeout(() => {
          setJustSubmitted(false);
        }, 3000);
        return resp;
      })
      .catch(err => {
        handleError(err, alert);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return {
    app: app as BizApp,
    isLoading,
    runApp,
    isSubmitting,
    justSubmitted,
    isReadyToSubmit,
  };
}
