import axios from 'axios';
import queryString from 'query-string';
import client from './client';
import { Session, User } from '../types';
import { saveAuthResponse } from './login';
import { saveSession } from './storage';

export interface RegisterUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  password?: string;
  requirePassword?: boolean;
  // added fields
  state?: string;
  username?: string;
}

export interface SearchUserRequest {
  partialString: string;
}

export interface UpdateUserRequest {
  email: string;
  roleId: string;
}

export async function verifyEmail(req: RegisterUserRequest): Promise<void> {
  const params = queryString.parse(window.location.search);
  const query = queryString.stringify(params);
  const url = `/api/login/verify-email?${query}`;
  await axios.post(url, req);
}

export async function getUserRegistration(state: string): Promise<RegisterUserRequest> {
  const res = await axios.get(`/api/login/user-reg?state=${state}`);
  return res.data as RegisterUserRequest;
}

export async function listUsersInOrg(orgId: string): Promise<User[]> {
  const res = await client.get(`/organizations/${orgId}/users`);
  return res.data?.users;
}

export async function registerUser(req: RegisterUserRequest): Promise<Session> {
  const instance = req.password ? axios : client;
  const endpoint = req.password ? '/api/login/register' : '/users';
  const response = await instance.post<{ session: Session }>(endpoint, req);
  saveAuthResponse(response.data.session.auth);
  saveSession(response.data.session);
  return response.data.session;
}

export async function updateUser(
  updatedinfo: Partial<User & { newLastName: string; newFirstName: string; newAvatarFileId: string }>,
): Promise<User> {
  return client.put(`/users`, { ...updatedinfo }).then(resp => resp.data);
}
