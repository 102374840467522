import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../components/notfound';

const DeepLinkView = React.lazy(() => import('../components/DeepLinkView'));

const DeepLinkPage = (): React.ReactElement => {
  return (
    <Suspense fallback={<React.Fragment />}>
      <Routes>
        <Route path=":linkId" element={<DeepLinkView />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default DeepLinkPage;
