import React from 'react';

import _ from 'lodash';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@savant-components/theme';

import { AlertTemplate } from '@savant-components/basic';
import { Provider as AlertProvider, positions } from 'react-alert';

import { store } from './store';

import WithIntl from './components/WithIntl';
import NotFound from './components/notfound';
import { LANDING_URI } from './constants';
import { useAuth } from './hooks/auth';
import LoginCallback from './pages/callback/login';
import LogoutCallback from './pages/callback/logout';
import DeepLinkPage from './pages/deeplink';

// base on runtime context
function resolveBrowserLocale(languages: string[]): { locale: string; language: string } {
  // supported languages and locales
  const locales = _.uniq(_.map(languages, l => l.split('_')[0]));
  let blang = 'en_us';
  if (typeof window !== 'undefined') {
    blang = navigator.language;
    if (blang) {
      blang = blang.replace('-', '_').toLocaleLowerCase();
    }
  }
  let blocale = blang.split('_')[0];
  if (locales.indexOf(blocale) < 0) {
    // fall back to en, if not a supported local
    blocale = 'en';
    blang = 'en_us';
  }
  return {
    locale: blocale,
    language: blang,
  };
}

function ReloadWithIntl({ locale, prefix }: { locale: string; prefix: string }) {
  const href2 = window.location.href.replace(
    `${window.location.origin}/${prefix}`,
    `${window.location.origin}/${locale}/${prefix}`,
  );
  window.location.href = href2;
  return <div></div>;
}

function Initialize({ locale }: { locale: string }) {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    window.location.href = `/${locale}${LANDING_URI}`;
  } else {
    window.location.href = `/${locale}/login`;
  }
  return <React.Fragment></React.Fragment>;
}

function App() {
  const locale = resolveBrowserLocale(['de_de', 'en_us', 'es_es', 'fr_fr', 'pt_pt', 'zh_cn', 'zh_tw']);

  return (
    <ThemeProvider theme="light">
      <Provider store={store}>
        <AlertProvider
          template={AlertTemplate}
          timeout={5000}
          containerStyle={{
            zIndex: 2000,
          }}
          position={positions.BOTTOM_CENTER}
        >
          <BrowserRouter basename="/">
            <Routes>
              <Route path="/" element={<Outlet />}>
                <Route path="" element={<Initialize locale={locale.locale} />} />
                <Route path="callback/login" element={<LoginCallback />} />
                <Route path="callback/logout" element={<LogoutCallback />} />
                <Route path="deeplink/*" element={<DeepLinkPage />} />
                <Route path="de/*" element={<WithIntl locale="de" language={locale.language} />} />
                <Route path="en/*" element={<WithIntl locale="en" language={locale.language} />} />
                <Route path="es/*" element={<WithIntl locale="es" language={locale.language} />} />
                <Route path="fr/*" element={<WithIntl locale="fr" language={locale.language} />} />
                <Route path="pt/*" element={<WithIntl locale="pt" language={locale.language} />} />
                <Route path="zh/*" element={<WithIntl locale="zh" language={locale.language} />} />
                <Route path="login/*" element={<ReloadWithIntl locale="en" prefix="login" />} />
                <Route path="signup/*" element={<ReloadWithIntl locale="en" prefix="signup" />} />
                <Route path="app/*" element={<ReloadWithIntl locale="en" prefix="app" />} />
                <Route path="reset_password/*" element={<ReloadWithIntl locale="en" prefix="reset_password" />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AlertProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
