import client from './client';
import { User, Workspace } from '../types';
import { RegisterUserRequest, SearchUserRequest, UpdateUserRequest } from './user';

export async function listWorkspacesInOrg(orgId: string): Promise<Workspace[]> {
  const res = await client.get(`/organizations/${orgId}/workspaces`);
  return res.data?.workspaces;
}

export async function createWorkspace(orgId: string, workspaceName: string): Promise<void> {
  const payload = {
    name: workspaceName,
  };
  await client.post(`/organizations/${orgId}/workspaces`, payload);
}

export async function archiveWorkspace(orgId: string, workspaceId: string): Promise<void> {
  await client.delete(`/organizations/${orgId}/workspaces/${workspaceId}/archive`);
}

export async function addUsers(orgId: string, workspaceId: string, usersToAdd: RegisterUserRequest[]): Promise<User[]> {
  const res = await client.post(`/organizations/${orgId}/workspaces/${workspaceId}/users`, usersToAdd);
  return res.data?.users;
}

export async function removeUser(orgId: string, workspaceId: string, userToRemove: RegisterUserRequest): Promise<void> {
  await client.post(`/organizations/${orgId}/workspaces/${workspaceId}/removeuser`, userToRemove);
}

export async function updateUserRole(
  orgId: string,
  workspaceId: string,
  updateRequest: UpdateUserRequest,
): Promise<void> {
  await client.post(`/organizations/${orgId}/workspaces/${workspaceId}/updateuser`, updateRequest);
}

export async function updateWorkspace(orgId: string, workspaceId: string, newName: string): Promise<string> {
  const payload = {
    newName,
  };
  const res = await client.put(`/organizations/${orgId}/workspaces/${workspaceId}/update`, payload);
  return res.data.newName;
}

export async function searchUsersWithRequest(
  orgId: string,
  workspaceId: string,
  searchUserRequest: SearchUserRequest,
): Promise<User[]> {
  const res = await client.post(`/organizations/${orgId}/workspaces/${workspaceId}/search/users`, searchUserRequest);
  return res.data?.users;
}

export async function getWorkspaceDetail(
  orgId: string,
  workspaceId: string,
): Promise<
  {
    members: User[];
  } & Workspace
> {
  const res = await client.get(`/organizations/${orgId}/workspaces/${workspaceId}/detail`);
  return res.data;
}
