import React from 'react';
import { useEffect, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { IMessage } from '@stomp/stompjs';
import { ws } from '../../services/websocket';
import events from '../../events';

const WithWebSocket = ({
  children,
}: {
  children: string | React.ReactElement | React.ReactElement[];
}): React.ReactElement => {
  const [isWsStarted, setIsWsStarted] = useState<boolean>(false);
  useEffect(() => {
    if (!isWsStarted) {
      ws.init({
        onClose: () => setIsWsStarted(false),
        callback: (message: IMessage) => {
          try {
            const payload = JSON.parse(message.body);
            if (payload['type'] === 'session') {
              emitCustomEvent(events.NEW_SESSION, payload);
              return;
            } else if (payload.type === 'graph_status') {
              emitCustomEvent(events.WS_COMPUTE_GRAPH_RESP, payload);
            }
            ws.send({ type: 'ack', messageId: payload.messageId });
          } catch (e) {
            console.log('error parsing message', e);
          }
        },
      });
      setIsWsStarted(true);
    }
  }, [isWsStarted]);
  return <React.Fragment>{children}</React.Fragment>;
};
export default WithWebSocket;
