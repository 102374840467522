/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { FC, useCallback, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Breadcrumbs, Divider, Link as MLink } from '@mui/material';

import { FlexFiller, Loader, EditableText } from '@savant-components/basic';
import { useTheme } from '@savant-components/theme';
import { Recipe } from '@savant-components/catalog';

import { Brand } from '../logo';
import { getTabSession } from '../../services/storage';
import SessionControl from './SessionControl';
import { useRecipe } from '../../hooks/recipes';
import { LANDING_URI } from '../../constants';

export type AnalysisContextProps = {
  recipe?: Recipe;
  updateRecipeName: (name: string) => Promise<string>;
};
export const AnalysisContext = React.createContext<AnalysisContextProps>({
  updateRecipeName: () => new Promise(resolve => resolve('')),
});

const FlowBotSwitch: FC<{ tab: string; onSwitchTab: (tab: string) => void }> = ({ tab, onSwitchTab }) => {
  const intl = useIntl();
  const analysisLabel = intl.formatMessage({ id: 'app.analysis' });
  const botLabel = intl.formatMessage({ id: 'app.recipe.bot' });
  const theme = useTheme();
  const style = css`
    border: 1px solid ${theme.colors.o2};
    border-radius: 4px;
    height: 24px;
    display: flex;
    align-items: center;
    & > .switch-side {
      user-select: none;
      height: 100%;
      display: flex;
      align-items: center;
      width: 80px;
      & > span {
        width: 100%;
        text-align: center;
      }
      margin-left: -2px;
      margin-right: -2px;
    }
    & > .switch-active {
      color: ${theme.colors.f6};
      background: ${theme.colors.b10};
      border-radius: 4px;
      z-index: 2;
    }
    & > .switch-inactive {
      cursor: pointer;
      background: ${theme.colors.b4};
      :hover {
        color: ${theme.colors.f6};
        background: ${theme.colors.b11};
        border-radius: 4px;
      }
      z-index: 1;
    }
  `;
  return (
    <div id="flow-bot-switch" css={style}>
      <div
        className={`switch-side ${tab === 'flow' ? 'switch-active' : 'switch-inactive'}`}
        onClick={() => onSwitchTab('flow')}
      >
        <span className={`${tab === 'flow' ? 'text-t9' : 'text-t10'}`}>{analysisLabel}</span>
      </div>
      <Divider orientation="vertical" />
      <div
        className={`switch-side ${tab === 'bot' ? 'switch-active' : 'switch-inactive'}`}
        onClick={() => onSwitchTab('bot')}
      >
        <span className={`${tab === 'bot' ? 'text-t9' : 'text-t10'}`}>{botLabel}</span>
      </div>
    </div>
  );
};

const TopControlBar = ({
  recipeName,
  folderId,
  tab,
  updateRecipeName,
  switchTab,
}: {
  recipeName: string;
  folderId?: string;
  tab: string;
  updateRecipeName: (name: string) => Promise<string>;
  switchTab: (tab: string) => void;
}): JSX.Element => {
  const theme = useTheme();
  const intl = useIntl();
  const applicationLabel = intl.formatMessage({ id: 'app.applications' });
  const tabSession = getTabSession();

  const getAnalysisLink = useCallback(
    (folderId: string) => {
      if (folderId) {
        return `/${intl.locale}/app/analysis?folderId=${folderId}`;
      } else {
        return `/${intl.locale}/app/analysis`;
      }
    },
    [intl?.locale],
  );

  const onClickBrand = () => {
    window.location.href = `/${intl.locale}${LANDING_URI}`;
  };

  const onSwitchTab = (newTab: string) => {
    if (newTab !== tab) {
      switchTab(newTab);
    }
  };
  const style = css`
    user-select: none;
    height: 3rem;
    width: 100vw;
    position: relative;
    & > .float-center {
      float: left;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      display: flex;
      align-items: center;
    }
    & > .flex-container {
      padding: 0 1.5rem;
      border-bottom: 2px solid ${theme.palette.grey[300]};
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      .brand {
        margin-right: 1em;
        display: flex;
        align-items: center;
        padding: 0.5em;
        border-radius: 0.5em;
        cursor: pointer;
      }
      .flow-name-group {
        max-width: 20rem;
        .flow-name {
          line-height: 1.25rem;
          letter-spacing: 0.2px;
          font-weight: normal;
        }
      }
      .automate-btn {
        margin: 0 0.5rem;
      }
    }
  `;
  return (
    <div css={style}>
      <div className="flex-container">
        <div className="brand" onClick={onClickBrand}>
          <Brand orgId={tabSession.orgId} />
        </div>
        <Breadcrumbs aria-label="breadcrumb">
          <MLink underline="hover" color="inherit" href={getAnalysisLink(folderId as string)}>
            {applicationLabel}
          </MLink>
          <div></div>
        </Breadcrumbs>
        <EditableText text={recipeName} onUpdateText={updateRecipeName} size="t3" />
        <FlexFiller />
        <SessionControl />
      </div>
      <div className="float-center">
        <FlowBotSwitch tab={tab} onSwitchTab={onSwitchTab} />
      </div>
    </div>
  );
};

const AnalysisDetailLayout = (): React.ReactElement => {
  const { recipeId } = useParams() as { recipeId: string };
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();
  const tabSession = getTabSession();
  const tab = useMemo(() => {
    if (location.pathname.endsWith('/bot') || location.pathname.includes('/bot/')) {
      return 'bot';
    } else {
      return 'flow';
    }
  }, [location.pathname]);
  const switchTab = (newTab: string) => {
    if (tab !== newTab) {
      if (newTab == 'flow') {
        navigate(`/${intl.locale}/app/flow/${recipeId}?rns=${tabSession.namespace}`);
      } else if (newTab == 'bot') {
        navigate(`/${intl.locale}/app/flow/${recipeId}/bot/runs?rns=${tabSession.namespace}`);
      } else {
        navigate(`/${intl.locale}/app/flow/${recipeId}/${newTab}?rns=${tabSession.namespace}`);
      }
    }
  };

  const { recipe, isLoading, onUpdateName } = useRecipe({ recipeId });
  const folderId = recipe?.folderId;

  const style = css`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  `;
  const containerStyle = css`
    position: fixed;
    top: 3rem;
    left: 0;
    bottom: 0;
    right: 0;
  `;
  return (
    <div css={style}>
      {isLoading && <Loader size={200} />}
      {!isLoading && recipe && (
        <React.Fragment>
          <TopControlBar
            recipeName={recipe.name}
            folderId={folderId}
            updateRecipeName={onUpdateName}
            tab={tab}
            switchTab={switchTab}
          />
          <div css={containerStyle}>
            {recipe && (
              <AnalysisContext.Provider value={{ recipe, updateRecipeName: onUpdateName }}>
                <Outlet />
              </AnalysisContext.Provider>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default AnalysisDetailLayout;
