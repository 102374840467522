import { useAlert } from 'react-alert';

import { useAsync } from '@savant-components/basic';

import { handleError } from '../services/client';
import { getSession, getTabSession } from '../services/storage';
import { listUsersInOrg, UpdateUserRequest } from '../services/user';
import { useEffect } from 'react';
import { User } from '../types';
import { updateUserRole } from '../services/organization';
import { AxiosError } from 'axios';

// FIXME: fetch from api
const rolesInOrganization = [
  { id: '1', name: 'Admin' },
  { id: '2', name: 'Analyst' },
  { id: '3', name: 'Business User' },
];

export function containsAdmin(members: User[], email: string): boolean {
  if ((members || []).length > 0) {
    return (
      members.findIndex(member => {
        return member.email === email && member.role === 'Admin';
      }) >= 0
    );
  } else {
    return false;
  }
}

export function useUsers(): {
  users: User[];
  isLoading: boolean;
  isOrgAdmin: boolean;
  updateMemberRole: (email: string, role: string) => Promise<string>;
} {
  const alert = useAlert();
  const { value, status, error } = useAsync(() => {
    const session = getTabSession();
    return listUsersInOrg(session?.orgId).then(users => {
      return users.sort((a, b) => a.name.localeCompare(b.name));
    });
  }, true);
  const isLoading = status !== 'success' && status !== 'error';
  const curSession = getSession();
  const isOrgAdmin: boolean = (!isLoading && containsAdmin(value as User[], curSession.userId)) || false;

  useEffect(() => {
    if (error) {
      handleError(error as AxiosError, alert);
    }
  }, [error]);

  function updateMemberRole(email: string, role: string): Promise<string> {
    const roleId = rolesInOrganization.find(r => r.name.toLowerCase() === role.toLowerCase())?.id;
    if (email && roleId) {
      const updateRequest: UpdateUserRequest = {
        email,
        roleId,
      };
      const session = getTabSession();
      const orgId = session.orgId;
      return updateUserRole(orgId, updateRequest)
        .then(() => role)
        .catch(err => {
          handleError(err, alert);
          return '';
        });
    } else {
      return Promise.resolve('');
    }
  }

  return {
    users: value || [],
    isLoading,
    isOrgAdmin,
    updateMemberRole,
  };
}
