import client from './client';

export interface Event {
  event: string;
}

export async function emitEvent(event: Event): Promise<void> {
  return client.post(`/front-end-event/events`, event).then(() => {
    return;
  });
}
