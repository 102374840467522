import React from 'react';
import { useIntl } from 'react-intl';
import { useAuth, useRedirectNamespace } from '../hooks/auth';

import { removeAuthentication, removeTabSession, saveLandingUrl } from '../services/storage';

const RequireAuth = ({
  children,
}: {
  children: string | React.ReactElement | React.ReactElement[];
}): React.ReactElement => {
  const intl = useIntl();
  const locale = intl.locale;
  const { isAuthenticated, isStaledSession, isBootstrappingTab } = useAuth();
  useRedirectNamespace();
  if (isBootstrappingTab) {
    return <React.Fragment />;
  } else if (typeof window !== 'undefined') {
    if (!isAuthenticated && window.location.pathname !== `/${locale}/login`) {
      removeAuthentication();
      const landingUrl = window.location.href.replace(window.location.origin, '');
      saveLandingUrl(landingUrl);
      window.location.href = `/${locale}/login`;
    } else if (isStaledSession && window.location.pathname !== `/${locale}/login`) {
      removeTabSession();
      window.location.href = `/${locale}/`;
    } else {
      return <React.Fragment>{children}</React.Fragment>;
    }
  }
  return <React.Fragment />;
};
export default RequireAuth;
