import * as React from 'react';
import queryString from 'query-string';

import { removeSession } from '../../stores/session';
import { useAppDispatch } from '../../hooks';
import { removeAuthentication } from '../../services/storage';
import { Loader } from '@savant-components/basic';

const LogoutCallback = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  if (typeof window !== 'undefined') {
    const parsed = queryString.parse(location.search);
    const error = parsed.error;
    if (error) {
      const error_description = parsed.error_description;
      console.error(error_description);
    } else {
      removeAuthentication();
      dispatch(removeSession());
      setTimeout(() => (window.location.href = '/'), 0);
    }
  }
  return (
    <div style={{ height: '100vh' }}>
      <Loader size={100} />
    </div>
  );
};

export default LogoutCallback;
