import { createAction, createReducer } from '@reduxjs/toolkit';

import {
  getSession as getGlobalSession,
  saveSession as saveGlobalSession,
  removeSession as removeGlobalSession,
  getTabSession,
  removeTabSession,
} from '../services/storage';
import { Session, TabSession, User } from '../types';

export const saveSession = createAction<Session, 'saveSession'>('saveSession');
export const removeSession = createAction<void, 'removeSession'>('removeSession');

const session: Session = getGlobalSession();
const tabSession: TabSession = getTabSession();

let initialState: Session = {} as Session;
if (session) {
  initialState = { ...session, user: enhanceUserName(session.user as User) };
  if (tabSession) {
    initialState.tab = tabSession;
  }
}

function enhanceUserName(user: User): User {
  const name = user.name || (user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.username);
  return {
    ...user,
    name,
  };
}

export default createReducer(initialState, builder =>
  builder
    .addCase(saveSession, (_, action) => {
      const session: Session = action.payload;
      if (session.user) {
        session.user = enhanceUserName(session.user);
      }
      saveGlobalSession(session);
      return { ...session };
    })
    .addCase(removeSession, () => {
      removeTabSession();
      removeGlobalSession();
      return {} as Session;
    }),
);
