/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Tooltip, tooltipClasses } from '@mui/material';
import { EmailInput, Loader } from '@savant-components/basic';
import { NotificationOptions, SlackChannelSettings } from '@savant-components/builder';

import { UsersContext } from '@savant-components/catalog';
import React from 'react';
import { useIntl } from 'react-intl';
import { getUsersContext } from '../../contexts';
import { useNotifications } from '../../hooks/notifications';
import AdminLayout from './AdminLayout';
import { Button } from '@savant-components/basic';
import { Icon, useTheme } from '@savant-components/theme';

const NotificationsView = (): React.ReactElement => {
  const emailAddr = 'Email Address';
  const slackChannel = 'Slack Channel';
  const teamsChannel = 'Teams Channel';
  const intl = useIntl();
  const userCtx = React.useMemo(() => getUsersContext(intl), []);
  const theme = useTheme();
  const notificationText = 'Send notifications to an admin for bot jobs across all workspaces';
  const destinationText = 'Notifications can be sent to one or many email addresses and a Slack channel.';
  const {
    isLoading,
    seeNotification,
    recipients,
    setRecipients,
    setting,
    setSetting,
    selected,
    setSelected,
    updateSettings,
    isButtonDiabled,
    listOrgSlackConnections,
    listOrgTeamsConnections,
    listOrgSlackChannels,
    describeOrgSlackChannel,
  } = useNotifications('organization');
  const style = css`
    padding: 16px;
    padding-right: 4.5rem;
    height: 100%;
    margin-top: 40px;
    margin-bottom: 200px;
    margin-left: 32px;
    & > .header {
      height: 32px;
      margin-bottom: 8px;
      margin-top: 30px;
    }
    & > .options-section {
      margin-top: 16px;
      display: flex;
      & > .global-notification {
        margin-right: 6px;
      }
    }
    & > .options {
      margin-top: 16px;
    }
    & > .destinations {
      margin-top: 16px;
      & > .email-section {
        margin-top: 20px;
        margin-bottom: 16px;
        margin-right: 500px;
      }
      & > .slack-section {
        margin-top: 20px;
        margin-bottom: 16px;
        margin-right: 500px;
      }
    }
    & > .notification-onsave {
      margin-top: 40px;
    }
  `;

  const tooptipProps = {
    tooltip: {
      sx: {
        color: '#191919',
        fontSize: '11',
        fontStyle: 'normal',
        boxShadow: 2,
        // Override MUI defaults
        bgcolor: 'rgb(255, 255, 255)',
        [`& .${tooltipClasses.arrow}`]: {
          color: 'rgb(255, 255, 255)',
        },
      },
    },
  };

  return (
    <AdminLayout module="notifications">
      <div css={style}>
        {seeNotification &&
          (isLoading ? (
            <Loader size={200} />
          ) : (
            <UsersContext.Provider value={userCtx}>
              <div className="header text-t1">Global Notifications</div>
              <div className="options-section">
                <span className="global-notification text-t6">{notificationText}</span>
                <Tooltip
                  componentsProps={tooptipProps}
                  arrow
                  placement="top"
                  title="If desired, you can also set different notification rules for each workspace by navigating to them (on the left)."
                >
                  <div>
                    <Icon
                      sx={{
                        fontSize: '15px',
                        color: '#B0B4BF',
                        ':hover': {
                          color: theme.colors.p1,
                        },
                      }}
                      name="Info"
                      variant={'outlined'}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="options">
                <NotificationOptions
                  isViewMode={false}
                  selected={selected}
                  setSelected={setSelected}
                ></NotificationOptions>
              </div>
              <div className="header text-t1">Destinations</div>
              <div className="destinations">
                <span className="text-t6">{destinationText}</span>
                <div className="email-section">
                  <div className="text-t7">{emailAddr}</div>
                  <EmailInput
                    isViewMode={false}
                    emails={recipients}
                    setEmails={setRecipients}
                    notificationSettings={setting}
                    setNotificationSettings={setSetting}
                  ></EmailInput>
                </div>
                <div className="slack-section">
                  <div className="text-t7" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    {slackChannel}
                  </div>
                  <SlackChannelSettings
                    isViewMode={false}
                    listChannels={listOrgSlackChannels}
                    listConnections={listOrgSlackConnections}
                    discribeChannel={describeOrgSlackChannel}
                    notificationSettings={setting}
                    setNotificationSettings={setSetting}
                    type="slack"
                  />
                </div>
                <div className="teams-section">
                  <div className="text-t7" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    {teamsChannel}
                  </div>
                  <SlackChannelSettings
                    isViewMode={false}
                    listChannels={listOrgSlackChannels}
                    listConnections={listOrgTeamsConnections}
                    discribeChannel={describeOrgSlackChannel}
                    notificationSettings={setting}
                    setNotificationSettings={setSetting}
                    type="teams"
                  />
                </div>
              </div>
              <div className="notification-onsave">
                <Button
                  className="save-button"
                  type={'submit'}
                  design={'primary'}
                  onClick={updateSettings}
                  label={'Save'}
                  disabled={isButtonDiabled}
                />
              </div>
            </UsersContext.Provider>
          ))}
      </div>
    </AdminLayout>
  );
};

export default NotificationsView;
