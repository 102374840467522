/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Workspace, WorkspaceContext, Workspaces } from '@savant-components/catalog';

import AdminLayout from './AdminLayout';
import { getWorkspaceContext } from '../../contexts';
import { Loader } from '@savant-components/basic';
import { useWorkspaces } from '../../hooks/workspaces';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '../../hooks/users';

const WorkspaceDetailView = (): React.ReactElement => {
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');
  const ctx = React.useMemo(() => getWorkspaceContext(intl), []);
  const { workspaces, isLoading, onArchive, onAddWorkspace } = useWorkspaces();
  const { isOrgAdmin } = useUsers();
  const navigate = useNavigate();
  const onClickDetail = (ws: Workspace) => {
    navigate(`/${intl.locale}/app/admin/workspace/${ws.id}`);
  };
  const style = css`
    padding: 16px;
    padding-right: 4.5rem;
    height: 100%;
  `;
  return (
    <AdminLayout module="workspaces">
      <div css={style}>
        {isLoading ? (
          <Loader size={200} />
        ) : (
          <WorkspaceContext.Provider value={ctx}>
            <Workspaces
              workspaces={workspaces}
              onAddWorkspace={onAddWorkspace}
              onDetail={onClickDetail}
              onArchive={onArchive}
              searchText={searchText}
              setSearchText={setSearchText}
              isOrgAdmin={isOrgAdmin}
            />
          </WorkspaceContext.Provider>
        )}
      </div>
    </AdminLayout>
  );
};

export default WorkspaceDetailView;
