import { getTabSession, saveTabSession } from '../services/storage';
import { Organization } from '../types';
import { useAlert } from 'react-alert';
import { updateLogo, updateName } from '../services/organization';
import { handleError } from '../services/client';
import { useState } from 'react';

export function useOrgProfile(): {
  organization: Organization;
  updateOrgName: (name: string) => Promise<string>;
  updateOrgLogo: (fileId: string) => Promise<string>;
} {
  const session = getTabSession();
  const alert = useAlert();
  const [organization, setOrganization] = useState<Organization>(session.organization as Organization);
  const updateOrgName = async (name: string): Promise<string> => {
    return updateName(name)
      .then(n => {
        const organization2 = {
          ...organization,
          name: n,
        };
        setOrganization(organization2);
        saveTabSession({
          ...session,
          organization: organization2,
        });
        return n;
      })
      .catch(err => {
        handleError(err, alert);
        return session.organization?.name as unknown as string;
      });
  };

  const updateOrgLogo = async (fileId: string): Promise<string> => {
    return updateLogo(fileId)
      .then(org => {
        const organization2 = {
          ...organization,
          ...org,
        };
        setOrganization(organization2);
        saveTabSession({
          ...session,
          organization: organization2,
        });
        return organization2?.logoUrl as string;
      })
      .catch(err => {
        handleError(err, alert);
        return session.organization?.logoFileId as unknown as string;
      });
  };

  return {
    organization,
    updateOrgName,
    updateOrgLogo,
  };
}
