/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Loader, Uploader } from '@savant-components/basic';
import { AggregatedUploadFileSourceState as AggregatedState, UploadFileSource } from '@savant-components/catalog';

import { useUploader } from '../../services/uploader';
import { getSchema } from '../../services/source';
import { useUploadFileSource } from '../../hooks/source';
import { useTheme } from '@savant-components/theme';
import { getAddSourceWizardContext, removeAddSourceWizardContext, saveNewSourceNode } from '../../services/storage';

const UploadSourceView = (): JSX.Element => {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  const locale = intl.locale;
  const uploader: Uploader = useUploader();
  const { isLoading, confirmAddSource, configService } = useUploadFileSource();

  const params = useParams();
  const onCancelAdd = () => {
    let cancelUrl = `/${locale}/app/source`;
    const ctx = getAddSourceWizardContext();
    if (ctx === 'new-connection') {
      // impossible
      cancelUrl = `/${locale}/app/connection`;
    } else if (ctx !== undefined && ctx.startsWith('edit-recipe')) {
      cancelUrl = ctx.replace('edit-recipe', `/${locale}/app/flow`);
    }
    removeAddSourceWizardContext();
    window.location.href = cancelUrl;
  };
  const onConfirmAdd = (state: AggregatedState) => {
    confirmAddSource({ ...state, sourceId: params.sourceId } as any).then(source => {
      let returnUrl = `/${locale}/app/source`;
      const ctx = getAddSourceWizardContext();
      if (ctx === 'first-recipe' || (ctx !== undefined && ctx.startsWith('edit-recipe'))) {
        getSchema(source?.id || '').then(schema => {
          saveNewSourceNode({
            ...source,
            schema,
            position: state?.upload?.position,
            createdTime: undefined,
            preSourceId: state?.upload?.preSourceId,
          } as any);
          if (ctx.startsWith('edit-recipe')) {
            returnUrl = ctx.replace('edit-recipe', `/${locale}/app/flow`);
          } else {
            returnUrl = `/${locale}/app/flow/create`;
          }
          removeAddSourceWizardContext();

          navigate(returnUrl);
        });
        returnUrl = undefined as unknown as string;
      }
      if (returnUrl !== undefined) {
        removeAddSourceWizardContext();

        navigate(returnUrl);
      }
    });
  };

  const style = css`
    height: calc(100vh - 4rem);
    // padding: 2rem;
    .container {
      height: 100%;
      // background: ${theme.colors.background};
      // border-radius: 1em;
    }
  `;
  return (
    <React.Fragment>
      <div css={style}>
        <div className="container">
          {isLoading ? (
            <Loader size={200} />
          ) : (
            <UploadFileSource
              sourceId={params.sourceId}
              uploader={uploader}
              onCancel={onCancelAdd}
              onConfirm={onConfirmAdd}
              configService={configService}
              getAddSourceWizardContext={getAddSourceWizardContext}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadSourceView;
