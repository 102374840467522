import { Connection, Source } from '@savant-components/catalog';
import { Authentication, BuilderState, Session, TabSession, User } from '../types';
import { DeepLink } from './deeplink';

// local store
const AUTHENTICATION = 'savant_v3';
const IDP_PROVIDER = 'idp_provider';
const SESSION = 'session_v2';
const LAST_TAB_IDS = 'last_tab_ids';

// session store
const PKCE_CODE_VERIFIER = 'pkce_code_verifier';
const LANDING_URL = 'landing_url';
const REDIRECT_STATE = 'redirect_state';
const TAB_SESSION = 'tab_session';
const ADD_CONNECTION_WIZARD_CONTEXT = 'add_connection_wizard_context';
const ADD_SOURCE_WIZARD_CONTEXT = 'add_source_wizard_context';
const FLOW_BUILDER_CONTEXT = 'flow_builder_context';
const NEW_SOURCE_NODE = 'new_source_node';
const NEW_DEST_NODE = 'new_dest_node';
const BUILDER_STATE = 'builder_state';
const DEEP_LINK = 'deeplink';

function deleteLocalStorageKeysIncluding(item: string) {
  for (const key of Object.keys(localStorage)) {
    if (key.includes(item)) {
      localStorage.removeItem(key);
    }
  }
}

function btoa64EncodeUnicode(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    }),
  );
}

// Decoding base64 ⇢ UTF-8

function atob64DecodeUnicode(str: string): string {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
}
export function clearLocalStorage() {
  // removeAuthentication();
  removeIdpProvider();
  // removeSession();
  // removeLatestTabSession();

  // delete analysis table filters
  const host = window.location.host;
  localStorage.removeItem(`${host}showOptions`);
  localStorage.removeItem(`${host}filterOptions`);
  // remove debugMode flags for all analysys
  deleteLocalStorageKeysIncluding('isDebugSessionMode');
  // remove panel state for all analysys
  deleteLocalStorageKeysIncluding('focus-panel');
  deleteLocalStorageKeysIncluding('config-panel');
  deleteLocalStorageKeysIncluding('inpector-panel');
}

export function clearSessionStorage() {
  removePkceCodeVerifier();
  removeDeepLink();
  // removeLandingUrl();
  removeAddConnectionWizardContext();
  removeAddSourceWizardContext();
  removeFlowBuilderContext();
  removeNewSourceNode();
  removeNewDestNode();
  removeBuilderState();
  // removeTabSession();
  removeRedirectState();

  // TODO: clear session storage related to filter state
}

function inNameSpace(key: string) {
  return `savant/${key}`;
}

function enhanceUserName(user: User): User {
  const name = user.name || (user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.username);
  return {
    ...user,
    name,
  };
}

// localStorage
function getItem<T>(key: string): T {
  if (typeof window !== 'undefined') {
    const item = window.localStorage.getItem(inNameSpace(key));
    if (item) {
      return JSON.parse(atob64DecodeUnicode(item)) as T;
    } else {
      return undefined as unknown as T;
    }
  } else {
    return undefined as unknown as T;
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function saveItem(key: string, item: any): void {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(inNameSpace(key), btoa64EncodeUnicode(JSON.stringify(item)));
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

function removeItem(key: string): void {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(inNameSpace(key));
  }
}

// sessionStorage
function getSessionItem<T>(key: string): T {
  if (typeof window !== 'undefined') {
    const item = window.sessionStorage.getItem(inNameSpace(key));
    if (item) {
      return JSON.parse(atob64DecodeUnicode(item)) as T;
    } else {
      return undefined as unknown as T;
    }
  } else {
    return undefined as unknown as T;
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function saveSessionItem(key: string, item: any) {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(inNameSpace(key), btoa64EncodeUnicode(JSON.stringify(item)));
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

function removeSessionItem(key: string): void {
  if (typeof window !== 'undefined') {
    window.sessionStorage.removeItem(inNameSpace(key));
  }
}

// In local storage
export function saveIdpProvider(provider: string): void {
  saveItem(IDP_PROVIDER, provider);
}

export function getIdpProvider(): string | undefined {
  return getItem(IDP_PROVIDER);
}

export function removeIdpProvider(): void {
  removeItem(IDP_PROVIDER);
}

export function saveAuthentication(auth: Authentication): void {
  saveItem(AUTHENTICATION, auth);
}

export function getAuthentication(): Authentication {
  removeItem('authentication');
  return getItem(AUTHENTICATION);
}

// prevent staled tabs
export function getUpToDateAuth(): Authentication | undefined {
  const auth = getAuthentication();
  if (auth) {
    const session = getSession();
    const tab = getTabSession();
    if (tab && tab.sessionId !== session?.sessionId) {
      throw new Error('Tab session is expired, please refresh the page');
    }
  }
  return auth;
}

export function removeAuthentication(): void {
  removeItem(AUTHENTICATION);
}

export function saveSession(session: Session): void {
  if (session.user) {
    session.user = enhanceUserName(session.user);
  }
  saveItem(SESSION, session);
}

export function getSession(): Session {
  removeItem('session');
  return getItem(SESSION);
}

export function removeSession(): void {
  removeLatestTabSession();
  removeItem(SESSION);
}

function saveLatestTabSession(tab: TabSession): void {
  saveItem(TAB_SESSION, tab);
  const session = getSession();
  if (session?.user?.email) {
    saveLastTabId(session.user.email, tab.tabId);
  }
}

export function getLatestTabSession(): TabSession {
  return getItem(TAB_SESSION);
}

function removeLatestTabSession(): void {
  removeItem(TAB_SESSION);
}

function saveLastTabId(email: string, sessionId: string): void {
  const ids: { [key: string]: string } = getItem(LAST_TAB_IDS) || {};
  ids[email] = sessionId;
  saveItem(LAST_TAB_IDS, ids);
}

export function getLastTabId(): string {
  const session = getSession();
  if (session?.user?.email) {
    return getLastTabIdByEmail(session.user.email);
  } else {
    return undefined as unknown as string;
  }
}

function getLastTabIdByEmail(email: string): string {
  removeItem('last_session_ids');
  const ids: { [key: string]: string } = getItem(LAST_TAB_IDS);
  return ids ? ids[email] : (undefined as unknown as string);
}

// In session storage
export function savePkceCodeVerifier(verifier: string): void {
  saveSessionItem(PKCE_CODE_VERIFIER, verifier);
}

export function getPkceCodeVerifier(): string | undefined {
  return getSessionItem(PKCE_CODE_VERIFIER);
}

export function removePkceCodeVerifier(): void {
  removeSessionItem(PKCE_CODE_VERIFIER);
}

export function saveDeepLink(deepLink: DeepLink): void {
  saveSessionItem(DEEP_LINK, deepLink);
}

export function getDeepLink(): DeepLink | undefined {
  return getSessionItem(DEEP_LINK);
}

export function removeDeepLink(): void {
  removeSessionItem(DEEP_LINK);
}

export function saveLandingUrl(url: string): void {
  saveSessionItem(LANDING_URL, url);
}

export function getLandingUrl(): string | undefined {
  return getSessionItem(LANDING_URL);
}

export function removeLandingUrl(): void {
  removeSessionItem(LANDING_URL);
}

export function saveAddConnectionWizardContext(context: string): void {
  saveSessionItem(ADD_CONNECTION_WIZARD_CONTEXT, context);
}

export function getAddConnectionWizardContext(): string {
  return getSessionItem(ADD_CONNECTION_WIZARD_CONTEXT);
}

export function removeAddConnectionWizardContext(): void {
  removeSessionItem(ADD_CONNECTION_WIZARD_CONTEXT);
}

export function saveAddSourceWizardContext(context: string): void {
  saveSessionItem(ADD_SOURCE_WIZARD_CONTEXT, context);
}

export function getAddSourceWizardContext(): string {
  return getSessionItem(ADD_SOURCE_WIZARD_CONTEXT);
}

export function removeAddSourceWizardContext(): void {
  removeSessionItem(ADD_SOURCE_WIZARD_CONTEXT);
}

export function saveFlowBuilderContext(context: string): void {
  saveSessionItem(FLOW_BUILDER_CONTEXT, context);
}

export function getFlowBuilderContext(): string {
  return getSessionItem(FLOW_BUILDER_CONTEXT);
}

export function removeFlowBuilderContext(): void {
  removeSessionItem(FLOW_BUILDER_CONTEXT);
}

export function saveNewSourceNode(source: Source): void {
  saveSessionItem(NEW_SOURCE_NODE, source);
}

export function getNewSourceNode(): Source {
  return getSessionItem(NEW_SOURCE_NODE);
}

export function removeNewSourceNode(): void {
  removeSessionItem(NEW_SOURCE_NODE);
}

export function saveNewDestNode(conn: Connection): void {
  saveSessionItem(NEW_DEST_NODE, conn);
}

export function getNewDestNode(): Connection {
  return getSessionItem(NEW_DEST_NODE);
}

export function removeNewDestNode(): void {
  removeSessionItem(NEW_DEST_NODE);
}

export function saveBuilderState(state: BuilderState): void {
  saveSessionItem(BUILDER_STATE, state);
}

export function getBuilderState(): BuilderState {
  return getSessionItem(BUILDER_STATE);
}

export function removeBuilderState(): void {
  removeSessionItem(BUILDER_STATE);
}

export function saveTabSession(tab: TabSession): void {
  saveSessionItem(TAB_SESSION, tab);
  saveLatestTabSession(tab);
}

export function getTabSession(): TabSession {
  const tab = getSessionItem(TAB_SESSION);
  return tab as TabSession;
  // if (tab == undefined) {
  //   const latestTab = getLatestTabSession();
  //   if (latestTab) {
  //     saveTabSession(latestTab);
  //   }
  //   return latestTab;
  // } else {
  //   return tab as TabSession;
  // }
}

export function removeTabSession(): void {
  removeSessionItem(TAB_SESSION);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function saveRedirectState(state: any): void {
  saveSessionItem(REDIRECT_STATE, state);
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */

export function getRedirectState(): any | undefined {
  return getSessionItem(REDIRECT_STATE);
}

export function removeRedirectState(): void {
  removeSessionItem(REDIRECT_STATE);
}
/* eslint-enable @typescript-eslint/no-explicit-any */
