/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { Link as MLink } from '@mui/material';
import { useTheme } from '@savant-components/theme';
import { ClickableIcon } from '@savant-components/basic';

const NotFound = (): React.ReactElement => {
  const theme = useTheme();

  const onClickHomepage = () => {
    window.location.href = `/`;
  };

  const rootStyle = css`
    .content-404 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      .image-404 {
        max-width: 100%;
        display: inline-block;
      }
      .header {
        margin-top: 100px;
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: bold;
        color: #3528ab;
      }
      .desciprtion {
        max-width: 490px;
        margin-left: auto;
        margin-right: auto;
        font-family: Inter, Helvetica, Arial, sans-serif;
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: normal;
      }
      .btn-row {
        margin-top: 50px;
        color: ${theme.colors.f7};
        .mlink:hover {
          font-weight: bolder;
          color: ${theme.colors.f8};
          text-decoration-color: ${theme.colors.p2};
        }
      }
      }
    }
  `;
  return (
    <div css={rootStyle}>
      <div className="content-404">
        <div className="image-404">
          <img src={'https://assets.savantlabs.io/img/404.svg'} />
        </div>
        <h1 className="header">{`We couldn't connect the dots. `}</h1>
        <p className="desciprtion">{`This page was not found. You may have mistyped the address or the page may have moved.`}</p>
        <div className="btn-row text-t5">
          <ClickableIcon color={theme.colors.p1} size={24} icon={['las', 'arrow-left']} onClick={onClickHomepage} />
          <MLink underline="none" className="mlink" onClick={onClickHomepage}>
            Back to homepage
          </MLink>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
