import client from './client';
import { Organization, Workspace, Session, TabSession } from '../types';

export const getBackendSession = async (): Promise<Session> => {
  return client.get(`/sessions`).then(resp => {
    return resp.data;
  });
};

export const getAccessibleOrganizations = async (): Promise<Organization[]> => {
  return client.get(`/sessions/organizations`).then(resp => {
    return resp.data.organizations;
  });
};

export const getAccessibleWorkspaces = async (): Promise<Workspace[]> => {
  return client.get(`/sessions/workspaces`).then(resp => {
    return resp.data.workspaces;
  });
};

export const switchOrg = async (toOrgId: string): Promise<TabSession> => {
  return client.put(`/sessions/organization`, { toOrgId }).then(resp => {
    return resp.data as TabSession;
  });
};

export const switchWorkspace = async (toWorkspaceId: string): Promise<TabSession> => {
  return client.put(`/sessions/workspace`, { toWorkspaceId }).then(resp => {
    return resp.data as TabSession;
  });
};

export const switchNamespace = async (toNamespace: string): Promise<TabSession> => {
  return client.put(`/sessions/namespace`, { toNamespace }).then(resp => {
    return resp.data as TabSession;
  });
};
