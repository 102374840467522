/* eslint-disable @typescript-eslint/no-explicit-any */
import queryString from 'query-string';

import { ConnectionInfo, DataFrame, LogicalDataType } from '@savant-components/basic';
import {
  ApiKeyAuthRequest,
  AwsAuthRequest,
  BasicAuthRequest,
  Compression,
  Connection,
  Connector,
  DbAuthRequest,
  FileType,
  IDataPullWindow,
  OAuthRequest,
  ObjectPath,
  ObjectSchema,
  ObjectTree,
  SourceConfigType,
  SshAuthRequest,
} from '@savant-components/catalog';
import { SlackChannel } from '@savant-components/builder';

import client, { ApiPromise, followPromise } from './client';
import { GenericApiRequest, GoogleSARequest } from '@savant-components/catalog/build/Authentication/submit/types';
import { AxiosError } from 'axios';

export interface RedirectOAuthRequest {
  connector: Connector;
  externalId: string;
  environment: string;
  clientId?: string;
  clientSecret?: string;
  returnUrl: string;
  agencyId?: string;
  advertiserId?: string;
}

export interface AuthenticateSshClientRequest {
  connector: Connector;
  client: SshAuthRequest;
}

export interface AuthenticateDbClientRequest {
  connector: Connector;
  client: DbAuthRequest;
}

export interface AuthenticateApiKeyClientRequest {
  connector: Connector;
  client: ApiKeyAuthRequest;
}

export interface AuthenticateBasicAuthClientRequest {
  connector: Connector;
  client: BasicAuthRequest;
}

export interface AuthenticateOAuthClientRequest {
  connector: Connector;
  client: OAuthRequest;
}

export interface AuthenticateAwsAuthClientRequest {
  connector: Connector;
  client: AwsAuthRequest;
}

export interface AuthenticateGenricApiAuthClientRequest {
  connector: Connector;
  client: GenericApiRequest;
}

export interface AuthenticateGoogleSAAuthClientRequest {
  connector: Connector;
  client: GoogleSARequest;
}

export interface UpdateConnectionRequest {
  name: string;
  description: string;
  authId?: string;
}

export interface GetTabsResponse {
  tabs: string[];
}

export async function redirectOAuth(req: RedirectOAuthRequest): Promise<string> {
  return client.post(`/connectors/init-oauth`, req).then(resp => {
    return resp.data.redirectUrl;
  });
}

export async function authenticateCustomOAuth(req: AuthenticateOAuthClientRequest): Promise<Connection> {
  return client
    .post(`/connectors/oauth`, {
      ...req,
      client: {
        ...req.client,
        authType: 'oauth',
      },
    })
    .then(resp => {
      const conn = resp.data;
      return {
        ...conn,
        type: conn.connector,
      };
    });
}

export async function authenticateSsh(req: AuthenticateSshClientRequest): Promise<Connection> {
  return client
    .post(`/connectors/ssh`, {
      ...req,
      client: {
        ...req.client,
        authType: 'ssh',
      },
    })
    .then(resp => {
      const conn = resp.data;
      return {
        ...conn,
        type: conn.connector,
      };
    });
}

export async function authenticateDb(req: AuthenticateDbClientRequest): Promise<Connection> {
  const dbClient = {
    ...req.client,
    authType: 'database',
  };
  if (dbClient.sshClient) {
    dbClient.sshClient.authType = 'ssh';
  }
  return client
    .post(`/connectors/db-async`, {
      ...req,
      client: dbClient,
    })
    .then(resp => {
      const promise: ApiPromise<Connection> = resp.data;
      promise.createdTime = new Date();
      return followPromise(promise, undefined).then(result => {
        return {
          ...result,
          type: result.connector,
        } as unknown as Connection;
      });
    });
}

export async function authenticateApiKey(req: AuthenticateApiKeyClientRequest): Promise<Connection> {
  return client
    .post(`/connectors/apiKey`, {
      ...req,
      client: {
        ...req.client,
        authType: 'apiKey',
      },
    })
    .then(resp => {
      const conn = resp.data;
      return {
        ...conn,
        type: conn.connector,
      };
    });
}

export async function authenticateGenericApi(req: AuthenticateGenricApiAuthClientRequest): Promise<Connection> {
  return client
    .post(`/connectors/api-async`, {
      ...req,
      client: {
        ...req.client,
        authType: 'api',
      },
    })
    .then(async resp => {
      const promise: ApiPromise<Connection> = resp.data;
      promise.createdTime = new Date();
      return followPromise(promise, undefined).then(result => {
        return {
          ...result,
          type: result.connector,
        } as unknown as Connection;
      });
    });
}

export async function authenticateBasicAuth(req: AuthenticateBasicAuthClientRequest): Promise<Connection> {
  return client
    .post(`/connectors/basic`, {
      ...req,
      client: {
        ...req.client,
        authType: 'basic',
      },
    })
    .then(resp => {
      const conn = resp.data;
      return {
        ...conn,
        type: conn.connector,
      };
    });
}

export async function authenticateAwsAuth(req: AuthenticateAwsAuthClientRequest): Promise<Connection> {
  return client
    .post(`/connectors/aws`, {
      ...req,
      client: {
        ...req.client,
        authType: 'aws',
      },
    })
    .then(resp => {
      const conn = resp.data;
      return {
        ...conn,
        type: conn.connector,
      };
    });
}

export async function authenticateGoogleSAAuth(req: AuthenticateGoogleSAAuthClientRequest): Promise<Connection> {
  return client
    .post(`/connectors/googlesa`, {
      ...req,
      client: {
        ...req.client,
        authType: 'googleSA',
      },
    })
    .then(resp => {
      const conn = resp.data;
      return {
        ...conn,
        type: conn.connector,
      };
    });
}

export async function getConnection(id: string): Promise<Connection> {
  return client.get(`/connections/${id}`).then(resp => {
    const conn = resp.data;
    return {
      ...conn,
      type: conn.connector,
    };
  });
}

export async function updateConnection(id: string, req: UpdateConnectionRequest): Promise<Connection> {
  return client.put(`/connections/${id}`, req).then(resp => {
    const conn = resp.data;
    return {
      ...conn,
      type: conn.connector,
    };
  });
}

export async function getConnectionInfo(id: string): Promise<ConnectionInfo> {
  return client.get(`/connections/${id}/info`).then(resp => {
    return resp.data;
  });
}

export async function getObjectTree(id: string, action: 'READ' | 'WRITE'): Promise<ObjectTree> {
  return client.get(`/connections/${id}/tree-async?action=${action}`).then(async resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as ObjectTree;
    });
  });
}

export async function getObjectSchema(
  id: string,
  objectPath: ObjectPath,
  action: 'READ' | 'WRITE',
): Promise<ObjectSchema> {
  const query = queryString.stringify({
    ...objectPath,
    action,
  });
  return client.get(`/connections/${id}/schema-async?${query}`).then(resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as ObjectSchema;
    });
  });
}

export async function getObjectSchemaByQuery(id: string, query: string): Promise<ObjectSchema> {
  const req = {
    query: query,
  };
  return client.post(`/connections/${id}/schema-async`, req).then(resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as ObjectSchema;
    });
  });
}

export async function generateSample(id: string, objectPath: ObjectPath): Promise<DataFrame> {
  const query = queryString.stringify(objectPath);
  return client.get(`/connections/${id}/sample-async?${query}`).then(async resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as DataFrame;
    });
  });
}

export async function getSampleAsync(
  id: string,
  config: SourceConfigType,
  overwrittenTypes: { [key: string]: LogicalDataType },
  dataPullWindow?: IDataPullWindow,
): Promise<DataFrame> {
  // const query = queryString.stringify(objectPath);
  const payload = {
    ...config,
    overwrittenTypes: overwrittenTypes,
    dataPullWindow: dataPullWindow,
  };
  return client.post(`/connections/${id}/sample-async`, payload).then(async resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as DataFrame;
    });
  });
}

export async function getQuerySampleAsync(id: string, query: string): Promise<DataFrame> {
  const req = {
    query: query,
  };
  return client.post(`/connections/${id}/query-sample-async`, req).then(async resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as DataFrame;
    });
  });
}

export async function getDefaultSelection(id: string, objectPath: ObjectPath): Promise<string[]> {
  const query = queryString.stringify(objectPath);
  return client.get(`/connections/${id}/default-selection?${query}`).then(resp => {
    return resp.data.selectedFields;
  });
}

export async function parseFileURL(connectionId: string, fileUrl: string): Promise<string | AxiosError> {
  const payload = {
    url: fileUrl,
    urlType: 'file',
  };
  return client.post(`/connections/${connectionId}/fs/clean-url`, payload).then(resp => {
    return resp.data.url;
  });
}

export async function parseFolderURL(connectionId: string, folderUrl: string): Promise<string> {
  const payload = {
    url: folderUrl,
    urlType: 'folder',
  };
  return client.post(`/connections/${connectionId}/fs/clean-url`, payload).then(resp => {
    return resp.data.url;
  });
}

export async function validateFilePattern(
  connectionId: string,
  folderUrl: string,
  filePattern: string,
): Promise<string> {
  const payload = {
    folderUrl,
    filePattern,
  };
  return client.post(`/connections/${connectionId}/fs/validate-file-pattern`, payload).then(resp => {
    return resp.data.matchedFiles;
  });
}

type GetTabRequest = {
  extension?: FileType;
  fileUrl?: string;
  folderUrl?: string;
  filePattern?: string;
  isMultiple?: boolean;
};

export async function getTabs(connectionId: string, request: GetTabRequest): Promise<string[]> {
  return client.post(`/connections/${connectionId}/fs/tabs-async`, request).then(async resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      const response = result as GetTabsResponse;
      return response.tabs;
    });
  });
}

//FIXME: to be imported from @savant-components/catalog
type FilePath = {
  fileType: 'CSV' | 'EXCEL' | 'GOOGLE_SHEET' | 'BLOB' | 'PARQUET';
  isMultiple?: boolean;
  folderURL?: string;
  filePattern?: string;
  fileURL?: string;
  tabs: string[];
  fileParserProps?: {
    delimiter?: string;
    qualifier?: string;
  };
};
export async function getFileSystemSample(
  connectionId: string,
  filePath: FilePath,
  overwrittenTypes: { [key: string]: LogicalDataType },
  compression?: Compression,
): Promise<DataFrame> {
  const { fileParserProps, ...rest } = filePath;
  const payload: FilePath & any = {
    ...rest,
    extension: filePath.fileType.toUpperCase() as any,
    tabNames: filePath.tabs,
    multiple: filePath.isMultiple,
    overwrittenTypes: overwrittenTypes,
    compression: compression,
  };
  if (
    filePath.fileType === 'CSV' &&
    fileParserProps &&
    Object.values(fileParserProps).filter(x => x !== undefined && x !== '').length !== 0
  ) {
    payload['fileParserProps'] = {
      delimiter: fileParserProps?.delimiter,
      qualifier: fileParserProps?.qualifier,
    };
  }
  return client.post(`/connections/${connectionId}/fs/sample-async`, payload).then(async resp => {
    const promise = resp.data;
    promise.createdTime = new Date();
    return followPromise(promise, undefined).then(result => {
      return result as DataFrame;
    });
  });
}

export type ListConnectionsRequest = {
  connector?: string;
  excludeReadOnly?: boolean;
  excludeWriteOnly?: boolean;
  excludeDbt?: boolean;
};

export async function listConnections(req?: ListConnectionsRequest): Promise<Connection[]> {
  let url = `/connections`;

  if (req) {
    const parts: string[] = [];
    if (req.connector) {
      parts.push(`connector=${req.connector}`);
    }
    if (req.excludeReadOnly) {
      // only writable connections
      parts.push('usages=destination');
    }
    if (req.excludeWriteOnly) {
      parts.push('usage=source');
    }
    url += '?' + parts.join('&');
  }
  return client.get(url).then(resp => {
    // Connector connector;
    // Long expiresAt;
    // Connection.Status status;
    return resp.data.slice.map((summary: { connector: any }) => {
      return {
        ...summary,
        type: summary.connector,
      };
    });
  });
}

export async function listAIProviderConnections(): Promise<Connection[]> {
  return client
    .get(`/connections/AIProviders`)
    .then((resp: { data: { slice: Connection[]; hasNext: boolean } }) => resp.data.slice);
}

export async function listDbtJobs(connectionId: string): Promise<SlackChannel[]> {
  return client.get(`/connections/${connectionId}/dbt/jobs`).then(resp => {
    return resp.data;
  });
}

export async function listSlackChannels(connectionId: string): Promise<SlackChannel[]> {
  return client.get(`/connections/${connectionId}/slack/channels`).then(resp => {
    return resp.data;
  });
}

export async function describeSlackChannel(connectionId: string, channelId: string): Promise<SlackChannel> {
  return client.get(`/connections/${connectionId}/slack/channels/${channelId}`).then(resp => {
    return resp.data;
  });
}

export async function deletConnection(id: string): Promise<void> {
  return client.delete(`/connections/${id}`).then(() => {
    return;
  });
}
