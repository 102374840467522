/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl';

import { Loader } from '@savant-components/basic';
import { Source, Sources } from '@savant-components/catalog';

import { useSources } from '../../hooks/source';
import { removeAddSourceWizardContext } from '../../services/storage';

import CatalogLayout from './CatalogLayout';
import { useConnections } from '../../hooks/connection';
import { useState } from 'react';
import { getConnectorsArray, getOwnerArray } from '../../utils/utils';

const SourcesView = (): JSX.Element => {
  const intl = useIntl();
  const addSourceViewUrl = `/${intl.locale}/app/source/add`;
  const uploadSourceViewUrl = `/${intl.locale}/app/source/upload`;
  // const { isLoading, sources, getSourceLink, getConnectionLink, onDelete } = useSources();
  const { getConnectionInfo } = useConnections({
    connecionsUrl: `/${intl.locale}/app/connection`,
  });
  const onClickAdd = () => {
    removeAddSourceWizardContext();
    window.location.href = addSourceViewUrl;
  };

  const onClickUpload = () => {
    removeAddSourceWizardContext();
    window.location.href = uploadSourceViewUrl;
  };

  const setFilterValues = (source: Source[]) => {
    const typeFilter = filterOptions.find(f => f.label === 'Connector(s)');
    if (typeFilter) {
      typeFilter.options = getConnectorsArray(source || []);
    }
    const phaseFilter = filterOptions.find(f => f.label === 'Owner');
    if (phaseFilter) {
      phaseFilter.options = getOwnerArray(source || []);
    }
    setFilterOptions([...filterOptions]);
  };

  const {
    isLoading,
    sources,
    getSourceLink,
    onDelete,
    onReSync,
    onReSample,
    getDependingRecipes,
    onCopy,
    onReplaceFile,
  } = useSources(`/${intl.locale}/app/source`, setFilterValues);

  const [searchText, setSearchText] = useState('');
  const [filterOptions, setFilterOptions] = useState([
    {
      label: 'Last Modified',
      type: 'date',
      placeHolder: 'Select date',
      value: [null, null],
    },
    {
      label: 'Connector(s)',
      type: 'select',
      placeHolder: 'All systems',
      value: [],
      options: getConnectorsArray([...sources]),
    },
    {
      label: 'Owner',
      type: 'select',
      placeHolder: 'All systems',
      value: [],
      options: getOwnerArray([...sources]),
    },
  ]);

  return (
    <CatalogLayout module="source">
      {isLoading ? (
        <Loader size={200} />
      ) : (
        <Sources
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          sources={sources}
          getSourceLink={getSourceLink}
          getConnectionLink={undefined}
          onSelect={undefined}
          onClickAdd={onClickAdd}
          onClickUpload={onClickUpload}
          onDelete={onDelete}
          onReSync={onReSync}
          onReplaceFile={onReplaceFile}
          onReSample={onReSample}
          getConnectionInfo={getConnectionInfo}
          getDependingRecipes={getDependingRecipes}
          onCopy={onCopy}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      )}
    </CatalogLayout>
  );
};

export default SourcesView;
