import { useAlert } from 'react-alert';

import { useAsync } from '@savant-components/basic';
import { DeepLink, evaluatLink, resolveDeepLink } from '../services/deeplink';
import { useEffect, useState } from 'react';
import { handleError, is404 } from '../services/client';
// import { useNavigate } from 'react-router-dom';
import { getDeepLink, getTabSession, removeDeepLink, saveDeepLink } from '../services/storage';
import { useNavigate } from 'react-router';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';

export function useDeepLink(linkId: string): { notFound: boolean } {
  const alert = useAlert();
  const { value, error } = useAsync<DeepLink, AxiosError>(async () => {
    removeDeepLink();
    return await resolveDeepLink(linkId);
  }, true);
  const [notFound, setNotFound] = useState<boolean>(false);
  useEffect(() => {
    if (error) {
      handleError(error, alert);
      if (is404(error)) {
        setNotFound(true);
      }
    }
  }, [error]);
  useEffect(() => {
    if (value) {
      saveDeepLink(value);
      window.location.href = '/app/link';
    }
  }, [value]);
  return {
    notFound,
  };
}

export function useProtectedLink(): { isLoading: boolean; deepLink: DeepLink } {
  const alert = useAlert();
  const deepLink = getDeepLink();
  const intl = useIntl();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (deepLink) {
      removeDeepLink();
      setIsLoading(true);
      evaluatLink(deepLink)
        .then(resp => {
          if (resp.objectType === 'recipe') {
            const tab = getTabSession();
            navigate(`/${intl.locale}/app/flow/${resp.objectId}/?rns=${tab.namespace}`);
            setIsLoading(false);
          }
        })
        .catch(err => {
          handleError(err, alert);
          setIsLoading(false);
        });
    }
  }, [deepLink]);
  return {
    isLoading,
    deepLink: deepLink as DeepLink,
  };
}
