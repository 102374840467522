import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';

import { useAsync } from '@savant-components/basic';

import {
  cancelExecution as cancelExec,
  downloadReportExecution,
  getExecution,
  getExecutionGraph,
  getExecutions,
  getExecutionsFromExecution,
  updateExecutionMetadata as updateExecutionMetadataViaAPI,
} from '../services/execution';
import { handleError } from '../services/client';
import { AuditReportPayload, Execution, Recipe, UpdateExecutionMetadataRequest } from '@savant-components/catalog';
import { AxiosError } from 'axios';
import { Flow } from '../types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getRecipeFromExecution } from '../services/recipes';

export function useExecutions(
  isMonitoring: boolean,
  setFilterValues: (executions: Execution[]) => void,
): {
  executions: Execution[];
  isLoading: boolean;
  cancelExecution: (exec: Execution) => Promise<void>;
  downloadReportExecution: (executions: AuditReportPayload) => Promise<void>;
  getExecutionsFromExecution: (execution: Execution) => Promise<Execution[]>;
  getRecipeFromExecution: (exec: Execution) => Promise<Recipe>;
  updateExecutionMetadata: (req: UpdateExecutionMetadataRequest) => Promise<Execution>;
} {
  const { status, value, error, execute } = useAsync<Execution[], AxiosError>(() => getExecutions());
  const [isLoading, setIsLoading] = useState<boolean>(!(status === 'success' || status === 'error'));
  const [executions, setExecutions] = useState<Execution[]>();
  const alert = useAlert();
  const refreshAllEvent = 'app/runs/refreshAll';
  const refreshSingleEvent = 'app/runs/refreshSingle';

  useEffect(() => {
    if (!isLoading && !(status === 'success' || status === 'error')) {
      setIsLoading(true);
    } else if (isLoading && (status === 'success' || status === 'error')) {
      setIsLoading(false);
      if (error) {
        updateExecutions([]);
        handleError(error as AxiosError, alert);
      } else {
        updateExecutions(value as Execution[]);
      }
    }
  }, [isLoading, status]);

  function updateExecutions(executions: Execution[]) {
    setTimeout(() => {
      emitCustomEvent(refreshAllEvent, {});
    }, 60000);
    executions
      .filter(exec => exec.phase.toLowerCase() === 'running' || exec.phase.toLowerCase() === 'pending')
      .forEach(exec =>
        setTimeout(() => {
          emitCustomEvent(refreshSingleEvent, { executionId: exec.id });
        }, 1000),
      );
    setFilterValues(executions);
    setExecutions(executions);
  }

  // slow full refresh
  useCustomEventListener(refreshAllEvent, () => {
    if (isMonitoring) {
      getExecutions().then(executions => {
        setExecutions(executions);
      });
      setTimeout(() => {
        emitCustomEvent(refreshAllEvent, {});
      }, 60000);
    }
  });

  // fast partial refresh
  useCustomEventListener(refreshSingleEvent, (data: { executionId: string }) => {
    if (isMonitoring) {
      const executionId = data.executionId;

      getExecution(executionId).then(exec => {
        setExecutions(
          executions?.map(e2 => {
            if (e2.id === exec.id) {
              return {
                ...e2,
                phase: exec.phase,
                progress: exec.progress,
                finishedAt: exec.finishedAt,
                recipeId: exec.recipeId,
              };
            } else {
              return e2;
            }
          }),
        );
        if (exec.phase.toLowerCase() === 'running' || exec.phase.toLowerCase() === 'pending') {
          setTimeout(() => {
            emitCustomEvent(refreshSingleEvent, { executionId });
          }, 1000);
        }
      });
    }
  });

  const cancelExecution = async (exec: Execution) => {
    return cancelExec(exec.id)
      .then(() => emitCustomEvent(refreshAllEvent, {}))
      .catch(err => handleError(err, alert));
  };

  const updateExecutionMetadata = async (req: UpdateExecutionMetadataRequest): Promise<Execution> => {
    return updateExecutionMetadataViaAPI(req)
      .then(res => {
        execute();
        return res;
      })
      .catch(err => {
        handleError(err, alert);
        return { id: req.id, name: req.name } as Execution;
      });
  };

  return {
    executions: executions as Execution[],
    isLoading,
    cancelExecution,
    getExecutionsFromExecution: getExecutionsFromExecution(alert),
    downloadReportExecution: downloadReportExecution(alert, refreshAllEvent),
    getRecipeFromExecution: getRecipeFromExecution(alert),
    updateExecutionMetadata,
  };
}

export const useExecutionQuery = (executionId?: string): UseQueryResult<Execution> => {
  return useQuery<Execution>({
    refetchInterval: dd => (!dd?.finishedAt && dd?.phase !== 'Canceled' ? 1000 : false),
    queryKey: [executionId, 'useExecutionQuery'],
    queryFn: async () => {
      return getExecution(executionId || '');
    },
  });
};
export const useExecutionGraphQuery = (executionId?: string): UseQueryResult<Flow> => {
  return useQuery<Flow>({
    queryKey: [executionId, 'useExecutionGraphQuery'],
    queryFn: async () => {
      return getExecutionGraph(executionId || '');
    },
  });
};
