import { useCustomEventListener } from 'react-custom-events';
import TagManager from 'react-gtm-module';

import { events, TrackingEvent } from '@savant-components/basic';

export const useTrackingEvent = () => {
  function gtmTrack(event: TrackingEvent): void {
    const tagManagerArgs = {
      dataLayer: {
        event: 'savantEvent',
        eventDetail: event,
      },
      dataLayerName: 'PageDataLayer',
    };
    TagManager.dataLayer(tagManagerArgs);
  }

  useCustomEventListener(events.TRACKING, (event: TrackingEvent) => {
    if (typeof window !== 'undefined') {
      gtmTrack(event);
    }
  });
};
