import React, { Suspense } from 'react';
import { useIntl } from 'react-intl';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import {
  RecipeContext,
  SourcesContext,
  ConnectionsContext,
  ExecutionsContext,
  TemplateContext,
} from '@savant-components/catalog';
import {
  getConnectionContext,
  getRecipeContext,
  getSourceContext,
  getExecutionContext,
  getTemplateContext,
} from '../contexts';

import { useFeatureService } from '../hooks/feature';
import RequireAuth from '../components/RequireAuth';
import WithWebSocket from '../components/app/WithWebSocket';
import AppLayout from '../components/app/AppLayout';

// admin
import UsageLogView from '../components/app/UsageLogView';
import UsersView from '../components/app/UsersView';
import WorkspacesView from '../components/app/WorkspacesView';
import WorksapceDetailView from '../components/app/WorkspaceDetailView';
import OrgProfileView from '../components/app/OrgProfileView';
import RecipesView from '../components/app/RecipesView';

// connection
import { default as ListConnectionView } from '../components/app/ConnectionsView';
import AddConnectionView from '../components/app/AddConnectionView';
import EditConnectionView from '../components/app/EditConnectionView';

// source
import { default as ListSourceView } from '../components/app/SourcesView';
import AddSourceView from '../components/app/AddSourceView';
import UploadSourceView from '../components/app/UploadSourceView';
import SourceDetailView from '../components/app/SourceDetailView';

// flow
import AnalysisDetailLayout from '../components/app/AnalysisDetailLayout';
import FlowViewer from '../components/app/FlowViewer';
import FlowBuilder from '../components/app/FlowBuilder';
import BotView, { Schedules, Runs } from '../components/app/BotView';

// run
const ExecutionsView = React.lazy(() => import('../components/app/ExecutionsView'));

// template
const TemplatesView = React.lazy(() => import('../components/app/TemplatesView'));

// oidc
const OpenIDAuth = React.lazy(() => import('../components/app/OpenIDAuth'));

// import { CreateRecipe } from '../components/app/CreateRecipe';
import NotFound from '../components/notfound';
import NotificationsView from '../components/app/NotificationsView';

// business app
import BusinessAppView from '../components/app/BusinessAppView';

// link
import ProtectedLinkView from '../components/app/ProtectedLinkView';
import RestrictedPage from '../components/RestricedPage';
import { Loader } from '@savant-components/basic';

// community
const CommunityFrame = React.lazy(() => import('../components/app/CommunityFrame'));

const AppPage = (): React.ReactElement => {
  const intl = useIntl();
  const { isFeatureEnabled } = useFeatureService();
  const canInstallTemplate = isFeatureEnabled('INSTALL_TEMPLATE');
  const canSeeBuilder = isFeatureEnabled('SEE_BUILDER_PAGE');
  return (
    <RequireAuth>
      <WithWebSocket>
        {/* <BrowserRouter basename={`/${locale}/app`}> */}
        <Routes>
          <Route
            path="analysis"
            element={
              <AppLayout module="analysis" pagePermission="SEE_ANALYSIS_PAGE">
                <RecipeContext.Provider value={getRecipeContext(intl)}>
                  <RecipesView />
                </RecipeContext.Provider>
              </AppLayout>
            }
          />
          {canInstallTemplate && (
            <Route
              path="template"
              element={
                <AppLayout module="template" pagePermission="SEE_ANALYSIS_PAGE">
                  <Suspense fallback={<Loader size={100} />}>
                    <TemplateContext.Provider value={getTemplateContext(intl)}>
                      <TemplatesView />
                    </TemplateContext.Provider>
                  </Suspense>
                </AppLayout>
              }
            />
          )}
          <Route
            path="source"
            element={
              <AppLayout module="source" pagePermission="SEE_DATA_PAGE">
                <SourcesContext.Provider value={getSourceContext(intl)}>
                  <Outlet />
                </SourcesContext.Provider>
              </AppLayout>
            }
          >
            <Route path="" element={<ListSourceView />} />
            <Route path="add" element={<AddSourceView />} />
            <Route path="upload" element={<UploadSourceView />} />
            <Route path=":sourceId/upload" element={<UploadSourceView />} />
            <Route path=":sourceId" element={<SourceDetailView />} />
          </Route>
          <Route
            path="connection"
            element={
              <AppLayout module="connection" pagePermission="SEE_DATA_PAGE">
                <ConnectionsContext.Provider value={getConnectionContext(intl)}>
                  <Outlet />
                </ConnectionsContext.Provider>
              </AppLayout>
            }
          >
            <Route path="" element={<ListConnectionView />} />
            <Route path="add" element={<AddConnectionView />} />
            <Route path=":connectionId/edit" element={<EditConnectionView />} />
          </Route>
          <Route
            path="run"
            element={
              <AppLayout module="run" pagePermission="SEE_RUNS_PAGE">
                <Suspense fallback={<Loader size={100} />}>
                  <ExecutionsContext.Provider value={getExecutionContext(intl)}>
                    <ExecutionsView />
                  </ExecutionsContext.Provider>
                </Suspense>
              </AppLayout>
            }
          />
          <Route
            path="flow"
            element={
              <QueryClientProvider client={new QueryClient()}>
                <ConnectionsContext.Provider value={getConnectionContext(intl)}>
                  <SourcesContext.Provider value={getSourceContext(intl)}>
                    <RecipeContext.Provider value={getRecipeContext(intl)}>
                      <ExecutionsContext.Provider value={getExecutionContext(intl)}>
                        {canSeeBuilder ? (
                          <Outlet />
                        ) : (
                          <AppLayout module="runs" hideNavigation pagePermission="SEE_BUILDER_PAGE">
                            <RestrictedPage />
                          </AppLayout>
                        )}
                      </ExecutionsContext.Provider>
                    </RecipeContext.Provider>
                  </SourcesContext.Provider>
                </ConnectionsContext.Provider>
              </QueryClientProvider>
            }
          >
            {/* <Route path="create" element={<CreateRecipe />} /> */}
            <Route path="execution/:executionId" element={<FlowViewer />} />
            <Route path=":recipeId" element={<AnalysisDetailLayout />}>
              <Route path="" element={<FlowBuilder />} />
              <Route path="bot" element={<BotView />}>
                <Route path="" element={<Navigate to="runs" replace />} />
                <Route path="automation" element={<Schedules />} />
                <Route path="runs" element={<Runs />} />
              </Route>
            </Route>
          </Route>
          <Route
            path="admin"
            element={
              <AppLayout module="admin" pagePermission="SEE_ADMIN_PAGE">
                <Outlet />
              </AppLayout>
            }
          >
            <Route path="" element={<Navigate to="profile" replace />} />
            <Route path="profile" element={<OrgProfileView />} />
            <Route path="users" element={<UsersView />} />
            <Route path="workspaces" element={<WorkspacesView />} />
            <Route path="workspace/:workspaceId" element={<WorksapceDetailView />} />
            <Route path="usage" element={<UsageLogView />} />
            <Route path="notifications" element={<NotificationsView />} />
          </Route>
          <Route
            path="bizapp"
            element={
              <AppLayout module="analysis" hideNavigation pagePermission="SEE_BIZAPP_PAGE">
                <Outlet />
              </AppLayout>
            }
          >
            <Route path="" element={<NotFound />} />
            <Route path=":appId/submit" element={<BusinessAppView />} />
          </Route>
          <Route
            path="community"
            element={
              <AppLayout module="community" pagePermission="SEE_COMMUNITY_TAB">
                <Suspense fallback={<Loader size={100} />}>
                  <CommunityFrame />
                </Suspense>
              </AppLayout>
            }
          />
          <Route
            path="link"
            element={
              <AppLayout module="link" pagePermission="SEE_LINK_PAGE">
                <ProtectedLinkView />
              </AppLayout>
            }
          />
          <Route path="oauth" element={<Outlet />}>
            <Route path="" element={<Navigate to="auth" replace />} />
            <Route
              path="auth"
              element={
                <Suspense fallback={<Loader size={100} />}>
                  <OpenIDAuth />
                </Suspense>
              }
            />
          </Route>
          <Route path="/" element={<Navigate to="analysis" replace />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* </BrowserRouter> */}
      </WithWebSocket>
    </RequireAuth>
  );
};

export default AppPage;
