/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { BasicContext, EditableText, Spacer, ImageUploaderClickable } from '@savant-components/basic';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AdminLayout from './AdminLayout';
import { useOrgProfile } from '../../hooks/useOrgProfile';
import { useTheme } from '@savant-components/theme';
import { useUploader } from '../../services/uploader';

const OrgProfileView = (): React.ReactElement => {
  const intl = useIntl();
  const ctx = useContext(BasicContext);
  const theme = useTheme();
  const logoLabel = intl.formatMessage({ id: 'app.logo' });
  const emailDomainLabel = intl.formatMessage({ id: 'app.emailDomain' });
  const [isProfileImgChange, setIsProfileImgChange] = useState(false);
  const { organization: org, updateOrgName, updateOrgLogo } = useOrgProfile();
  const [imageUrl, setImageUrl] = useState<string>();
  const uploader = useUploader();

  useEffect(() => {
    if (org.logoUrl !== imageUrl) {
      setImageUrl(org.logoUrl);
    }
  }, [org.logoUrl]);

  const style = css`
    padding: 64px;
    padding-right: 4.5rem;
    height: 100%;
    & > .section-title {
      height: 32px;
      margin-bottom: 8px;
    }
    & > .logo-section {
      height: 80px;
      width: 80px;
      padding: 8px;
      border: 1px solid ${theme.colors.o3};
      border-radius: 8px;
    }
  `;
  return (
    <AdminLayout module="profile">
      <div css={style}>
        <div className="section-title text-t5">{ctx.messages.name}</div>
        <div className="section-p">
          {org.role === 'Admin' ? (
            <EditableText text={org.name} onUpdateText={s => updateOrgName(s)} size="t6" keepEditIconVisible />
          ) : (
            <span>{org.name}</span>
          )}
        </div>
        <Spacer direction="vertical" size="32px" />
        <div className="section-title text-t5">{emailDomainLabel}</div>
        <div className="section-p">
          <span>{org.domain}</span>
        </div>
        <Spacer direction="vertical" size="32px" />
        <div className="section-title text-t5">{logoLabel}</div>
        <ImageUploaderClickable
          updateLogo={updateOrgLogo}
          imageUrl={org.logoUrl || ''}
          setIsProfileImgChange={setIsProfileImgChange}
          isProfileImgChange={isProfileImgChange}
          uploader={uploader}
        />
      </div>
    </AdminLayout>
  );
};

export default OrgProfileView;
